import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useGetConversationsQuery } from "../../utils/ApiSlice";
import "./ChatList.css";
// import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from "../../env.json";
import SearchInput from "../../layouts/searchInput/SearchInput";
import { post } from "jquery";
import Pusher from 'pusher-js';
import NewChatModal from '../../components/chat/NewChatModal'
import {formatDateIntoTodayYesterdayFormat} from '../../utils/Date';
import { useSelector, useDispatch } from 'react-redux'
import {getAllConversationsApi} from '../../apis/chat/chatApi';
import {chat, setChatDetails, setChatType, setRefreshChatComponent, setRefreshChatList} from '../../app/slice/chatSlice';
import Spinner from "../../layouts/spinner/Spinner";
import Avatar from "../Avatar/Avatar";
import { useTranslation } from "react-i18next";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import { setTimeout } from "timers/promises";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { Keyboard } from '@capacitor/keyboard';
import { useNavigate } from "react-router-dom";
import { App } from '@capacitor/app';
function stringAvatar(name: string) {

  let tempname: any =name?name.split(" "):"U K".split(" ");
  let shortName = tempname[0][0];
  if (tempname[1]) shortName += tempname[1][0];

  return {
    sx: {
      bgcolor: "#FAF8FF",
      color: document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize: "1rem",
      fontWeight: "bold",
      width: "50px",
      height: "50px",
      border: "1px solid #734BD14D",
    },
    children: shortName,
  };
}

export default function ChatList(props) {
  const { t } = useTranslation();
  const dispatch=useDispatch()
  const virtualUserId=useSelector((state: any) => state.authApi.Id);
  const loginToken = useSelector((state: any) => state.login.token)
  const refreshChatListStore = useSelector((state: any) => state.chat.refreshChatList);
const [chats,setChats]=useState<any>([]);
const [hasMore,setHasMore]=useState(true);
const [instantOpenChat,setInstantOpenchat]=useState(false);
// const [paddingValTop,setPaddingValTop]=useState(0);
const [paddingValBottom,setPaddingValBottom]=useState(0);
 const [paddingValRight,setPaddingValRight]=useState(0);
// const [paddingValLeft,setPaddingValLeft]=useState(0);
// const {data:posts,isFetching,isSuccess,isError} = useGetConversationsQuery({});
const [newChatComponent,setNewChatComponent] = useState(false);
// useEffect(() => {
//  if(isError){
//     setHasMore(false);
//  }
// }, [isError,isFetching])
  // const [chats, setChats] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [allData,setAllData]=useState([]);
  const [page, setPage] = useState(1);
  const [limit,setLimit]=useState(100)
  const [filterdData,setFilterdData]=useState([]);
  // const navigate = useNavigate();
  const [infiniteScrollHeight,setInfiniteScrollHeight]=useState(500);
  // const [prescOrAssocId,setPrescOrAssocId]=useState<any>();
  // const [prescOrAssocType,setPrescOrAssocType]=useState<any>()
  // const {data:posts,isFetching,isSuccess,isError} = useGetConversationsQuery({});
  // useEffect(() => {
  //  if(isError){
  //     setHasMore(false);
  //  }
  // }, [isError,isFetching])
  const navigate = useNavigate();

  useEffect(() => {
  
    // console.log(loginToken)
    if(props.openNewChat){
      setNewChatComponent(props.openNewChat)
    }
  }, [props])
  
  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);

      });

      Keyboard.addListener('keyboardDidShow', info => {
        getHeight();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        getHeight();
        console.log("---Keyboard height---");
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight)
    window.addEventListener('orientationchange', getHeight)
  return () => {
    window.removeEventListener('resize',getHeight)
    window.removeEventListener('orientationchange',getHeight)
  }
  }, [])

  const getHeight=()=>{
    let doc:any=document
    let div:any=document.querySelector('#main');     
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right')); 
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingright);
    setInfiniteScrollHeight(window.innerHeight-(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('search').offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom)
  }

  useEffect(() => {
      getConversation(1,limit,search);
      getHeight();
  }, []);

  useEffect(()=>{
    if(refreshChatListStore)
    getConversation(1,limit,search);
  },[refreshChatListStore])
  
  useEffect(() => {
  if(search.length != 1) {
    setChats([])
    getConversation(1,limit,search);
  } 
  }, [search])
  

  const getConversation = (page,limit,search) => {
    setHasMore(true)

    getAllConversationsApi({page,limit,search},(res)=>{

        

        // res=res.sort((a:any,b:any)=>(new Date(b['Recipient Msg End']).getTime())-(new Date(a['Recipient Msg End']).getTime()))
        if(page==1){
          setChats(res);
        }
        else{
          setChats(chats.concat(res))
        }

        res=res.sort((a:any,b:any)=>(new Date(b['Recipient Msg End']).getTime())-(new Date(a['Recipient Msg End']).getTime()))
        // console.log(res)
        setChats(res);

        setHasMore(false);
        dispatch(setRefreshChatList(false)); 
        })
    // getAllConversationsApi({page,limit,search},(res)=>{

    // let temp:any=[]
    // let tempT:any={}

    // res.map((x:any)=>{

    //   if(x['Recipient Sender Typ']=="EMP")
    //   {
    //     if(tempT[x['Recipient Id']]){
    //       tempT[x['Recipient Id']].push(x);
    //     }
    //     else{
    //       tempT[x['Recipient Id']]=[];
    //       tempT[x['Recipient Id']].push(x);
    //     }
    //   }
    //   if(x['Recipient Sender Typ']!=="EMP")
    //   {
    //     if(tempT[x['Recipient Sender Id']]){
    //       tempT[x['Recipient Sender Id']].push(x);
    //     }
    //     else{
    //       tempT[x['Recipient Sender Id']]=[];
    //       tempT[x['Recipient Sender Id']].push(x);
    //     }
    //   }
    // })
    // console.log(tempT)
        
    
    // for(let keys in tempT){
    //   tempT[keys]=tempT[keys].sort(function(a:any,b:any){
    //     // Turn your strings into dates, and then subtract them
    //     // to get a value that is either negative, positive, or zero.
    //     return (new Date(b['Recipient Msg Start']).getTime()) -(new Date(a['Recipient Msg Start']).getTime());
    //   }); 
    //   temp.push(tempT[keys][0]); 
    // }
    
    // temp=temp.sort((a:any,b:any)=>new Date(b['Recipient Msg Start']).getTime() -(new Date(a['Recipient Msg Start']).getTime())) 
    // console.log(temp)
    // debugger
      
    // if(instantOpenChat)
    // {
    //   showChatPage(temp[0]);
    //   setInstantOpenchat(false)
    // }
    //   setChats(temp);
    //   setHasMore(false);
    // })
  //   axios
  //     .get(
  //       // config.REACT_APP_MESSAGE_API_BASE +
  //       //   "/conversations?page=" +
  //       //   page +
  //       //   "&searchquery=" +
  //       //   search,
  //       config.REACT_APP_MESSAGE_API_BASE +"/conversations?page="+page+"&limit="+limit+"&searchquery=" + search,
  //       {
  //         headers: {
  //           Authorization: ("Bearer " +
  //             sessionStorage.getItem("login")) as string,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       //setAssets(res.data.data);
  // //       let temp: any = [];
  // //       //debugger;

       
  // //       // setCampaign(res.data.data);
  // //       // console.log(res.data.data)
          
  // //        setAllData(data);
  // //       let out=data.filter(x=>x['Recipient Sender Typ']=="EMP");
  // //       let inC=data.filter(x=>x['Recipient Sender Typ']!=="EMP");


  // //       // inc.map((x:any)=>{
  // //       //   let t={
  // //       //     isIncoming:true,
  // //       //     body:x.MsgBody,
  // //       //     datetime:x['Recipient Msg Start'],
  // //       //     name:x['Sender Name'],
  // //       //     id:x['Recipient Sender Id']
  // //       //   }
  // //       //   temp.push(t);
  // //       //   return x;
  // //       // })
  // //       // out.map((x:any)=>{
  // //       //   let t={
  // //       //     isIncoming:true,
  // //       //     body:x.MsgBody,
  // //       //     datetime:x['Recipient Msg Start'],
  // //       //     name:x['Sender Name'],
  // //       //     id:x['Recipient Sender Id']
  // //       //   }
  // //       //   temp.push(t);
  // //       //   return x;
  // //       // })

  // //      function groupBy(array:any, f:any) {
  // //         let groups = {};
  // //         array.forEach(function (o:any) {
  // //           var group = JSON.stringify(f(o));
  // //           groups[group] = groups[group] || [];
  // //           groups[group].push(o);
  // //         });

  // //      return Object.keys(groups).map(function (group) {
  // //        return groups[group];
  // //      })
  // //   }
        
  
       
  // //  let senderType:any= groupBy(res.data, function (item) {
  // //     return [item['Recipient Sender Typ']];
  // //   });


  // //    console.log(senderType);

  // //   let outgoingYY:any= groupBy(out, function (item) {
  // //     return [item['Recipient Id']];
  // //   });
   
  // //   //  console.log(Yy);
  // //   //  Yy.map((p:any)=>{
  // //   //   temp.push(p[0]);
  // //   // })
  // //   console.log(IncomingYY,outgoingYY)
  // //   // IncomingYY.map((x:any)=>{
  // //   //   x[0]['Recipient Sender Id']
  // //   // })

  // let temp:any=[]
  //   let tempT:any={}

  //   res.data.map((x:any)=>{

  //     if(x['Recipient Sender Typ']=="EMP")
  //     {
  //       if(tempT[x['Recipient Id']]){
  //         tempT[x['Recipient Id']].push(x);
  //       }
  //       else{
  //         tempT[x['Recipient Id']]=[];
  //         tempT[x['Recipient Id']].push(x);
  //       }
  //     }
  //     if(x['Recipient Sender Typ']!=="EMP")
  //     {
  //       if(tempT[x['Recipient Sender Id']]){
  //         tempT[x['Recipient Sender Id']].push(x);
  //       }
  //       else{
  //         tempT[x['Recipient Sender Id']]=[];
  //         tempT[x['Recipient Sender Id']].push(x);
  //       }
  //     }
  //   })
  //   console.log(tempT)
        
    
  //   for(let keys in tempT){
  //     tempT[keys]=tempT[keys].sort(function(a:any,b:any){
  //       // Turn your strings into dates, and then subtract them
  //       // to get a value that is either negative, positive, or zero.
  //       return (new Date(b['Recipient Msg Start']).getTime()) -(new Date(a['Recipient Msg Start']).getTime());
  //     }); 
  //     temp.push(tempT[keys][0]); 
  //   }
    
  //   temp=temp.sort((a:any,b:any)=>new Date(b['Recipient Msg Start']).getTime() -(new Date(a['Recipient Msg Start']).getTime())) 
  //   console.log(temp)
  //   debugger
      
  //   if(instantOpenChat)
  //   {
  //     showChatPage(temp[0]);
  //     setInstantOpenchat(false)
  //   }
  //     setChats(temp);
  //     setHasMore(false);
  //     });
  };


  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  useEffect(() => {
    if(virtualUserId)
    chatListSubscriber() 
  }, [virtualUserId])
  

  const chatListSubscriber =() => {
   
    debugger
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
        // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
    //   encrypted: true,
    });
    debugger
  
    const channel = pusher.subscribe("chatdetail");
    channel.bind("RecipientId"+virtualUserId,(data)=>{
     dispatch(setRefreshChatList(true))
     dispatch(setRefreshChatComponent(true))
    })
  

    
    
    
    return () => {
      pusher.unsubscribe("chatdetail");
    };
  }
  const messageReadAt =(item) =>{
    let payload:any={
      prescOrAssocId:item.PresOrAssocId, 
      prescOrAssocType:item.PresOrAssocIdType
    }
    console.log(payload)
    // getMsgReadAt(payload)
    getMsgReadAt(payload,(res)=>{
      console.log(res)
    })
  };
  const showChatPage = (item: any, index:any) => {
    console.log(index)
    debugger;
//     let doc:any=document;
// doc.getElementById('headerVal').style.display="none !important";
// doc.getElementById('MobHeader').style.display="none !important";
// doc.getElementsByClassName('chatListDiv')[0].style.display="none !important";
    // this.setState({contact:item,showDetails:true},()=>{
    //   // this.setState({showDetails:true})
    // });
    // contact:item,showDetails:true

    // contact:item,showDetails:true

    // function groupBy(array:any, f:any) {
    //       let groups = {};
    //       array.forEach(function (o:any) {
    //         var group = JSON.stringify(f(o));
    //         groups[group] = groups[group] || [];
    //         groups[group].push(o);
    //       });

    //    return Object.keys(groups).map(function (group) {
    //      return groups[group];
    //    })
    // }
        
  
      
    // let tempData:any=allData.filter((x:any)=>x['Recipient Sender Id']===item['Recipient Sender Id'])

  //  let Yy:any= groupBy(res.data, function (item) {
  //     return [item['Recipient Sender Id']];
  //   });

//  console.log(item);
//  console.log(item.PresOrAssocId, item.PresOrAssocName, item.PresOrAssocIdType )

   let id=item.PresOrAssocId;
   let name:any=item.PresOrAssocName;
   let isPrescriber:boolean=item.PresOrAssocIdType=="PRESCR";

   messageReadAt(item);
   console.log(item.PresOrAssocId)

   chats[index].CountOfMsgNotRead=0
   setChats(chats)
  
   let chatListData = chats.map(x => x['PresOrAssocId'] === item.PresOrAssocId)
   console.log(chatListData)
  //  console.log(chats.findIndex(x=> x['PresOrAssocId'] === item.PresOrAssocId))
  //  chats[2162].CountOfMsgNotRead= 0;
    // if(item['Recipient Sender Typ']!=="EMP"){
    //   id=item['Recipient Sender Id'];
    //   name=item['Sender Name'];
    //   isPrescriber=item['Recipient Sender Typ']=="PRESCR";
    // }
    dispatch(setChatType(item.PresOrAssocIdType))
    dispatch(setChatDetails({
        chatId:id,
        chatName:name,
        chatIsPrescriber:isPrescriber,
        showChat:true,
    }))
    //props.func({ data:item , showChat: true });
  };

  const getItemName=(item)=>{
    const trimmedItem=getFormatedPhoneNumber(item['PresOrAssocName']).length>15 ?`${getFormatedPhoneNumber(item['PresOrAssocName']).substring(0,12)}...`:getFormatedPhoneNumber(item['PresOrAssocName']).substring(0,15)
    if(item.PresOrAssocIdType=="UNKN")
   return trimmedItem
   else
   return item['PresOrAssocName']?<span>{trimmedItem}</span>:<span>Unknown</span>
                            
  }
  return (
    <div className="main-content bg-gray p-0 col-xl-3 chatListDiv">
      {
        newChatComponent?
        <NewChatModal 
            show={newChatComponent}
            handleCloseParent={(e)=>{
              if(e){
              getConversation(1,limit,search);
              setInstantOpenchat(true)
              
              }
              //debugger;
              props.handleNewChatModal(false);
              setNewChatComponent(false)}}
            />
            :null
      }
      
      <div className="searchHeader row--three" id="search">
        <div className="content__header__searchbox chatListPage">
        <div className='content__header__searchbox'>
         
          <SearchInput id={'allContactSearch'} classes={'form-control font-md search-chats'} placeholder={t('Search Chats')} onChangeValue={(e)=>{setSearch(e)}}/>
        
          </div>

          <Tooltip title={t('New Chat')} arrow>
          <i
              className="far fa-edit create_newchat notMobile"
            //   @click="openHomeModal"
              id="CreateNewChat"
              onClick={()=> {setNewChatComponent(true)}}
            ></i>
            </Tooltip>
        </div>
      </div>
      {/* <div > */}
      <div className="chatlistbackground"  id="scrollableDiv"
  style={{
    // height: 300,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  }}>
        <InfiniteScroll
          dataLength={chats.length}
          next={() => {setPage(page+1); getConversation(page+1,limit,search)}}
          hasMore={hasMore}
          loader={<Spinner/>}
         height={infiniteScrollHeight}
          scrollableTarget="scrollableDiv"
          endMessage={

            <p style={{ textAlign: "center",fontSize:'1rem',fontWeight:'lighter' }}>
              {search.length>0 && chats.length==0?
              <span>No Messages found</span>:null
              }
              { chats.length==0 && search.length==0 ? 
              <span>
                <span className="notMobile">Click the <i className="far fa-edit"></i> button to start sending messages</span>
                <span className="onlyMobile">Click the "+" button to start sending messages</span>
                </span>
                :
                null
                
                }
            </p>
          }
        >
          {/* <div className="scroll_box"> */}
          <ul>
          
            {/* {chats.map((item:any) => {
              if(item['Recipient Sender Typ']=="EMP"){
                return (
                  <li
                    className="chatList-div"
                    key={item['Recipient Id']}
                    id={item['Recipient Id']}
                    onClick={() => {
                      showChatPage(item);
                    }}
                  >
                    
                    <div className="chatListItem-div">
                      <div className="d-flex">
                      <div className="avatar-div chatListName">
                      
                        <Avatar
                    imageType={item['Recipient Type'] === "PRESCR"?"prescriber":'prescriberassociates'}
                   avatarHeight="2.8rem" avatarWidth="2.8rem" />
                      </div>
                      <div className="chatListDetails">
                        <h4
                          style={{
                            fontSize: "medium",
                            fontWeight: "bold",
                          }}
                        >
                          
                           
                           <span>
                            {
                              item['Recipient Name']?<span>{item['Recipient Name']}</span>:<span>Unknown</span>
                            }
                           </span>
                           
                           
                          
                          
                        </h4>
                        
                        
                        {
                         item['Recipient Msg Body']?
                         <div
                         style={{
                           fontSize: "medium",
                         }}
                       >

                         {item['Recipient Msg Body'].substring(0,18)}
                       </div>:
                         <div style={{
                          fontSize: "medium",
                        }}>
                          Attachment</div>
                        }
                      </div>
                      </div>
                      <div className="chatListDate">
                        <span
                          style={{
                            fontSize: "small",
                          }}
                        >
                          {t(formatDateIntoTodayYesterdayFormat(item['Recipient Msg End']))}
                        </span>
                        <p
                          style={{
                            fontSize: "small",
  
                            visibility: "hidden",
                          }}
                        >
                          
                        </p>
                      </div>
                          
                      
                    </div>
                  </li>
                )
              }
              else
              return (
                <li
                  className="chatList-div"
                  key={item['Recipient Sender Id']}
                  id={item['Recipient Sender Id']}
                  onClick={() => {
                    showChatPage(item);
                  }}
                >
                  
                  <div className="chatListItem-div">
                    
                    <div className="d-flex">
                    <div className="avatar-div chatListName">
                     
                      <Avatar
                    imageType={item["Recipient Sender Typ"] === "PRESCR"?"prescriber":'prescriberassociates'}
                   avatarHeight="3rem" avatarWidth="3rem" />
                    </div>
                    <div className="chatListDetails">
                      <h4
                        style={{
                          fontSize: "medium",
                          fontWeight: "bold",
                        }}
                      >
                        
                        
                         <span>
                          {
                            item['Sender Name']?<span>{item['Sender Name']}</span>:<span>Unknown</span>
                          }
                         </span>
                       
                      </h4>
                      
                      {
                         item['Recipient Msg Body']? <div
                         style={{
                           fontSize: "medium",
                         }}
                       >
                         {item['Recipient Msg Body'].substring(0,18)}
                       </div>:<div style={{
                          fontSize: "medium",
                        }}>Attachment</div>
                        }
                    </div>
                    </div>
                    <div className="chatListDate">
                      <span
                        style={{
                          fontSize: "small",
                        }}
                      >
                        {t(formatDateIntoTodayYesterdayFormat(item['Recipient Msg End']))}
                      </span>
                      <p
                        style={{
                          fontSize: "small",

                          visibility: "hidden",
                        }}
                      >
                       
                      </p>
                    </div>
                        
                    
                  </div>
                </li>
              );
            })} */}
            {
              chats.map((item:any, index:any)=>(
                <li
                    className="chatList-div"
                    key={item['PresOrAssocId']}
                    id={item['PresOrAssocId']}
                    onClick={() => {
                      showChatPage(item, index);
                    }}
                  >
                    
                    <div className="chatListItem-div">
                      <div className="d-flex">
                      <div className="avatar-div chatListName">
                      
                        <Avatar
                    imageType={item['PresOrAssocIdType'] === "PRESCR"?"prescriber":'prescriberassociates'}
                   avatarHeight="2.8rem" avatarWidth="2.8rem" />
                      </div>
                      <div className="chatListDetails">
                        <h4
                        className="chatListDetailsName"
                        >
                          
                           
                           <span>
                         
                            {
                              getItemName(item)
                            }
                           </span>
                           
                           
                          
                          
                        </h4>
                        
                        
                        {
                         item['RecipientMsgBody']?
                         <div
                      className="ChatListMsg"
                       >

                         {/* {item['RecipientMsgBody'].split(' ').slice(0, 3).join(' ')} */}
                         {truncate(item['RecipientMsgBody'],18)}
                       </div>:
                         <div 
                         className="ChatListMsg">
                          Attachment</div>
                        }
                      </div>
                      </div>
                   
                      <div className="chatListDate">
                        <div className="msgDate"
                          
                        >
                          {t(formatDateIntoTodayYesterdayFormat(item['RecipientMsgEnd']))}
                        </div>
                        <div className="badge-container">
                        {item.CountOfMsgNotRead == '0'? <span>&nbsp;</span>:
                        <span className="badge" style={{visibility: "visible"}}>
                        {item.CountOfMsgNotRead}
                      </span>}
                        </div>
                        
                      </div>
                          
                      
                    </div>
                  </li>
              ))
                        }
          </ul>
          {/* </div> */}
        </InfiniteScroll>
        <div className="floating onlyMobile" style={{paddingBottom:paddingValBottom,paddingRight:paddingValRight}}>
        <Tooltip title={t('New Chat')} arrow>
          <div className="floating-button"> 
          <i
              className="fas fa-comment-medical create_newchat medical-icon"
            //   @click="openHomeModal"
              id="CreateNewChat"
              onClick={()=> {navigate('/Home')}}
            ></i>
            </div>
            </Tooltip>
            </div>
      </div>
    </div>
  
  );
}
