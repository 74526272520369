import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    active: string;
    chatPermId:any;
    title: any
   
  }
const initialState: loginState = {
    active:'',
    chatPermId:'',
    title: ''
};
export const sideBar = createSlice({
  name: 'sideBar',
  initialState: initialState,
  reducers: {
    setActiveTab: (state,action:any) => { 
        console.log(state,action)
        state.active=action.payload;

      },
    setChatPermId: (state,action:any) => { 
        console.log(state,action)
        state.chatPermId=action.payload;

      },
      setTitle: (state,action:any) => { 
        state.title=action.payload;
      }
    }
});

// this is for dispatch
export const { setActiveTab, setChatPermId, setTitle } = sideBar.actions;

// this is for configureStore
export default sideBar.reducer;