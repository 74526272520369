import React, { useEffect, useRef, useState } from 'react'
import WebViewer from '@pdftron/webviewer'
import getInstance from '@pdftron/webviewer'
import DocIcon from "../../assets/icons/Assets.svg"
import axios from "axios";
import "./Media.css"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch,useSelector } from 'react-redux';
import {setVideoCallDetails, setVideoCallShowFloater,setVideoCallRoom,setVideoOtherParticipants,setVideoLocalParticipant,setVideoCallCallStarted,setVideoCallEnded, setVideoCallScreenTrack, setVideoCallPdfTronStarted, setVideoCallChats} from '../../app/slice/videoCallSlice';

import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import {getDeviceType} from '../../utils/deviceTypeUtil';
import  config  from '../../env.json';

const data=[
 {
  name:'Dosing',
  type:'dir',
  id:1,
  child:[
    {
      name:'Dosing info',
      type:'dir',
      id:8,
      parentId:1,
      child:[
        
         {
          name:'Dosing-Information.png',
          type:'file',
          id:11,
          parentId:8,
          fileurl:'https://testblobmedia.blob.core.windows.net/media/dosing Information.png'
         }
      ]
     }
  ]
 },
 {
  name:'Folder1',
  type:'dir',
  id:3,
  child:[
    {
      name:'MirEmpty',
      type:'dir',
      id:12,
      parentId:3,
      child:[
        
         {
          
          type:'file',
          id:15,
          parentId:12,
          name:'MIR-Empty.pdf',
          fileurl:'https://testblobmedia.blob.core.windows.net/media/mirformblank.pdf'
         }
      ]
     },
     {
      name:'FeelingGuide',
      type:'dir',
      id:13,
      parentId:3
     },
     {
      
      type:'file',
      id:14,
      parentId:3,
      name:'Feeling-Guide.pdf',
      fileurl:'https://testblobmedia.blob.core.windows.net/media/CORDASTINfeelingsv2.pdf'
     },
     {
      
      type:'file',
      id:16,
      parentId:3,
      name:'MIR-Empty.pdf',
          fileurl:'https://testblobmedia.blob.core.windows.net/media/mirformblank.pdf'
     }
  ]
 },
 {
  
  type:'file',
  id:4,
  name:'Feeling-Guide.pdf',
  fileurl:'https://testblobmedia.blob.core.windows.net/media/CORDASTINfeelingsv2.pdf'
 },
 {
  
  type:'file',
  id:5,
  name:'MIR-Empty.pdf',
  fileurl:'https://testblobmedia.blob.core.windows.net/media/mirformblank.pdf'
 },
 {
  
  type:'file',
  id:6,
  name:'MIR-Sign-Dr-Abigail-Allan.pdf',
  fileurl:'https://testblobmedia.blob.core.windows.net/media/mirformfilled.pdf'
 },



]

const Media = () => {
  const { t } = useTranslation();

  const room=useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
  const token:any=useSelector((state: any) => state.floaterVideoCall)
  const screenTrack=useSelector((state: any) => state.floaterVideoCall.videoCallScreenTrack)
  const videoCallScreenShareStarted=useSelector((state: any) => state.floaterVideoCall.videoCallScreenShareStarted)
  const videoCallShareUserId=useSelector((state: any) => state.floaterVideoCall.videoCallShareUserId)
  const videoCallAnnotaion=useSelector((state: any) => state.floaterVideoCall.videoCallAnnotaion)
  const videoCallPdfTronStarted=useSelector((state:any)=>state.floaterVideoCall.videoCallPdfTronStarted)
  const chatsNew:any=useSelector((state:any)=>state.floaterVideoCall.videoCallChats)
  const videoCallStarted=useSelector((state:any)=>state.floaterVideoCall.videoCallCallStarted);
    let [docData,setDocData] = useState<any[]>([]);
    let [activeClass,setActiveClass] = useState('activedoctab');
    let [activeDocClass,setActiveDocClass] = useState('');
    let [activeVideoClass,setActiveVideoClass] = useState('');
    const [paddingValTop,setPaddingValTop]=useState(0);
    const [paddingValBottom,setPaddingValBottom]=useState(0);
    const [paddingValRight,setPaddingValRight]=useState(0);
    const [paddingValLeft,setPaddingValLeft]=useState(0);
    let [hoveredCart, setHoveredCart] = useState(-1);
    let [optionsdiv,setOptionsdiv] = useState(false);
    let [showdocviewer,setShowdocviewer] = useState(false);
    const [AnnotationManagerNew,setAnnotationManagerNew]=useState<any>(null);
    const viewer=useRef<any>(null);
    const serializer = new XMLSerializer();
    const [fileUrl,setFileUrl]=useState("")
    const [instance,setInstance]=useState<any>(null);
    const [open,setOpen]=useState(false);
    const viewerMobile=useRef<any>(null);
    const [mediaHeight,setMediaHeight]=useState(0);  
  
    const [mobileInstance,setMobileInstance]=useState<any>(null)
    const [isMobile,setIsMobile]=useState<any>(false);
    const [allDoc,setAllDoc]=useState<any>([])
    const [isfolder,setIsFolder]=useState<any>(false)
    const [localData,setLocalData]=useState<any>([])
    const [parent,setparent]=useState<any>([])
    const [showPresent,setShowPresent]=useState<any>([])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [modalHeight, setModalHeight] = useState(0);
    const [modalWidth, setModalWidth] = useState(0);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;
    useEffect(() => {
   
       
       document.documentElement.style.setProperty("--visibility", "hidden");
       document
         .getElementById("page-name")
         ?.setAttribute("data-content", t("Assets"));
         
         let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
         setIsMobile(true);
         calcHeightWidth()
       } else setIsMobile(false);
       window.addEventListener(
         "resize",
         function (event) {
          
          let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
             setIsMobile(true);
             calcHeightWidth()
           } else setIsMobile(false);
         },
         true
       );
       setLocalData(data)
       //setparent(data);
     }, []);
     


    const getHeight=()=>{
      let viewportHeight = window.innerHeight;
      let div:any=document.getElementById('main');
      let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
      let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
      let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
      let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
      let doc:any=document;
      setPaddingValTop(divpaddingTop);
      setPaddingValBottom(divpaddingbottom);
      setPaddingValRight(divpaddingright);
      setPaddingValLeft(divpaddingleft);
      if(doc.getElementsByClassName('media-main-div')[0])
      setMediaHeight(viewportHeight -(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom) ;
    }

    useEffect(()=>{
      console.log(videoCallStarted)
    },[videoCallStarted])
    useEffect(() => {
      
      document
      .getElementById("page-name")
      ?.setAttribute("data-content", t("Media"));
      // axios.get("https://mocki.io/v1/807ebf6f-5b3f-40ae-9ea1-dd86bb97a5d7")
      //   .then((res) => {
      //     console.log(res.data);
      //       setDocData(res.data);
      //   })
      //   .catch((err)=>{
      //     console.log("Media fetch Error:"+err);

      //   })
      let data=[
      {'id':1,'filename':'Dosing-Information.png',"fileurl":"https://testblobmedia.blob.core.windows.net/media/dosing Information.png",'filetype':'JPG'},
      {"id": 2, "filename": 'MIR-Sign-Dr-Abigail-Allan.pdf', "fileurl": 'https://testblobmedia.blob.core.windows.net/media/mirformfilled.pdf',"filetype": 'pdf'},
      {"id": 3, "filename": 'MIR-Empty.pdf', "fileurl": 'https://testblobmedia.blob.core.windows.net/media/mirformblank.pdf',"filetype": 'pdf'},
      {"id": 4, "filename": 'Feeling-Guide.pdf', "fileurl": 'https://testblobmedia.blob.core.windows.net/media/CORDASTINfeelingsv2.pdf',"filetype": 'pdf'}
      ]
      setDocData(data);
      setAllDoc(data);

      return()=>{
        fileSelect(null,'present')
      }
    }, [])


    useEffect(() => {
    
      
      debugger
      // if(fileUrl.length>0){
        if(open && fileUrl){
          debugger
          console.log('inside')
          if(instance){
            instance.loadDocument(fileUrl)
            //console.log(instance)
          }else{
            WebViewer(
              {
                path: '/webviewer/lib',
                initialDoc: fileUrl,
                licenseKey:'Optiks Solutions, Inc (p360.com):OEM:ZING Engagement Suite, BirdzAI::B+:AMS(20230818):BAA5A63D0447780AF360B13AC982527860615FC53EBB427B7C62159434401EFE14E4BEF5C7'
              //  fullAPI: true,
              //  disableVirtualDisplayMode: true,
              },
              viewer.current,
            ).then(async (instance) => {
              setInstance(instance);
              
              const { documentViewer, annotationManager, Annotations,PDFNet } = instance.Core;
              // await PDFNet.initialize();
              setAnnotationManagerNew(annotationManager);
        
              documentViewer.addEventListener('documentLoaded', () => {
                const rectangleAnnot = new Annotations.RectangleAnnotation({
                  PageNumber: 1,
                  // values are in page coordinates with (0, 0) in the top left
                  X: 100,
                  Y: 150,
                  Width: 200,
                  Height: 50,
                  Author: annotationManager.getCurrentUser()
                });
        
                annotationManager.addAnnotation(rectangleAnnot);
                // need to draw the annotation otherwise it won't show up until the page is refreshed
                annotationManager.redrawAnnotation(rectangleAnnot);
        
                annotationManager.on('annotationChanged', async (e:any) => {
                  // If annotation change is from import, return
                  if (e.imported) {
                    return;
                  }
              
                  const xfdfString = await annotationManager.exportAnnotationCommand();
                  // Parse xfdfString to separate multiple annotation changes to individual annotation change
                  const parser = new DOMParser();
                  const commandData = parser.parseFromString(xfdfString, 'text/xml');
                  const addedAnnots = commandData.getElementsByTagName('add')[0];
                  const modifiedAnnots = commandData.getElementsByTagName('modify')[0];
                  const deletedAnnots = commandData.getElementsByTagName('delete')[0];
              
                  // List of added annotations
                  addedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'add');
                  });
              
                  // // List of modified annotations
                  modifiedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'modify');
                  });
                  
                  // // List of deleted annotations
                  deletedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'delete');
                  });
        
                });
        
              });
            });
      }
      //}
      }
      else{
        setInstance(null)
      }
      
        }, [open,fileUrl]);

 

    
    const convertToXfdf = (changedAnnotation, action) => {
            let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
            if (action === 'add') {
              xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
            } else if (action === 'modify') {
              xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
            } else if (action === 'delete') {
              xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
            }
            xfdfString += `</xfdf>`;
            return xfdfString;
          }
          
          // helper function to send annotation changes to WebSocket server
          const sendAnnotationChange = (annotation, action) => {
            console.log('adasdas')
            if (annotation.nodeType !== annotation.TEXT_NODE) {
              const annotationString = serializer.serializeToString(annotation);
          
              const annotations = convertToXfdf(annotationString,action);
              console.log("annotation Data from UI ::" + annotationString +"\t");
             
              if(room)
              room.localParticipant.dataTracks.forEach(element => {
            
                element.track.send(JSON.stringify({"type":"annotation","value":annotations}))
                });



              //setAnnotationData(annotations);
        
          
            //  getAnnotationData && getAnnotationData(annotations);
              // connection.send(JSON.stringify({
              //   documentId: DOCUMENT_ID,
              //   annotationId: annotation.getAttribute('name'),
              //   xfdfString: convertToXfdf(annotationString, action)
              // }));
            }
            else{
              console.log("No data for annotations");
            }
          }    

          const remoteAnnote = async(annotationManagerNew)=>{ 
            debugger;
            const annotations = await annotationManagerNew.importAnnotCommand(videoCallAnnotaion);
            await annotationManagerNew.drawAnnotationsFromList(annotations);
           }
         
          useEffect(()=>{
        
            if(videoCallAnnotaion){
             
                
             if(AnnotationManagerNew){
            
              remoteAnnote(AnnotationManagerNew);
              
             }
            
            }
          },[videoCallAnnotaion])
    const handleOptionsClick = (i)=>{
        debugger;

        console.log('240')
            if(videoCallStarted){
              setOptionsdiv(!optionsdiv);
              setHoveredCart(i);
            }
            else{

            }
          
       
    }
    const calcHeightWidth=()=>{
      let doc:any=document
      let div:any=document.querySelector('#main') 
      let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
      let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
      let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
      let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
  
      if(videoCallStarted){}
      // console.log("gc modal toppad",divpaddingTop);
      // console.log("gc modal bottompad",divpaddingbottom);
      // console.log("gc modal leftpad",divpaddingleft);
      // console.log("gc modal rightpad",divpaddingright);
      // console.log("gc modal height",window.innerHeight-divpaddingTop-divpaddingbottom);
      // console.log("gc modal width",window.innerWidth-divpaddingleft-divpaddingright);
      // if(window.innerWidth<=1200){
        setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
        setModalWidth(window.innerWidth-divpaddingleft-divpaddingright);

       
      //}
     
    }

    useEffect(() => {
      window.addEventListener('orientationchange', () => {
        getHeight();
        calcHeightWidth();
      });
      return () => {
        window.removeEventListener('orientationchange', () => {
          getHeight();
          calcHeightWidth();
        })
      }
    }, [])
    
    useEffect(()=>{

      debugger
     
        if(showdocviewer && fileUrl){
          console.log()
          if(mobileInstance){
            mobileInstance.loadDocument(fileUrl)
            //console.log(instance)
          }else{
            console.log('inside')
            debugger
         WebViewer(
              {
                path: '/webviewer/lib',
                initialDoc: fileUrl,
                licenseKey:'Optiks Solutions, Inc (p360.com):OEM:ZING Engagement Suite, BirdzAI::B+:AMS(20230818):BAA5A63D0447780AF360B13AC982527860615FC53EBB427B7C62159434401EFE14E4BEF5C7'
               // extension:'SVG'
              //  fullAPI: true,
              //  disableVirtualDisplayMode: true,
              },
              viewerMobile.current,
              
            ).then(async(instance) => {
              console.log('inside')
              setMobileInstance(instance);
              
              const { documentViewer, annotationManager, Annotations,PDFNet } = instance.Core;
              //await PDFNet.initialize();
              setAnnotationManagerNew(annotationManager);
        
              documentViewer.addEventListener('documentLoaded', () => {
                const rectangleAnnot = new Annotations.RectangleAnnotation({
                  PageNumber: 1,
                  // values are in page coordinates with (0, 0) in the top left
                  X: 100,
                  Y: 150,
                  Width: 200,
                  Height: 50,
                  Author: annotationManager.getCurrentUser()
                });
        
                annotationManager.addAnnotation(rectangleAnnot);
                // need to draw the annotation otherwise it won't show up until the page is refreshed
                annotationManager.redrawAnnotation(rectangleAnnot);
        
                annotationManager.on('annotationChanged', async (e:any) => {
                  // If annotation change is from import, return
                  if (e.imported) {
                    return;
                  }
              
                  const xfdfString = await annotationManager.exportAnnotationCommand();
                  // Parse xfdfString to separate multiple annotation changes to individual annotation change
                  const parser = new DOMParser();
                  const commandData = parser.parseFromString(xfdfString, 'text/xml');
                  const addedAnnots = commandData.getElementsByTagName('add')[0];
                  const modifiedAnnots = commandData.getElementsByTagName('modify')[0];
                 const deletedAnnots = commandData.getElementsByTagName('delete')[0];
              
                  // List of added annotations
                  addedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'add');
                  });
              
                  // List of modified annotations
                  modifiedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'modify');
                  });
                  
                  // List of deleted annotations
                  deletedAnnots.childNodes.forEach((child) => {
                    sendAnnotationChange(child, 'delete');
                  });
        
                });
        
              });
            });
        }
        }
        else{
          setMobileInstance(null);
        }
     
      console.log(showdocviewer && fileUrl)
      getHeight();
     
    },[showdocviewer,fileUrl])
    
    const handleDocViewerMobile = (e)=>{
      debugger
      
      setFileUrl(e)
      
      setShowdocviewer(!showdocviewer);

    }

   const showCartHandler = (i)=>{
        setHoveredCart(i);
   }

   const hideCartHandler=()=>{
          setHoveredCart(-1)
   }

    const handleAllFilesClick  =()=>{
        setActiveClass('activedoctab');
        setActiveDocClass('');
        setActiveVideoClass('');
        setDocData(allDoc);
    }
    const handleDocsClick =()=>{
        setActiveClass('');
        setActiveDocClass('activedoctab');
        setActiveVideoClass('');
      //  let onlydocsData = allDoc.filter(i => i.filetype === 'doc');
      //  setDocData(onlydocsData);
    }
    const handleVideosClick  =()=>{
        setActiveClass('');
        setActiveDocClass('');
        setActiveVideoClass('activedoctab');
        

        let onlyVideosData = allDoc.filter(i => i.filetype === 'video');
        setDocData(onlyVideosData);
    }

    const fileSelect=(item,viewOrPresent)=>{
      debugger
      if(item){
        //setFileUrl("https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK")
        if(isMobile){
          handleDocViewerMobile(item.fileurl)
        }else{
          setOpen(true)
          setFileUrl(item.fileurl)
        }
       

        //instance.loadDocument(item)
        if(room && viewOrPresent=="present" ){
          room.localParticipant.dataTracks.forEach(element => {    
            element.track.send(JSON.stringify({"type":"pdftron","value":item.fileurl})) 
            // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
          });
        }
      }
      else{
        if(isMobile){
          handleDocViewerMobile(null)
        }else{
          setOpen(false) 
          setFileUrl('')
        }
        
        if(room && viewOrPresent=="present" ){
          room.localParticipant.dataTracks.forEach(element => {    
            element.track.send(JSON.stringify({"type":"pdftron","value":null})) 
            // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
          });
        }
      }
      if(viewOrPresent=="view"){
        if(room){
          room.localParticipant.dataTracks.forEach(element => {    
            element.track.send(JSON.stringify({"type":"pdftron","value":null})) 
            // element.track.send(JSON.stringify({"type":"file","value":"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}))
          });
        }
      }
     
    }

    const searchData=(e)=>{
      console.log(e)
      if(e.length==0){
        if(activeClass=='activedoctab')
        setDocData(allDoc);
        else if(activeDocClass=='activedoctab')
        { 
          let onlydocsData = allDoc.filter(i => i.filetype === 'doc');
          setDocData(onlydocsData);
       }
       else{
        let onlyVideosData = allDoc.filter(i => i.filetype === 'video');
        setDocData(onlyVideosData);
       }
      }
      else{
        let temp=docData.filter((x:any)=>x.filename.toLowerCase().indexOf(e.toLowerCase())>=0)
        setDocData(temp)
      }
     
    }

   const handleFolderStructure=()=>{

   }
   const getChild=(x)=>{
    debugger
    if(x.child){
     setparent(localData) 
     setLocalData(x.child)
    }
   }

   const getparent=(parent,id,notId)=>{
    debugger
    if(!id)
    return false
      let hasChild=false;
      let found=false;
      for(let i=0;i<parent.length;i++){
        if(parent[i].notId)
        return false
        if(parent[i].id==id)
        {
          found=true
          break;
        }
        if(parent[i].child)
        {
         if(getparent(parent[i].child,id,notId))
         {
            return true
         }
        }
        
      }
      if(found){
      setparent(parent);
      return true 
     }
     else{
      return false;
     }
      
   }
  const backFolder=()=>{
    console.log(parent)
    if(parent.length>0){
      debugger
      let p=getparent(data,parent[0].parentId,localData[0].id);
      if(!p)
      setparent([])
      
    }
    setLocalData(parent)

  }
  
  return (
    <>

    
    <div className='media-main-div' style={{height:mediaHeight}}>
        <div className='media-sidebar-div'>
                <div className='media-sidebar-innerDiv'>

                    {/* SearchBar  */}
                    <div className='searchbar-div'>
                        
                        <div className='searchbar-input-div'>
                            <input className='docs-search-input' onChange={(e)=>{searchData(e.target.value)}} type='search' placeholder={t('Search file')}/>
                        </div>
                    </div>

                    {/* Tabs  */}
                    <div className='tabs-main-div'>

                        <div className='tabs-heading-div' >

                            <div className='tabs-inner-div'>
                            <div className={`doctab ${activeClass}`} onClick={()=>{handleAllFilesClick();setIsFolder(false)}}>{t('All files')}</div>
                             <div className={`doctab ${activeDocClass}`} onClick={()=>{setIsFolder(true);handleDocsClick()}}>{t('Folder')}</div>
                            {/* <div className={`doctab ${activeDocClass}`} onClick={handleDocsClick}>Docs</div>
                            <div className={`doctab ${activeVideoClass}`} onClick={handleVideosClick}>Videos</div> */}
                            </div>

                        </div>
                          {
                            !isfolder?
                            <div className='doc-list-div'>
                            <ul className='docs-ul'>
                              {/* <li onClick={()=>{fileSelect("https://pdftronlibfiles.blob.core.windows.net/pdftron/AI-900-demo.pdf");}}>doc1</li>
                              <li onClick={()=>{fileSelect("https://pdftronlibfiles.blob.core.windows.net/pdftron/sample.pdf");}}>doc2</li>
                              <li onClick={()=>{fileSelect("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4")}}>Video</li> */}
                            {docData?.map((item,i)=>{
                                 return (
                                    <>
                                            <div className={`listitem-div ${hoveredCart === i?'withBg':''}`} onMouseLeave={hideCartHandler}
                                                                        onMouseEnter={()=>showCartHandler(i)}>
                                                    <li className='docs-li' onClick={()=>{
                                                      
                                                      fileSelect(item,'view');
                                                      }}
                                                      >
                                                        <span style={{  paddingRight: '1rem'}}><img src={DocIcon} alt='docIcon'/></span>
                                                        {item?.filename}

                                                    </li>
                                                    {/* {
                                                      videoCallStarted?
                                                      <button className="file-options" onClick={()=>{setOptionsdiv(!optionsdiv);handleOptionsClick(i)}}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                      </svg>
                                                  </button>:null
                                                    } */}
                                                   

                                                    {
                                                      videoCallStarted?<span className='presentButton' onClick={()=>{setOptionsdiv(!optionsdiv);fileSelect(item,'present')}}>{t('Present')}</span>:null
                                                    }
                                                    

                                                   {/* {optionsdiv && (hoveredCart === i) && <div className='options-div'>
                                                       <div onClick={()=>{setOptionsdiv(!optionsdiv);fileSelect(item,'view')}}>
                                                        <FiberManualRecordIcon color='error'/> View
                                                        </div>
                                                        {
                                                          videoCallStarted?<div onClick={()=>{setOptionsdiv(!optionsdiv);fileSelect(item,'present')}}>
                                                          <FiberManualRecordIcon color='error'/> Present
                                                          </div>:null
                                                        }
                                                        
                                                      
                                                    </div>
                                                    } */}

                                            </div>
                                    </>
                            )
                        
                          })

                            }

                            {docData.length==0?<h6 style={{textAlign:"center"}}>No Data</h6>:null}

                            </ul>
                        </div>:
                        <div>
                          {
                            parent.length>0?<button className='docviwer-mob-backBtn' onClick={backFolder}>Back</button>:null
                          }
                          
                          <div className='folderContainer'>
                          {localData.map(x=>(
                            <div className='folderitem' onClick={()=>getChild(x)} >
                              <div>
                              {
                                     videoCallStarted? 
                                     <Popover
                                     id={id}
                                     open={openPopover}
                                     anchorEl={anchorEl}
                                     onClose={handleClose}
                                     
                                   >
                                    <div>
                                      <div onClick={()=>{setAnchorEl(null);fileSelect(x,'view')}}><Typography ><FiberManualRecordIcon color='error'/>View</Typography></div>
                                      <div onClick={()=>{setAnchorEl(null);fileSelect(x,'present')}}><Typography ><FiberManualRecordIcon color='error'/>Present</Typography></div>
                                     </div>
                                     
                                   </Popover>:null                 
                                                    }
                            {x.type=='dir'?<FolderIcon style={{fill:"var(--primary)"}} fontSize='large' />:<InsertDriveFileIcon onClick={(e:any)=>{
                              if(!videoCallStarted)
                              fileSelect(x,'view');
                              else
                              handleClick(e)
                              }} color='info' fontSize='large' />}
                            </div>
                              <div className='folderName'>{x.name}</div>
                
                            </div>
                          ))}
                        </div>
                        </div>
                          }
                       



                    </div>
                </div>
        </div>


        <div className='media-docviewer-div'>
           {open?
  <div className="webviewer" 
  ref={viewer}
  ></div>:null
           }
          
          
        </div>

    </div>


{/* Mobile Doc Viwer */}

    {showdocviewer && 
    
        <div className='docviwer-mob-div' style={{height:room?"85%":'100%',top:paddingValTop,bottom:paddingValBottom,left:paddingValLeft,right:paddingValRight}}>
          <div className='docviwer-flex-div'>
          <button className='docviwer-mob-backBtn' onClick={()=>{handleDocViewerMobile(null);fileSelect(null,'view')}}>Back</button>
                
           
                <div className="webviewerMobile" 
                ref={viewerMobile}></div>
          </div>
           
         

        </div>
    }


    </>
  )
}

export default Media