import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";

import Chip from '@mui/material/Chip';

import { useGetPrescribersQuery,useAddContactQuery} from "../../utils/ApiContacts";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import config from '../../env.json';
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import {parsePhoneNumber}from 'react-phone-number-input';
// import ReactAutocomplete from "react-autocomplete";
import { addNewContact, checkNumberExist } from "../../apis/contacts/contactsApi";
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import { useDispatch,useSelector } from 'react-redux';
import ContactAlreadyAssign from '../../components/logoutModal/LogoutModal'
import {getDeviceType} from '../../utils/deviceTypeUtil';
import axios from "axios";

import "./AddContact.css";
import { data } from "jquery";
import { useTranslation } from 'react-i18next';

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function AddContact({ show, handleCloseParent }) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc,setAssoPresc]=useState<any>({})
  const [post, setPost] = useState([]);
  const [disabled,setDisabled]=useState(true);
  const [temp,setTemp]=useState('')
  const [secPrescribers,setSecPrescribers]=useState<any>([]);
  const [showModal,setShowModal]=useState(false);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [mutliAssociatePrescriberCheck, setMutliAssociatePrescriberCheck]= useState<any>(true);
  const dispatch = useDispatch();
  const [phoneErr,setPhoneErr]= useState<any>(true);
  // const {
  //   data: post,
  //   isFetching,
  //   isSuccess,
  // } = useGetPrescribersQuery({
  //   page: 0,
  //   pageSize: 5,
  //   search: assoPrescName,
  // });
  // useEffect(()=>{

  // },[se])

  useEffect(() => {
    window.addEventListener('orientationchange', calcHeightWidth);
    return () => {
      window.removeEventListener('orientationchange', calcHeightWidth)
    }
  }, [])

  useEffect(() => {
   // console.log(axios.CancelToken.source())
   if(config.REACT_APP_MULTI_PRES_ASSOCIATION){
    let value = config.REACT_APP_MULTI_PRES_ASSOCIATION
    
    setMutliAssociatePrescriberCheck(config.REACT_APP_MULTI_PRES_ASSOCIATION === "N"? false: true)
   }
   
   calcHeightWidth();
   window.addEventListener('resize',(e) => {
    calcHeightWidth()
  })
  }, [])
  const calcHeightWidth=()=>{
    let doc:any=document
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

    console.log("gc modal toppad",divpaddingTop);
    console.log("gc modal bottompad",divpaddingbottom);
    console.log("gc modal leftpad",divpaddingleft);
    console.log("gc modal rightpad",divpaddingright);
    console.log("gc modal height",window.innerHeight-divpaddingTop-divpaddingbottom);
    console.log("gc modal width",window.innerWidth-divpaddingleft-divpaddingright);
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
      setModalWidth(window.innerWidth-divpaddingleft-divpaddingright);
    }
   
  }
  const setPrescriber=(x)=>{
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setHiddenPresList(true);
    setDisabled(false)
    
    
  }
  const handleYOrNPopup =(e:any) =>{
    console.log(e)
    if(e=== true){
      // setDisabled(true)      
      let phn:any=parsePhoneNumber(othersPhone);
      console.log(phn)
      let payload={
        AcctPh_Nbr:phn.nationalNumber,
        Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
        AcctFull_Nm:othersName,
        IntlCallPfx_Nbr:phn.countryCallingCode
      }
      addNewContact(payload,(res:any)=>{
      
        if(res){      
          closePopup(true);
          dispatch(setShowSuccessSnackBar(true));
        }
        else{
          setDisabled(false)
          closePopup(false);
          dispatch(setShowErrorSnackBar(true));
        }
      })
    } else{
      setShowModal(false) 
    }
  }
  const setMultiPresAssocation =(e)=>{
    // console.log({config.REACT_APP_MULTI_PRES_ASSOCIATION})
    // if(e === 0){
    //   // setDisabled(true)
    // } else if (secPrescribers.length > config.REACT_APP_MULTI_PRES_ASSOCIATION){
    //   checkDisabled(true,othersPhone.length>0,false);
    // }
    console.log(e)
    console.log(secPrescribers)
  }
  const closePopup=(torf)=>{
    setOthersName('')
    setOthersPhone('')
    setAssoPrescName('');
    setDisabled(true);
    setAssoPresc({})
    setSecPrescribers([])
    handleCloseParent(torf);
   
  }
  async  function addContact(){
    setDisabled(true)      
    let phn:any=parsePhoneNumber(othersPhone);
    console.log(phn)
    let payload={
      AcctPh_Nbr:phn.nationalNumber,
      Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
      AcctFull_Nm:othersName,
      IntlCallPfx_Nbr:phn.countryCallingCode
    }
    console.log(
      payload
    )
    checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
      console.log(res)  
      if(res)  {
        setShowModal(true)
            }  else{
              addNewContact(payload,(res:any)=>{
      
                if(res){      
                  closePopup(true);
                  dispatch(setShowSuccessSnackBar(true));
                }
                else{
                  setDisabled(false)
                  closePopup(false);
                  dispatch(setShowErrorSnackBar(true));
                }
              })
            }
      
    })
    // if()
   
    // axios
    //     .post(
    //       config.REACT_APP_CONTACTS_API_BASE+"/contact",
    //       {
    //         AcctPh_Nbr:othersPhone,
    //         Prescriber_Id:assoPresc.Id as any,
    //         AcctFull_Nm:othersName,
    //         IsPrescriber:false
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + sessionStorage.getItem("login"),
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       closePopup(true);
    //       //handleCloseParent(true)
    //     })
    //     .catch((err)=>{
    //       closePopup(false);
    //      // handleCloseParent(false)
    //     })
  }
  // useEffect(() => {
  //   setSecPrescribers(secPrescribers);
  // }, [secPrescribers])
  const clearData=(e)=>{
    console.log("gc values changes e set",secPrescribers.length);
    ;
    if(!mutliAssociatePrescriberCheck && e.length==0)
      {setSecPrescribers([]);
        console.log("gc values changes e cleared!");
        checkDisabled(othersName.length>0,!phoneErr,e.length>0);
      }
  }
  const checkDisabled=(name,phone,presList)=>
  {
    if(name && phone && presList){
      setDisabled(false);
    }
    else setDisabled(true);
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        //onClose={()=>{closePopup(false)}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div className="modal-box contact-box addcontact-height" style={mobileStyle?{height: window.innerWidth > 768 ? 'calc(94.7458%)' : 'calc(87.75%)',width:modalWidth+'px'}:{}}>
            <div className="modal-header d-flex ">
              <h1 className="font-createcontact">{t('Create Contact')}</h1>
              <span
                onClick={() => {
                  closePopup(false);
                }}
                className="close"
              >
                ×
              </span>
            </div>

            <div className="modal-body addContacts" 
            style={mobileStyle?{height:(modalHeight - 99.7)+'px'}:{}}
            >
              <form onSubmit={(e)=>{e.preventDefault();addContact();}}>
              <div className="selecttype-radio-btns">
                <FormControl>
                  <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue="others"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                  >
                    <FormControlLabel
                      disabled
                      value="prescriber"
                      control={<BpRadio />}
                      label="Prescriber"
                    />
                    <FormControlLabel
                      value="others"
                      control={<BpRadio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-fields-div">
                <TextField
                  className="roundborder"
                  onChange={(e) => {
                    if(e.target.value.length>0){
                      checkDisabled(true,!phoneErr,secPrescribers.length>0)
                    }
                    else{
                      checkDisabled(false,!phoneErr,secPrescribers.length>0)
                    }
                    setOthersName(e.target.value);

                  }}
                  value={othersName}
                  label={t('Recipient Name')}
                  placeholder={t("Enter the recipient's name")}
                  color="secondary"
                  focused
                  // required
                />
                <CountryDropdown
                  errorFunc={(e)=>{
                    setPhoneErr(e);
                      checkDisabled(othersName.length>0,!e,secPrescribers.length>0)

                  }}
                  disableColored=""
                  valueNumber={othersPhone}
                  onChangeNumber={(e) => {
                    
                    setOthersPhone(e);
                  }}
                />
                {/* <TextField
                  label="Phone Number"
                  onChange={(e) => {
                    setOthersPhone(e.target.value);
                  }}
                  value={othersPhone}
                  placeholder="Please enter the phone number"
                  color="secondary"
                  focused
                /> */}
                <h1 className="font-md title-assocaite_prescribers">{t('Associated Prescribers')}</h1>
                {/* {
                  secPrescribers.length>0?
                  <div className="secPresDiv">
                {
                  secPrescribers.map((x:any)=>(<li key={x.Id} id={x.Id}><Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm} onDelete={()=>{}} /></li>))
                }
                </div>
                :null
                }  */}
                {mutliAssociatePrescriberCheck?
                <div className="secPresDiv" style={secPrescribers.length>0?{display:'block'}:{display:'none'}}>
                {
                   secPrescribers.map((x:any)=>(<Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm} onDelete={()=>{
                    let temp=secPrescribers.filter((p:any)=>{if(p.Id!==x.Id) return p;})
                    console.log(temp)
                    setSecPrescribers(temp);
                    checkDisabled(othersName.length>0,!phoneErr,temp.length>0)   
                   }} />))
                }
                </div>: null}
                <AssociatedPresList
                  placeholder={t('Search for an Associated Prescriber')}
                  label=""
                  styleClass=""
                  clearDataOnSelect={mutliAssociatePrescriberCheck}
                  multisel={mutliAssociatePrescriberCheck}
                  onChangeInputValue={(e:any)=>{setMultiPresAssocation(e); 
                  clearData(e)
                    
                
                }}
                  setAssoPresc={(e: any) => {
                    let t:any=[e];
                    console.log("gc values changes e t",e);
                    if(mutliAssociatePrescriberCheck){
                      t=t.concat(secPrescribers);
                    }
                    const result:any = [];
const map = new Map();
for (const item of t) {
    if(!map.has(item.Id)){
        map.set(item.Id, true);    // set any value to Map
        result.push(item);
    }
}
checkDisabled(othersName.length>0,!phoneErr,result.length>0)                
                    
                    
                    setSecPrescribers(result);

                  }}
                />

                {/* <h1 className="font-md">Primary Prescriber</h1>
                <AssociatedPresList
                  placeholder={"Search for an Associated Prescriber"}
                  onChangeInputValue={(e:any)=>{console.log(e)}}
                  label=""
                  styleClass=""
                  setAssoPresc={(e: any) => {
                    setPrescriber(e);
                    let t:any=[e];
                    t=t.concat(secPrescribers);
                    setSecPrescribers(t);

                  }}
                /> */}

                
                <button
                  type="submit"
                  className="create-btn"
                  disabled={disabled}
                  style={{ opacity: disabled ? 0.3 : 1 }}
                >
                  {t('Create')}
                </button>
              </div>
              </form>
            </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
      show={showModal}
      yorn={handleYOrNPopup}
      modalText={t('This number has already been assigned. Do you want to override the number?')}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowModal(false)
      }} />
    </div>
  );
}
