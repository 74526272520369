import React, { useState, useEffect, useRef } from "react";
import './Participant.css'
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import MicOffIcon from '@mui/icons-material/MicOff';
import { setVideoCallScreenShareStarted, setVideoCallShareUserId } from "../../../app/slice/videoCallSlice";
import { useSelector, useDispatch } from 'react-redux'
import { setVideoCallAnnotaion,setVideoCallChats } from "../../../app/slice/videoCallSlice";
//import Avatar from "../../../components/Avatar/Avatar";
import Avatar from '@mui/material/Avatar';



const Participant = (props:any) => {
 let { participant,mute,video,parentWidth,parentHeight,children,dominant,annotationset,disconnected}=props
  const videoCallChats:any=useSelector((state:any)=>state.floaterVideoCall.videoCallChats)
  const videoCallScreenShareStarted:any=useSelector((state:any)=>state.floaterVideoCall.videoCallScreenShareStarted)
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [dataTracks, setDataTracks] = useState<any>([]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [width,setWidth]=useState(10);
  const [height,setHeight]=useState(10);
  const [muted,setMuted]=useState(false);
  const [videoOff,setVideoOff]=useState(false)
  const [network,setNetwork]=useState('')
  const [border,setBorder]=useState('none')
  const [localChats,setLocalChats]=useState<any>([])

  // useEffect(()=>{
  //   setLocalChats(videoCallChats)
  // },[videoCallChats])

  function stringAvatar(name) {

    let n=name;
    if(n)
    {
      if(n.split(' ').length>2)
      n=`${name?.split(' ')[0][0]}${name?.toUpperCase().split(' ')[1][0]}`
      else
      n="U N"

    }
    return {

      sx: {

        cursor:"pointer",

        bgcolor: "#ffffff",

        color:"#734BD1",
        width:'4rem',
        height:'4rem'
      },

      children: n

    };

  }
const dispatch=useDispatch();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication:any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    console.log('sub')
    console.log('hi inside participant')
    console.log(videoCallChats)
    debugger
    if(participant.videoTracks instanceof Map)
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    if(participant.audioTracks instanceof Map)
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if(participant.dataTracks instanceof Map)
    setDataTracks(trackpubsToTracks(participant.dataTracks));

    const trackSubscribed = (track) => {
      
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }

      else if (track.kind === "data") {
        setDataTracks((dataTracks) => [...dataTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log('un')
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
      else if (track.kind === "data") {
        setDataTracks((dataTracks) => dataTracks.filter((a) => a !== track));
      }
    };
    function printNetworkQualityStats(networkQualityLevel, networkQualityStats) {
      // Print in console the networkQualityLevel using bars
      // console.log({
      //   1: '▃',
      //   2: '▃ ▄',
      //   3: '▃ ▄ ▅',
      //   4: '▃ ▄ ▅ ▆',
      //   5: '▃ ▄ ▅ ▆ ▇'
      // }[networkQualityLevel] || '');
      setNetwork({
        1:<SignalCellularAlt1BarIcon />,
        2:<SignalCellularAlt1BarIcon />,
        3: <SignalCellularAlt2BarIcon />,
        4: <SignalCellularAlt2BarIcon />,
        5: <SignalCellularAltIcon />
      }[networkQualityLevel] || '')
    
      if (networkQualityStats) {
        // Print in console the networkQualityStats, which is non-null only if Network Quality
        // verbosity is 2 (moderate) or greater
        console.log('Network Quality statistics:', networkQualityStats);
      }
    }
    
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    setNetwork({
      1:<SignalCellularAlt1BarIcon />,
      2:<SignalCellularAlt1BarIcon />,
      3: <SignalCellularAlt2BarIcon />,
      4: <SignalCellularAlt2BarIcon />,
      5: <SignalCellularAltIcon />
    }[participant.networkQualityLevel] || '')
    participant.on('networkQualityLevelChanged', printNetworkQualityStats);
    participant.on('subscribed',(track)=>{
     console.log('subscribed')
    });
    participant.on('unsubscribed',(track)=>{
      console.log('unsubscribed')
    });
    
    //resize2()
    return () => {
        
      setVideoTracks([]);
      setAudioTracks([]);
      setDataTracks([])
      participant.removeAllListeners();
      
    };
  }, [participant]);

  useEffect(() => {
    console.log('insde track',participant.videoTracks)
    
   
  }, [participant.videoTracks])
  
const resize2=()=>{
  
  let doc:any=document;
  let w=parentWidth
  let h=parentHeight;
  const boardArea = (w * h) 
//console.log(w,h)
// Calculate the tile area
var tileArea = boardArea / children

if(false){
  return {
    height:h-10,width:w-10,margin:1+'px'
  }
}
else{
// Get the sqr 
const squareRoot = Math.sqrt(tileArea)
debugger
// Get the Width 
const width = (squareRoot-100)

//console.log(h/w)
// Get the height
let hm=0.65//0.75<h/w?0.75:h/w;
const height = (width * hm)
// let childrens=doc.getElementsByClassName('participantsDiv')[0].children;
//     for (var s = 0; s < childrens.length; s++) {

//         // camera fron dish (div without class)
//         let element =childrens[s];
        
//         // custom margin
//         element.style.margin = 6 + "px"

//         // calculate dimensions
//         debugger
        
//         element.style.width =width+"px"//((100/children.length)-10)+"%"  //width + "px"
//         element.style.height =height+'px' //(width * ratio()) + "px"

//         // to show the aspect ratio in demo (optional)
//         //element.setAttribute('data-aspect', this._ratios[this._aspect]);

//     }
//console.log(h,w,height,width)
return {
  height,width,margin:1+'px'
}
}

}

  const areaNew=(increment,width,height)=> {

    let i = 0;
    let w = 0;
    let h = increment * ratio()+20;
    //let doc:any=document;
    //let children=chikdre//doc.getElementsByClassName('participantsDiv')[0].children;
    
    while (i < (children)) {
        if ((w + increment) > width) {
            w = 0;
            h = h + (increment * ratio())+20;
        }
        w = w + increment+20;
        i++;
    }
    if (h > height || increment > width) return false;
    else return increment;

}
  const resize=()=> {

    // get dimensions of dish
    // let doc:any=document;
    // let width=doc.getElementsByClassName('participantsDiv')[0].offsetWidth
    // let height=doc.getElementsByClassName('participantsDiv')[0].offsetHeight
    let height=parentHeight-20;
    let width=parentWidth-20
    console.log(height,width)
    // loop (i recommend you optimize this)
    let max = 0
    let i = 1
    while (i < 5000) {
        let area = areaNew(i,width,height);
        if (area === false) {
            max = i - 1;
            break;
        }
        i++;
    }
    
    // console.log(doc.getElementsByClassName('participantsDiv')[0])

    // remove margins
    max = max - (20);
    return {
      height:(max * (9/16)),width:max,margin:1+'px'
    }
    // set dimensions to all cameras
    //resizer(max);
}
const resizer=(width)=> {

    let doc:any=document;
    let children=doc.getElementsByClassName('participantsDiv')[0].children;
    for (var s = 0; s < children.length; s++) {

        // camera fron dish (div without class)
        let element =children[s];
        
        // custom margin
        element.style.margin = 10 + "px"

        // calculate dimensions
        debugger
        
        element.style.width =width*1.5 + "px"//((100/children.length)-10)+"%"  //width + "px"
        element.style.height = (width * ratio()) + "px"

        // to show the aspect ratio in demo (optional)
        //element.setAttribute('data-aspect', this._ratios[this._aspect]);

    }
}
const ratio=()=>{return 480/640}

  useEffect(() => {
    console.log('new',videoTracks)
    let isSharing=false;
    // videoTracks.map(x=>{
    //   console.log(x.name)
    //   if(x.name=="user-screen")
    //   {
    //     console.log(true)
    //     isSharing=true;
    //     dispatch(setVideoCallScreenShareStarted(true))
    //     dispatch(setVideoCallShareUserId(participant.sid))
    //   }
    //  })
    //  if(!isSharing){
    //   dispatch(setVideoCallScreenShareStarted(false))
    //   dispatch(setVideoCallShareUserId(null))
    //  }
    let videoTrack = videoTracks[0];
    videoTracks.map(x=>{
        console.log(x.name)
        if(x.name=="user-screen" && videoCallScreenShareStarted )
        {
          videoTrack=x;
        }
       return x;
    })
    if (videoTrack) {
      if(!videoTrack.isEnabled || !videoTrack.isStarted){
        setVideoOff(true)
      }
      videoTrack.attach(videoRef.current);
      videoTrack.on('disabled',()=>{console.log('disable');setVideoOff(true)})
    videoTrack.on('enabled', ()=>{console.log('enabled');setVideoOff(false)})
    videoTrack.on('stopped',()=>{console.log('stopped');setVideoOff(true)})
    videoTrack.on('started', ()=>{console.log('started');setVideoOff(false)})

      return () => {
        videoTrack.detach();
        videoTrack.off('disabled',()=>{setVideoOff(false)})
    videoTrack.off('enabled', ()=>{setVideoOff(false)})
    videoTrack.off('stopped',()=>{setVideoOff(true)})
    videoTrack.off('started', ()=>{setVideoOff(false)})
      };
    }

   
    // else
    // setVideoOff(true)

  }, [videoTracks]);


  useEffect(() => {
    const audioTrack = audioTracks[0];
   
    if (audioTrack) {
      if(!audioTrack.isEnabled){
        setMuted(true)
      }
      audioTrack.on('disabled',()=>{setMuted(true)})
      audioTrack.on('enabled', ()=>{setMuted(false)})
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
        audioTrack.off('disabled',()=>{setMuted(false)})
        audioTrack.off('enabled', ()=>{setMuted(false)})
      };
    }
  }, [audioTracks]);
  
  useEffect(() => {
    const dataTrack = dataTracks[0];  
    debugger
    if(dataTrack){
      
      dataTrack.on('message', message => {
        debugger
        
        console.log(JSON.parse(message))
        if(JSON.parse(message).type=="disconnect"){
          if(JSON.parse(message).value){
            disconnected(participant)
          }
        }
        if(JSON.parse(message).type=="annotation")
        dispatch(setVideoCallAnnotaion(JSON.parse(message).value))
        if(JSON.parse(message).type=="message"){
          
          //console.log(localChats);
          annotationset(JSON.parse(message).value)
        }
        if(JSON.parse(message).type=="screenshare"){
          if(JSON.parse(message).value==false){
            dispatch(setVideoCallScreenShareStarted(false))
            dispatch(setVideoCallShareUserId(null))
          }
          else{
           dispatch(setVideoCallScreenShareStarted(true))
           dispatch(setVideoCallShareUserId(participant.sid))
          }
        }
      
    })
    return () => {
      dataTrack.off('message', message => {
        console.log('recieved',message)
      })
    }
    }
    
   
  }, [dataTracks])
  useEffect(()=>{
console.log(localChats)
  },[localChats])
  useEffect(()=>{
    if(dominant){
      setBorder('3px solid green')
    }
    else
    setBorder('none')
  },[dominant])
  return (
    <div className={"video-wrapper video-"+children} style={{border:border}}>
       <div>
       <h3>{participant.identity}
      <span>{network}</span>
      {
      muted?<MicOffIcon />:null
      }
      
      </h3>
        </div>
      
      
      
      <video ref={videoRef} autoPlay={true}/>
      <div id={'div-'+participant.sid}> </div>
      {
        videoOff?
        <div className="videoOffIcon">
           <Avatar {...stringAvatar((participant?.identity))} />
       </div>:null
      }  
      
      
      
      <audio ref={audioRef} autoPlay={true} muted={mute} />
    </div>
  );
};

export default Participant;