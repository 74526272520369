import axios from "axios";
import config  from '../../env.json';
function getPersonalizationData(callback){
  // let {lang}=params;
    try {
        axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          debugger;
          callback(res.data);
        }).catch(()=>{
          debugger;
          callback([])
        })
    } catch (error) {
      debugger;
        callback([])
        
    }
}

function updatePersonalizationData(payload,callback){
  
  try {
      axios.post(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',payload,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then(res=>{
        callback(res.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}



export {getPersonalizationData,updatePersonalizationData}