import axios from "axios";
import config  from '../../env.json';
function sendMessageApi(payload,callback){
  

    try {
        axios.post(config.REACT_APP_MESSAGE_API_BASE +"/message",payload)
        .then(res=>{ 
          console.log(res)
          if(res.data.message === "Inappropriate message body"){
            callback(true, res.data);
          }else if(res.data.message === "You have reached the maximum number of promotional messages allowed to send per day. Please try again in 24 hours." || res.data.message === "You have recently sent a promotional message to this contact. Please try again in a few minutes." || res.data.message === "Distribution of branded and unbranded messages must be separated by 72 hours.") {
            callback(false, res.data.message);
          } else{
            callback(true);
          }
          
        }).catch(()=>{
          callback(false)
        })
    } catch (error) {
        callback(false)
    }
   
}
 




export {sendMessageApi}