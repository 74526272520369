import React,{useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from "@mui/material/Backdrop";
import Button from '@mui/material/Button';
import {msalInstance} from '../../index';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import './LogoutModal.css'
import { fabClasses } from '@mui/material';
import  config  from '../../env.json';
import {capacitormsal} from "capacitormsal";
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import { useTranslation } from "react-i18next";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
export default function LogoutModal({show,handleCloseParent, yorn, modalText}) {

  useEffect(() => {
    handleLogoutPopup(false)
  },[])

  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);

  const handleLogoutPopup=(torf)=>{
    // handleClose();
    yorn(torf)
    handleCloseParent()
    // if(torf){
    //   logout();
    // }
  }
  const logout=()=>{
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
        capacitormsal.signOut({
          authority: config.REACT_APP_AD_AUTHORITY as string,
          clientId: config.REACT_APP_AD_CLIENTID as string,
          redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
          scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });               
          })
          .catch(error => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/"  
            });
          })
      }  else if(config.REACT_APP_AUTH_TYPE==='zing'){
        sessionStorage.clear();
        localStorage.clear();
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });   
      } else {
        let browser = InAppBrowser.create('https://pingfed-ut.merck.com/idp/startSLO.ping', '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });
      }
    } else {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
      let account=sessionStorage.getItem('loginName') as string
        msalInstance.logoutRedirect({
          account: msalInstance.getAccountByUsername(account)
      }).catch((e:any) => {
          console.log(e);
        });
      }
      else
        {
            sessionStorage.clear();
            localStorage.clear();
            var logoutUrl = "https://pingfed-ut.merck.com/idp/startSLO.ping";
            window.location.href = logoutUrl
             window.location.href =config.REACT_APP_PING_REDIRECT_URL;
          //window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
        }
    }
    
  }
  return (
    <div>
         <Modal
      
      open={show}
      onClose={handleCloseParent}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      >
        <Fade 
        in={show}
        >
        <Box>
            <div className='modal-box logout--modal'>
            <div className="modal--body">
                <div className='modal--wrapper'>
                    <p className='modal--content'>{modalText}</p>
                    <div className="modal--btn logout-modal-btn">
                        <div className='modal--btn--left'>
                            <button onClick={()=>{handleLogoutPopup(true)}}>
                            {/* <i className="fa fa-check"></i> */}
                            <DoneIcon className='logout_page_icons' />
                            {t('Yes')}</button>
                        </div>
                        <div className='modal--btn--right'>
                            <button onClick={()=>{handleLogoutPopup(false)}}>
                            {/* <i className="fa fa-remove"></i> */}
                                {/* <i className="fa fa-remove"></i> */}
                                <CloseIcon className='logout_page_icons' />
                                {t('No')}</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </Box>
        </Fade>
      </Modal>
    </div>
  )
}
