import React, { useEffect, useState } from 'react';
import moment from 'moment';
import '../chat/Message.css'
import Tooltip from '@mui/material/Tooltip';
import tickicon from '../../assets/icons/tick-double.svg'
import { isImage, isVcard, isAudio, isOtherAudio, isOtherVideo, isPdf, isVideo,isImgLink } from '../../utils/FileTypes';
import { retry } from '@reduxjs/toolkit/dist/query';
// import linkifyHtml from "linkifyjs/html";
import Linkify from 'linkify-react';
import axios from 'axios';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { formatDateIntoTodayYesterdayFormat } from '../../utils/Date';
import ErrorIcon from '@mui/icons-material/Error';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import config from '../../env.json';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from "react-i18next";
import Pusher from 'pusher-js'
import { useSelector, useDispatch } from 'react-redux';
import { setSkipHeader } from '../../app/slice/loginSlice';
var decode = require('decode-html');




export default function ChatComponent(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.login.token)
  const [messageData, setMessageData] = useState({});
  const [body, setBody] = useState('');
  const [incoming, setIncoming] = useState(true);
  const [attachment, setAttachment] = useState([]);
  const [sentmsg, setSendMsg] = useState(true)
  const [random, setRandom] = useState((Math.random() + 1).toString(36).substring(9))
  const [iswhatsapp, setIswhatapp] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [msgStatus, setMsgStatus] = useState<any>();
  const [msgStatusPusher, setMsgStatusPusher] = useState<any>();
  useEffect(() => {

    if (props.message) {

      // console.log(props.message['Recipient Msg Body'])
      // messageSubscriber()
      // setMsgStatus(props.message['Recipient Msg Status'])
      // messageSubscriber()



      // if(props.pusher){
      //   const channel = props.pusher.subscribe("chatdetailstatus");
      //       channel.bind(props.message['Message Id'], (data) => {
      //           console.log('pusher',data)
      //           //setMessageData(data);
      //           debugger
      //       });
      // }

      if (props.message['Recipient Sender Typ'] == "EMP") {
        setIncoming(false);
      } else {
        setIncoming(true)
      }
      // debugger
      if (props.message['Recipient Channel Type'] === "SMS" || props.message['Recipient Channel Type'] === "sms") setIsSms(true); else setIsSms(false);
      if (props.message['Recipient Channel Type'] === "whatsa") setIswhatapp(true); else setIswhatapp(false);
      //  debugger
      setBody(props.message['Recipient Msg Body']);
      if (props.message['Recipient Msg Attachment'])
        setAttachment(JSON.parse(props.message['Recipient Msg Attachment']))
      setMessageData(props.message);

      // console.log(props.message['Message Id'])
      // debugger
      // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      //   const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      //     // cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   cluster:config.REACT_APP_PUSHER_CLUSTER,
      // //   encrypted: true,
      // });

      // console.log(contactNumber)
      // const channel = pusher.subscribe("chatdetailstatus");
      if (props.message['Message Id'] && props.msgPusherSubscriber && props.msgPusherSubscriber.bind) {
        props.msgPusherSubscriber.unbind(props.message['Message Id']);
        props.msgPusherSubscriber.bind(props.message['Message Id'], (data) => {
          //   if(data)
          debugger
          let x = JSON.parse(data.Body);
          console.log(x.status, props.message['Message Id'] )
          setMsgStatus("")
          if(x)
          setMsgStatus(x.status)
        });
      }
      // return () => {
      //   pusher.unsubscribe("chatdetailstatus");
      // };
    }

  }, [props])

  // useEffect(() => {
  //   if(props.message){
  //     setMsgStatus(props.message['Recipient Msg Status'])
  //   }
  // },[props.message['Recipient Msg Status']])

  useEffect(() => {
    if(props.message && props.message['Recipient Msg Status']!==msgStatus)
    setMsgStatus(props.message['Recipient Msg Status'])
  },[props.message['Recipient Msg Status']])

  const forceFileDownload = (response: any, filename: any) => {

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  const downloadWithAxios = (url) => {
    let urlSplit = url.split("/");
    let filename = urlSplit[urlSplit.length - 1];
    delete axios.defaults.headers.common["Authorization"];
    dispatch(setSkipHeader({ 'skipHeader': true }));
    fetch(url)
      .then(response => {
        forceFileDownload(response, filename);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        //   response.blob().then(blob => {
        //     let url = window.URL.createObjectURL(blob);
        //     let a = document.createElement('a');
        //     a.href = url;
        //     a.download = 'employees.json';
        //     a.click();
        //   });
        //   //window.location.href = response.url;
        // }
      });
  }

  // useEffect(() => {
  //   const pusher = new Pusher("e4d5c7d058642d0b529f", {
  //     // cluster: config.REACT_APP_PUSHER_CLUSTER,
  //   cluster: "mt1",
  // //   encrypted: true,
  // });
  // debugger
  // // console.log(contactNumber)
  // const channel = pusher.subscribe("chatdetailstatus");
  // channel.bind(props.message['Message Id'],(data) => {
  // //   if(data)
  //     debugger
  //     let x = data;
  //     // console.log(JSON.parse(data.Body))
  //     console.log(data)
  //     // setChats(data);
  // });

  // return () => {
  //   pusher.unsubscribe("chatdetailstatus");
  // };
  // })

  const messageSubscriber = () => {
    // console.log(props.message['Message Id'])
    // debugger
    // const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      // cluster: config.REACT_APP_PUSHER_CLUSTER,
      cluster: config.REACT_APP_PUSHER_CLUSTER,
      //   encrypted: true,
    });
    // debugger
    // console.log(contactNumber)
    const channel = pusher.subscribe("chatdetailstatus");
    channel.bind(props.message['Message Id'], (data) => {
      //   if(data)
      // debugger
      let x = data;
      // console.log(data)
      // console.log(JSON.parse(data.Body))
      // messageStatusInfo()
      // console.log(data)
      // getStatus()
      // setChats(data);
    });

    // return () => {
    //   pusher.unsubscribe("chatdetailstatus");
    // };
  }
  const checkIfURL = (textToCheck) => {
    // return linkifyHtml(textToCheck, {
    //   defaultProtocol: "https",
    //   className: "linkified",
    //   download:textToCheck,
    //   nl2br: false,
    //   validate: true,
    //   formatHref: function(href, type) {
    //     console.log(href, type);
    //     return href;
    //   },
    //   format: function(value, type) {
    //     console.log(value, type);
    //     return value;
    //   },
    // });
  }
  // const messageStatusInfo = (e) => {
  //   // if(e=== 'delivered'){
  //   //   setMsgStatus('delivered')
  //   // } else if(e==='failed'){
  //   setMsgStatus(e)
  //   // }
  // }
  const createMarkup = () => {

    // return {
    //   __html: checkIfURL(body)    };
  }
  const handleEnlargeable = (imgdisurl) => {
    // console.log('handling image enlarge child',imgdisurl);
    props.handleImgPopup(imgdisurl)

  }
  const getStatus = () => {

    // if(e=== 'delivered' || e==='failed'|| e==='In Progress'){
    //   setMsgStatus(e)
    // }

    let inc = false;
    if (props.message['Recipient Sender Typ'] === "EMP") {
      // inc=true
      return props.message['Recipient Msg Status']
    }
    else {

    }
    // if(props.message['Recipient Msg Status']=="In Progress")
    // return false
    // else return true
  }
  return (
    <>


      <div className={incoming ? 'msg-row received' : 'msg-row sender'}>
        {/* <div className='msg-row received'> */}


        {/* if received msg */}
        {!(messageData['Recipient Sender Typ'] == "EMP") ?
          <div className='avatar-area' >
            <div className='avatar-block'>
              <i className="fas fa-user i-2"></i>
            </div>
          </div>
          : null}
        <div className='msgtext' >

          <div className='msg' >
            {
              attachment.length > 0 ?
                <div className='msg-attachment'>
                  {
                    attachment.map((x: any) => {

                      if (isOtherAudio(x.attachmentType))
                        return (<div className="media-files card">
                          <a
                            className="card-a"
                            href="javascript:void(0)"
                          // @click="downloadWithAxios(attachment.attachmentUrl)"
                          >
                            <i className="fas fa-file-audio i-2p5"></i>
                            <h5 className="ml-2 font-md">
                              {x.attachmentName}
                            </h5>
                          </a>
                        </div>)
                      if (isOtherVideo(x.attachmentType)) {
                        return (
                          <div className="media-files card">
                            <a
                              className="card-a"
                              href="javascript:void(0)"
                            // @click="downloadWithAxios(x.attachmentUrl)"
                            >
                              <i className="fas fa-video i-2p5"></i>
                              <h5 className="ml-2 font-md">
                                {x.attachmentName}
                              </h5>
                            </a>
                          </div>
                        )
                      }
                      // debugger;
                      if (isVideo(x.attachmentType)) {
                        // debugger;
                        // console.log("inside isvideo : ",x.attachmentType)
                        return (<div className="media-files">
                          <a href="javascript:void(0)">
                            <video
                              style={{ width: '100%' }}
                              controls
                              controlsList="nodownload"
                              disablePictureInPicture
                              src={x.attachmentUrl}
                              className="med"
                            ></video>
                          </a>
                        </div>
                        )
                      }

                      if (isAudio(x.attachmentType)) {
                        return (
                          <div className="audiodiv media-files">
                            <audio
                              // controls="controls"
                              controlsList="nodownload novolumeslider"
                            >
                              <source src={x.attachmentUrl} />
                              Your browser does not support the audio tag.
                            </audio>
                          </div>
                        )
                      }

                      if (isImage(x.attachmentType)|| isImgLink(x.attachmentUrl)) {
                        return (
                          <div className="media-files">
                            <img
                              data-enlargeable=""
                              src={x.attachmentUrl}
                              className="med"
                              //@click="enlargeable(attachment.attachmentUrl)"
                              onClick={() => handleEnlargeable(x.attachmentUrl)}
                            />
                          </div>)
                      }
                      
                      if (isPdf(x.attachmentType)) {
                        return (
                          <div className="media-files attachcard">
                            <a
                              className="card-a"
                              href="javascript:void(0)"
                              onClick={() => downloadWithAxios(x.attachmentUrl)}
                            >
                              <i className="fas fa-file-pdf"></i>
                              <h5 className="ml-2 font-md">
                                <img
                                  data-enlargeable=""
                                  src={x.attachmentPreviewUrl}
                                  className="med"
                                // //@click="enlargeable(attachment.attachmentUrl)"
                                // onClick={()=>handleEnlargeable(x.attachmentUrl)}
                                />
                              </h5>
                            </a>
                          </div>)
                      }
                    })

                  }
                </div> : null
            }
            {
              body ?
                <h5 className='msg-content'
                  // dangerouslySetInnerHTML={createMarkup()}
                  id={messageData['Recipient Msg Start'] + '-msg-body-' + random}>
                  <Linkify tagName="p"
                    options={
                      {
                        attributes: { target: "_blank" },
                        defaultProtocol: "https",
                        className: "linkified",
                        // download:decode(body),
                        nl2br: true,
                        validate: true,
                        formatHref: function (href, type) {


                          return href;
                        },

                        format: function (value, type) {


                          return value;
                        }
                      }}
                  >
                    {decode(body)}
                  </Linkify>
                </h5> : null
            }
          {/* for only V-Card the attachement comes below the msg-content */}
          {
            attachment.length > 0 && attachment.map((x: any) => {
              if (isVcard(x.attachmentType)) {
                return (
                  <div className="media-files attachcard">
                    <a
                      href="javascript:void(0)"
                      onClick={() => downloadWithAxios(x.attachmentUrl)}
                      className='d-flex'
                    >
                      <i
                       className="fas fa-user-tag"
                      ></i>
                          <h5 className="font-md">{x.attachmentName}</h5>
                        </a>
                    </div>         
                )
              }
            })
          }

            <div className='msg-details'>
              <span>
                {moment(messageData['Recipient Msg Start']).format("h:mm A")}

              </span>
              {
              config.REACT_APP_SMS_ICON === "Y" ?
              <>
              {
                isSms ? <span className='ms-1'>
                  <SmsOutlinedIcon fontSize='small' />
                </span> : null
              }
              </>:null
              }
              {
                iswhatsapp ? <span className='ms-1'>
                  <WhatsAppIcon fontSize='small' />
                </span> : null
              }
              {incoming === false ?
                <span className='ms-1'>
                  {/* {
                  <DoneAllIcon color='success' fontSize='small' />:<ErrorIcon color='error' fontSize='small'/>
                } */}
               
                  {
                    iswhatsapp && (msgStatus === "sent" || msgStatus === 'delivered' || msgStatus === 'In Progress') ? <DoneIcon color='success' fontSize='small' /> : null
                  } {
                    isSms && (msgStatus === "sent" || msgStatus === 'In Progress' ) ? <DoneIcon color='success' fontSize='small' /> : null
                  }
                  {
                    isSms && (msgStatus === 'delivered' || msgStatus === "read") ? <DoneAllIcon color='success' fontSize='small' /> : null
                  }
                  {
                    iswhatsapp && (msgStatus === "read") ? <DoneAllIcon color='success' fontSize='small' /> : null
                  }
                  {
                    msgStatus === "undelivered" || msgStatus === "failed" ? <ErrorIcon color='error' fontSize='small' /> : null
                  }
                </span> : null}
            </div>



          </div>
        </div>
        {messageData['Recipient Sender Typ'] == "EMP" ?
          <div className='avatar-area' >
            <div className='avatar-block'>
              <i className="fas fa-user i-2"></i>
            </div>
          </div>
          : null}
      </div>
      {
        messageData['showDateBanner'] ?
          <div className="dateBanner">
            <h4 className="font-md h4-date">{t(formatDateIntoTodayYesterdayFormat(messageData['Recipient Msg Start']))}</h4>
            <hr />
            <h4 className="font-md vis-hidden">{t('content')}</h4>
          </div> : null
      }

    </>
  )
}




