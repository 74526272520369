import axios from "axios";
import config  from '../../env.json';
function PingAuthApi(payload,callback){
   // let {authToken}=params;
    try {
        axios.post(config.REACT_APP_AUTH_API_BASE+"/auth/token",payload)
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback(null)
        })
    } catch (error) {
        callback(null)
    }
   
}
function PingAuthRefresh(payload,callback){
  // let {authToken}=params;
   try {
       axios.post(config.REACT_APP_AUTH_API_BASE+"/auth/refreshtoken",payload)
       .then(res=>{ 
         callback(res.data);
       }).catch(()=>{
         callback(null)
       })
   } catch (error) {
       callback(null)
   }
  
}




export {PingAuthApi,PingAuthRefresh}