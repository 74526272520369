
// var tokenMixin = Vue.mixin({
//     methods: {
  import config from '../env.json';
  import { formatPhoneNumberIntl,formatPhoneNumber,parsePhoneNumber,getCountryCallingCode } from 'react-phone-number-input'

    export function wformatPhoneNumber(str) {
        //Filter only numbers from the input
        let cleaned = ("" + str).replace(/\D/g, "");
        console.log(cleaned)
        console.log("cleaned")
  
        //Check if the input is of correct
        let match = cleaned.match(/^(1|91|)?(\d{3})(\d{3})(\d{4})$/);
      
        if (match) {
          console.log(match);
          console.log("match")
          //Remove the matched extension code
          //Change this to format for any country code.
          let intlCode = match[1] ? "+1 " : "+";
          // +config.REACT_APP_COUNTRY_CODE;
          return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
        }else{
          return null;
        }
  
        
      };
  
      export function getTenDigitPhoneNumber(phone) {
        if (phone.length == 11) return phone.substring(1, 11);
        else if (phone.length == 12) return phone.substring(2, 12);
        else if (phone.length == 10) return phone;
        else return phone
      };
//     },
//   });
  
export function getFormatedPhoneNumber(number){
  if(!number)
  return '';
  if(number.indexOf("+")>=0){
    let c:any=parsePhoneNumber(number)
    if(c)
    return "+"+c.countryCallingCode+" " + formatPhoneNumber(number);
    else 
    return formatPhoneNumberIntl(number);
  }
  else
    return number
}
