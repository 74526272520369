import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../layouts/sidebar/Sidebar";
import Header from "../../layouts/header/Header";
import "./Home.css";
import MobileHeader from "../../layouts/mobileHeader/MobileHeader";
import VideoFloater from "../../components/video/videoFloater/VideoFloater";
import Snackbar from "@mui/material/Snackbar";
import {
  setVideoCallShowFloater,
  setVideoCallRoom,
  setVideoCallCallStarted,
  setVideoCallEnded,
} from "../../app/slice/videoCallSlice";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,setCustomErrorSnackBar
} from "../../app/slice/personalisationSlice";

import { useDispatch, useSelector } from "react-redux";
import poweredByP360 from "../../assets/images/powered_by_p360.png";
import TestMobileChat from "../../components/chat/MobileChat";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import config from "../../env.json";
function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [footerClass, setFooterClass] = useState("");
  const dispatch = useDispatch();
  const room = useSelector(
    (state: any) => state.floaterVideoCall.videoCallRoom
  );
  const { showChat } = useSelector((state: any) => state.chat);
  const { showSuccessSnackBar,showErrorSnackBar, customError, showCustomErrorSnackBar } = useSelector((state: any) => state.personalisation);
  
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const endCall = () => {
    room.localParticipant.tracks.forEach((publication) => {
      if (publication.kind == "video" || publication.kind == "audio") {
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element) => element.remove());
        publication.track.stop();
      }
    });
    room.disconnect();
    dispatch(setVideoCallRoom(null));
    dispatch(setVideoCallEnded(true));
    dispatch(setVideoCallCallStarted(false));
    dispatch(setVideoCallShowFloater(false));
  };

  useEffect(() => {
    console.log("kikikif", showSuccessSnackBar, showChat);
  }, [showSuccessSnackBar]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setShowSuccessSnackBar(false));
    dispatch(setShowErrorSnackBar(false));
    dispatch(setCustomErrorSnackBar(false));
  };

  useEffect(() => {
    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setFooterClass("home-footer");
      setIsMobile(true);
    } else {
      setFooterClass("");
      setIsMobile(false);
    }
    window.addEventListener(
      "resize",
      function (event) {
        let device = getDeviceType();
        if (
          device == "mobile" ||
          device == "tablet" ||
          config.REACT_APP_IS_CROSS_PLATFORM
        ) {
          setFooterClass("home-footer");
          setIsMobile(true);
        } else {
          setFooterClass("");
          setIsMobile(false);
        }
      },
      true
    );
    window.addEventListener("beforeunload", (e) => {
      if (room) {
        endCall();
      }
    });
  }, []);

  return (
    <div id="main">
      {showChat && isMobile ? (
        <TestMobileChat
          messageData={[]}
          showActiveChatPage={showChat}
          setShowActiveChatPage={() => {}}
        />
      ) : (
        <div className="home-container" id="home">
          <MobileHeader />
          <Sidebar />
          <div className="main-content-div">
            <Header />
            <Outlet />
          </div>
          <div className={`mobileSideBar_footer  ${footerClass}`} id="footer">
            <footer className="footerMob" id="footerVal">
              <img src={poweredByP360} alt="" />
            </footer>
          </div>
        </div>
      )}
      <Snackbar
        open={showSuccessSnackBar}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: "50vw !important" }}>
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackBar}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert severity="error" sx={{ width: "50vw !important" }}>
          An error has occurred!
        </Alert>
      </Snackbar>
      <Snackbar 
        open={showCustomErrorSnackBar} 
        autoHideDuration={5000} 
        onClose={handleClose}
      >
        <Alert 
          severity="error"
          // sx={{ width: '50vw !important' }}
        >
          {customError}          
        </Alert>
        
      </Snackbar>
      <VideoFloater />
    </div>
  );
}

export default Home;
