// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import Popper from 'popper.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter} from 'react-router-dom';
//import { store } from './app/store';
import {store} from  './app/newStore';
import App from './App';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import reportWebVitals from './reportWebVitals';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import './index.css';
import {loginRequest} from './authConfig'; 
import axios from 'axios';
import { setLoginDetails } from './app/loginReducer';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'



// window.localStorage.setItem('errorpage', '');

// MSAL imports

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        debugger;
        msalInstance.setActiveAccount(account);
        debugger;
    }
    
});



const container = document.getElementById('root')||document.createElement('div');
const root = ReactDOM.createRoot(container);

let persistor = persistStore(store)

root.render(

    
      <BrowserRouter>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App pca={msalInstance} />
        </PersistGate>
        </Provider>
      </BrowserRouter>
    
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
