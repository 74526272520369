
import React,{useState,useEffect, useRef} from 'react'
import { Link } from 'react-router-dom';
import ZingLogoHome from "../../assets/images/ZingLogoHomePage.png"
import "./Sidebar.css"
import axios from 'axios'
import  config  from '../../env.json';
import { useDispatch,useSelector } from 'react-redux';
import {setActiveTab, setChatPermId, setTitle} from '../../app/slice/sideBarSlice';
import { setProfileSidebarView, } from '../../app/slice/loginSlice';
import { useTranslation, initReactI18next } from "react-i18next";
import {setUserMsgChannelType} from '../../app/slice/authApiSlice';
import {setUserCallType} from '../../app/slice/personalisationSlice';
import { useNavigate} from 'react-router-dom';
function Sidebar() {
  const messageChannelType=useSelector((state:any)=>state.authApi.EmployeeMsgorWhatsAppChannelType)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector((state: any) =>state.sideBar.active)
  const [menuItems,setMenuItems] = useState<any[]>([]);
  const [activeClass,setActiveClass] = useState('');
  const token = useSelector((state: any) => state.login.token);

const getMenuItems = async()=>{
  console.log("Sidebar"+token)

  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.headers.common["Authorization"] = "Bearer "+token;

  const menuItems =  axios.get(`${config.REACT_APP_AUTH_API_BASE}/auth/getuserpermissions`)
    .then(response => {
      debugger
      if(response){
        debugger
      if(response.data.message === 'No Menu Options to display'){
        console.log('No Data')
      navigate('/error')
      debugger
      }}
      console.log(response.data)
      response.data.sort((a, b) => parseFloat(a.Display_Or) - parseFloat(b.Display_Or));
      // whatsapp start
      let x = response.data.filter(d => d.Display_Nm === "WHATSAPP")
        if(x.length>0){
          console.log(x);
          dispatch(setUserMsgChannelType(true))
       } else{
        dispatch(setUserMsgChannelType(false))
       }
       // whatsapp End


      //  Bridge Call start
      let bridgecall= response.data.filter(d => d.Display_Nm === "BridgeCall");
      console.log(bridgecall.length)
      {bridgecall.length>0?dispatch(setUserCallType(true)):dispatch(setUserCallType(false))}
      let y=  response.data.filter(d => d.Display_Or >= 0);
     
   
      setMenuItems(y);
      debugger
      if(!activeTab){
        handleMenuItemClick(y[0]);
      }else
      {
        setActiveClass(activeTab);
      }
      
    //  setDefaultMenuItemSelected(response.data[0]);
    })
    .catch(error => {
        console.error('There was an error!', error);
    });

    
}

const setDefaultMenuItemSelected =(menuItem)=>{
      setActiveClass(menuItem.PermissionsId);
}
useEffect(() => {
  console.log(activeTab)
}, [activeTab])

useEffect(() => {
  console.log(activeTab)
//  dispatch(setActiveTab('Chat'))
  getMenuItems();
  // console.log("gc logo URL",config.REACT_APP_logo)
}, [])
  
  const handleMenuItemClick =(item) =>{
    setActiveClass(item.PermissionsId);
    debugger
    dispatch(setTitle(item.Display_Nm == 'Templates'?"Pre-Approved Messages":item.Display_Nm))
    dispatch(setActiveTab(item.PermissionsId));
    dispatch(setProfileSidebarView(false))
  }
  const { t } = useTranslation();
  return (
    <div className="sidebar-container notMobile">

        <div className="zing-logo-div">
          <div className='logo-img'>

          <img src={config.REACT_APP_LOGO} alt="Zing Logo" />

          </div>
        </div>

        <div className="menu-btn-div">
            <ul>
              
              {
                  menuItems?.map(items => {
                    if(items.Display_Nm == "Chat"){
                      dispatch(setChatPermId(items.PermissionsId));
                    }
                    return (<>
                   
                        <Link key={ items.PermissionsId }
                            className={activeClass === items.PermissionsId ? 'active':'' }
                            onClick={(e)=>{ handleMenuItemClick(items)                            
                             }} 
                            to={"/"+ items.Display_Nm.replace(/ /g,"")}>

                              <i className={items.ClassNameForUI}></i>
                              <h3>
                                {/* {t(items.Display_Nm)} */}
                                {/* renamed templated to pre-approved-temaplte. */}
                                {t(items.Display_Nm) == 'Templates' ?  'Pre-Approved Messages' :  t(items.Display_Nm) }  
                              </h3>
                              
                      </Link> 
                            
                     </>
                    )
                  })
              }
                
            </ul>
        </div>

  </div>
  )
}

export default Sidebar