import React,{useState,useEffect} from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import { setMobileProfileSidebarView } from '../../app/slice/loginSlice';
import Tooltip from '@mui/material/Tooltip';
import {getPersonalizationData,updatePersonalizationData} from '../../apis/personalizationApi/personalization';
import Avatar from '@mui/material/Avatar';
import {getlangTransData} from '../../apis/langtranslation/langTranslation';
import i18n from "i18next";
import poweredByP360 from "../../assets/images/powered_by_p360.png";
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as LangIcon} from '../../assets/icons/langIcon.svg';
import {ReactComponent as LangLocIcon} from '../../assets/icons/langLocIcon.svg';
import {ReactComponent as TimeZoneIcon} from '../../assets/icons/timeZoneIcon.svg';
import config from '../../env.json';
import './MobileProfileSidebar.css';
import datalist from '../../assets/personalization/datalist.json';
import { getFormatedPhoneNumber } from '../../utils/phoneNumberUtil';
import {setOptInKeywords, setOptOutKeywords, setUserPersonalisationDeatils} from '../../app/slice/personalisationSlice';
import MessageIcon from '@mui/icons-material/Message';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
// import EmailIcon from '@mui/icons-material/Email';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {getDeviceType} from '../../utils/deviceTypeUtil';

const Background:any = config.REACT_APP_PROFILE_BG_IMG;

function stringProfileSidebarAvatar(name: string) {
  // console.log("avatar name",name.split(' '));
  if(!name){
    name='D P';
  }
  console.log("avatar name",name.split(' '));
  return {
    sx: {
      bgcolor: "#FAF8FF",
      color:"var(--primary-color)",
      fontSize:"2rem",
      width:"7rem",
      height:"7rem"
    },
    // children: `${name.split(' ')[0]}${name.split(' ')[1]}`,
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default function MobileProfileSidebar(props) {

  const { t } = useTranslation();
  const dispatch=useDispatch();
  const [editProfile,setEditProfile]=useState(false);
  const mobileProfileSidebar=useSelector((state: any) => state.login.mobileProfileSidebar);
  const userPhno=useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const userEmail=useSelector((state: any) => state.authApi.EmployeeEmail_Adr);
  const userQR=useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const empPersonalisationCallType=useSelector((state: any) => state.personalisation);
  const empShowCallType=useSelector((state: any) => state.personalisation.showCallType);

  const [lang,setLang] = useState<any>();
  const [tmzone,settmzone] = useState<any>();
  const [langLocal,setLangLocal] = useState('');
  const [langLoc,setLangLoc] = useState('');
  const [empId,setEmpId] = useState<any>();
  const [langList,setLangList]=useState<any>();
  const [tmzoneList,setTmzoneList]=useState<any>();
  const [langLocalList,setLangLocalList]=useState<any>();
  const [callTypeList,setCallTypeList]=useState<any>();
  const [empCallType,setEmpCallType] = useState<any>();
  const [msgnotificationSelect,setMsgNotificationSelect]=useState<any>(true);
  const [emailnotificationSelect,setEmailNotificationSelect]=useState<any>(true);
  const [pushnotificationSelect,setPushNotificationSelect]=useState<any>(true);

  const [transData,setTransData]:any=useState([]);
  const [profileSidebarHeight,setProfileSidebarHeight]:any=useState(0);
  const [profileSidebarWidth,setProfileSidebarWidth]:any=useState(0);
  const [profileSidebarTop,setProfileSidebarTop]:any=useState(0);
  const [profileSidebarLeft,setProfileSidebarLeft]:any=useState(0);
  const [mobileStyle,setMobileStyle]:any=useState(false);
  const [settingData,setSettingData]=useState({});


  const getHeightWidth = () => {
    let doc:any=document
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
   

    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setProfileSidebarTop(divpaddingTop);
      setProfileSidebarLeft(divpaddingleft);
      if(document.getElementsByClassName('mobile-profile-sidebar-container')[0]){
      setProfileSidebarHeight(window.innerHeight-(divpaddingTop+divpaddingbottom));
      // document.querySelector('.MuiModal-root, .MuiBox-root').
      setProfileSidebarWidth(window.innerWidth-(divpaddingleft+divpaddingright));
      // document.querySelector('.MuiModal-root, .MuiBox-root').style.width=(window.innerWidth-(divpaddingleft+divpaddingright))+'px'
      // document.querySelector('.MuiModal-root, .MuiBox-root').style.height=(window.innerHeight-(divpaddingTop+divpaddingbottom))+'px'

      // doc.getElementsById('modalOuter').offsetHeight=window.innerHeight-divpaddingTop-divpaddingbottom;
      // doc.getElementsById('modalOuter').offsetWidth=window.innerWidth-divpaddingleft-divpaddingright;
      }
    }
  }

  useEffect(()=>{
    getHeightWidth();
  }, [mobileStyle, profileSidebarTop, profileSidebarLeft, profileSidebarHeight, profileSidebarWidth])

  useEffect(()=>{
    console.log("gc var phone",userPhno);
    console.log("gc var email",userEmail);
    console.log("gc var QR",userQR);
    console.log("gc var name",userName);

    setLangLoc(window.navigator.language.toLowerCase());    
    setupLanguage();
  
  },[langLocal]);
  useEffect(()=>{
    console.log(userName)
  },[userName])
  useEffect(()=>{
    setLangList(datalist["language"]);
    setTmzoneList(datalist["timezone"]);
    setLangLocalList(datalist["langLocal"]);
    setCallTypeList(datalist["callType"])
    getPersonalizationData((res) => {
      debugger
        if (res) {
      debugger
          setSettingData(res);

          setEmpId(res['EmployeePersonalisation_Id']);

          setLang(res['EmployeePersonalizationPref_Lng']);

          setLangLocal(res['EmployeePersonalizationLocale_Nm']);

          settmzone(res['EmployeePersonalizationWork_TmZn']);

          setEmpCallType(res['EmployeePersonalizationCall_Typ']);



          getNotificationData(res['EmployeePersonalizationNotifcation_Typ'] ? res['EmployeePersonalizationNotifcation_Typ'] : '')

          // debugger;

          console.log("gc pers", res)

          dispatch(setUserPersonalisationDeatils({

            callType: res['EmployeePersonalizationCall_Typ'],

            language: res['EmployeePersonalizationPref_Lng'],

            locale: res['EmployeePersonalizationLocale_Nm'],

            personalisationId: res['EmployeePersonalisation_Id'],

            timeZone: res['EmployeePersonalizationWork_TmZn'],

            notificationType: res['EmployeePersonalizationNotifcation_Typ'] ? res['EmployeePersonalizationNotifcation_Typ'] : ''

        
          }))
          let OOKeywords = res.KeywordsOptOut.map(({ Keyword_Nm }) => Keyword_Nm);
          dispatch(setOptOutKeywords(OOKeywords));
          let OIKeywords = res.KeywordsOptIn.map(({ Keyword_Nm }) => Keyword_Nm);
          dispatch(setOptInKeywords(OIKeywords));
        }  
      })
   
  },[])
  const handleSave=() =>{
    let emptyString = "" ;
   if(pushnotificationSelect  && msgnotificationSelect ){
    emptyString = "push,sms";
   } else if(pushnotificationSelect) {
    emptyString = "push";
   } else if(msgnotificationSelect){
    emptyString = "sms"
   }
    setEditProfile(false);
    let persPayload={
      language:lang,
      locale:langLocal,
      timeZone:tmzone,
      personalisationId:empId,
      callType:empPersonalisationCallType.callType,
      notificationType:emptyString

    }
    dispatch(setUserPersonalisationDeatils({
      callType:empCallType,
      language: lang,
      locale:langLocal,
      personalisationId: empId,
      timeZone:tmzone
  }))
    updatePersonalizationData(persPayload,(res:any)=>{
      if(res){
        console.log("lang update",res);
        // setFormID(res)
        setupLanguage();
      }
      else{
        console.log("lang update",res);
      }
    })
  }
  const handleSetLang=(e)=>{
    const lang=e.target.value;
    setLang(e.target.value);
  }
  const setupLanguage = () => {
    setLangLoc(window.navigator.language.toLowerCase());
    if(!tmzone){
      let dt=new Date();
      let dtstr=dt.toString()
      console.log("gc date",dtstr.substring(25,33));
      settmzone(dtstr.substring(25,33));
    }
    if(!langLocal) {setLangLocal(window.navigator.language.toLowerCase());}
    console.log("langlocal",langLoc);
    // if(transData<=0){
    // getlangTransData(langLocal?langLocal:window.navigator.language.toLowerCase(),(res)=>{     
    //   if (res && res.data) {
    //     // setTransData("lang res",res.data['translatedValues']);
    //     console.log("lang trans val",res.data['translatedValues'][0]);
    //     //formatting the data of lang translation
    //     let langjson:any={};
    //     let transjson:any={};
    //     transjson['translation']=res.data['translatedValues'][0];
    //     langjson[langLocal]=transjson;
    //     console.log("lang",langjson);
        
    //     setTransData(langjson);
    //     console.log("lang before trans function",transData,"lang loc",langLocal);
    



    //     i18n
    //     .use(initReactI18next) // passes i18n down to react-i18next
    //     .init({
    //       // the translations
    //       // (tip move them in a JSON file and import them,
    //       // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    //       // resources: transData[0],
    //       resources: langjson,

    //       lng: langLocal, // if you're using a language detector, do not define the lng option
          

    //       interpolation: {
    //         escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    //       }
    //     });
    //     }
       
            
    //   })
  // }
  };

  const emailHandleSelect=()=>{    
    setEmailNotificationSelect(!emailnotificationSelect)   
}
const pushHandleSelect =()=>{
  if (editProfile)
  setPushNotificationSelect(!pushnotificationSelect)
}
const msghandleSelect=()=>{
  if (editProfile)
  setMsgNotificationSelect(!msgnotificationSelect)    
}
const getNotificationData=(e)=>{
  console.log(e)
  if(e.indexOf("push") >= 0){
    setPushNotificationSelect(true)
  } else {
    setPushNotificationSelect(false)
  }
   
  if(e.indexOf("sms") >=0){
    setMsgNotificationSelect(true)
  } else{
    setMsgNotificationSelect(false)
  }
}

  const handleSetcalltype=(e)=>{
    console.log(e.target.value)
    const lang=e.target.value;
    setEmpCallType(e.target.value);
    dispatch(setUserPersonalisationDeatils({
      callType:e.target.value}))
  }
  const handleSetLangLocal=(e)=>{
    const langLocal=e.target.value;
    setLangLocal(e.target.value);
  }
  const handleSetTmZone=(e)=>{
    const TmZone=e.target.value;
    settmzone(e.target.value);
  }
  return (
    <div>
        
            <div className='mobile-profile-sidebar-container' style={mobileStyle?{height:profileSidebarHeight, width:profileSidebarWidth, top:profileSidebarTop, left:profileSidebarLeft}:{}}
             >
                <div className='profile-header-left' style={{ backgroundImage: `url(${Background})` }}>
                {/* <h1>{t('Profile')}</h1>                    */}
                  <span  onClick={()=>{dispatch(setMobileProfileSidebarView(false))}}>×</span>
                  <div className='profile-avatar-right mobile-sidebar-avatar'>
                  <Avatar {...stringProfileSidebarAvatar(userName)}/>
                  {config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" || config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y"? 
                    <div className='editsave'>
                    {
                      editProfile?
                      <Tooltip title={t('Save')} arrow>
                      <div className='editicon' onClick={()=>{handleSave()}}><i className="fa fa-solid fa-check"></i></div>
                      </Tooltip>
                      :
                      <Tooltip title={t('Edit')} arrow>
                        <div className='editicon' onClick={()=>{setEditProfile(true)}}><Edit/></div>
                      </Tooltip>
                    }
                    </div>:null
                  }
                  </div>
              </div>
                
                {/* <div className='settings'>
                  language : EN<br/>
                  TimeZone : +05:30<br/>
                  Local : US
                </div> */}
                
                <div>
                <div className='profile-detials-left'>
                  <label className='user-bold-font'>{userName}</label>
                  <label>{getFormatedPhoneNumber(userPhno)}</label>
                  <label>{userEmail}</label>
                </div>
                {config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" ? 
                <>
                {!editProfile?
                <div className='settingsDisplay'>
                  <div className='singlerow'><span className='icon'><LangIcon/></span> <span className='dataSpan'>{lang}</span></div>
                  <div className='singlerow'><span className='icon'><LangLocIcon/></span> <span className='dataSpan'>{langLocal}</span></div>
                  <div className='singlerow'><span className='icon'><TimeZoneIcon/></span> <span className='dataSpan'>{tmzone}</span></div>
                  {empShowCallType === true && config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL==="Y"?
                  <div className='singlerow'><span className='icon calltype'><i className="fas fa-phone-alt i-2p5 callIcon"></i></span> <span>{t(empPersonalisationCallType.callType)}</span></div>: null}
                </div>
                :
                <div className='settings'>
                  <div className='settrow'>
                    <div className='dropdownsicon'><LangIcon/></div>
                    <div>
                    <select value={lang} className='dropdowns' onChange={handleSetLang}>
                      {langList.map(item=><option key={item}>{item}</option>)}
                    </select>
                  </div>
                  </div>
                  <div className='settrow'>
                    <div className='dropdownsicon'><LangLocIcon/></div>
                    <div>
                    <select value={langLocal} className='dropdowns' onChange={handleSetLangLocal}>
                      {langLocalList.map(item=><option key={item}>{item}</option>)}
                    </select>
                    </div>
                  </div>
                  
                  <div className='settrow'>
                    <div className='dropdownsicon'><TimeZoneIcon/></div>
                    <div>
                    <select value={tmzone} className='dropdowns' onChange={handleSetTmZone}>
                        {tmzoneList.map(item=><option key={item}>{item}</option>)}
                    </select>
                    </div>
                  </div>
                  {empShowCallType === true && config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL==="Y"?
                  <div className='settrow'>
                    <div className='dropdownsicon dropdownCalltype'><i className="fas fa-phone-alt i-2p5 callIcon"></i></div>
                    <div>
                    <select value={empPersonalisationCallType.callType} className='dropdowns' onChange={handleSetcalltype}>
                      {callTypeList.map(item=><option key={item}>{t(item)}</option>)}
                    </select>
                  </div>
                  </div>: null}
                </div>
                }
                </>
                :null
                }
                 {config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y"?
                  <>
                  {/* {!editProfile? */}
                  <div className='singlerow align-notification-iocn'>
                  <span className='icon'><CircleNotificationsIcon/></span>
                  <span className={pushnotificationSelect === true?'select_icon':'unselect_icon'} onClick={pushHandleSelect}><AdUnitsIcon className='unselect_icon'/></span>
                  <span className={msgnotificationSelect === true?'select_icon':'unselect_icon'} onClick={msghandleSelect}><MessageIcon/></span>
                  {/* <span className={emailnotificationSelect === true?'select_icon':'unselect_icon'}><EmailIcon/></span> */}
                  {/* <span className=''><i className="fas fa-phone-alt i-2p5 callIcon"></i></span> */}
                  </div>
                  {/* : */}
                  {/* <div className='settings'>
                   <div className='settrow'>
                   <div className='dropdownsicon'><CircleNotificationsIcon/></div>
                   <div className='notification_icon_box'>
                     <span className={pushnotificationSelect === true?'select_icon':'unselect_icon'} onClick={pushHandleSelect}><AdUnitsIcon /></span>
                     <span className={msgnotificationSelect === true?'select_icon':'unselect_icon'} onClick={msghandleSelect}><MessageIcon/></span>
                   </div>
                  
                   </div>
                   </div> */}
                   {/* } */}
                  </>:null}
                {/* <div className='profile-detials-email'>
                  <label>{userEmail}</label>
                </div> */}
                 {config.REACT_APP_PROFILE_QR === "Y" ? 
                <>
                <div className='profile-qrcode-right'>
                 <img src={userQR} className='qr-code-img'/>
                </div>
                <div className='profile-btns-right'>
                  <button><i className="fa fa-share icons-sizing" aria-hidden="true"></i></button>
                  <button onClick={()=>{window.open(userQR)}} ><i className="fa fa-download icons-sizing" aria-hidden="true"></i></button>
                </div>
                </>:null
                }
                <div className='profile-footer-right mobile-profile-footer d-none'>
                  <img src={poweredByP360} className='poweredBy-img'></img>
                </div>
                </div>
            </div>
            
    </div>
  )
}
