  import React,{useState,useEffect, useRef} from 'react'
  import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
  import { loginRequest } from "../../authConfig";
  import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";

  import Spinner from '../../layouts/spinner/Spinner';
  import LoginBgImage from '../../assets/images/LoginBgImage.png'
  import LogoImage from '../../assets/images/Logo-Image.png'
  import KKITextLogo from '../../assets/images/KKITextLogo.png'
  import Chat from "../chat/Chat"
  import { msalInstance } from '../../index';
  import "./Login.css"
  import Home from '../home/Home';
  import { useAppSelector, useAppDispatch } from '../../app/hooks';

  import { useDispatch, useSelector } from 'react-redux';
  import { setLoginDetails } from '../../app/slice/loginSlice';
  import { getAuthApi } from '../../apis/authApi/authApi';
  import { PingAuthApi, PingAuthRefresh } from '../../apis/pingauthApi/pingauthApi';
  import { updatePushToken } from '../../apis/notificationApi/notificationApi';
  import { createDatabase, addUserDetails, getUserDetails, deleteUserDetails } from '../../apis/dbApi/dbApi';
  import { setUserVirtualNumber,setUserDetails } from '../../app/slice/authApiSlice';
  import axios from 'axios';
  import {capacitormsal} from "capacitormsal";
  import  config  from '../../env.json';
  import { PushNotifications } from '@capacitor/push-notifications';
  import {getlangTransData} from '../../apis/langtranslation/langTranslation';
  import { useTranslation, initReactI18next } from "react-i18next";
  import i18n from "i18next";
  import { sqlite, existingConn } from '../../App';
  import { setChatShowChat, setRefreshChatList } from '../../app/slice/chatSlice';
  import { setCallModalView, setCallModalDetails } from '../../app/slice/callModalSlice';
  import { useSearchParams } from "react-router-dom";
  import { stringify } from 'querystring';
  import { json } from 'stream/consumers';
  import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
  import LoginOtpBgImage from '../../assets/images/LoginOtpBgImage.png';
  import logoBrand from "../../assets/icons/logo_brand.png";
  import logoIcon from "../../assets/icons/logo_icon.png"
  import CircularProgress from '@mui/material/CircularProgress';
  import { App } from '@capacitor/app';
  import { Keyboard } from '@capacitor/keyboard';
  import './LoginOtp.css'
  import {getDeviceType} from '../../utils/deviceTypeUtil';

  function Login() {
    let transLocData={};
    const [transData,setTransData]:any=useState([]);
    // const { t } = useTranslation();
    const [AuthType, setAuthType] = useState("");
    // const t=(test)=>{return test};
    const [IsLoggingSilently, setIsLoggingSilently] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [IsLoggedIn, setIsLoggedIn] = useState(true);
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [isEmail,setIsEmail] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [email,setEmail] = useState("");
    const [isInvalidOtp,setIsInvalidOtp] = useState(false);
    const [isOtpEmpty,setisOtpEmpty] = useState(false);
    const [isOtpBtnDisabled,setIsOtpBtnDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [otpFromMessage, setOtpFromMessage] = useState(""); 
    const [mobileStyle, setMobileStyle] = useState(false);
    const [paddingTopStyle, setPaddingTopStyle] = useState(0);
    const [paddingRightStyle, setPaddingRightStyle] = useState(0);
    const [paddingBottomStyle, setPaddingBottomStyle] = useState(0);
    const [paddingLeftStyle, setPaddingLeftStyle] = useState(0);
    const otpInputRef = useRef<HTMLInputElement>(null);
    const otpContainerRef = useRef<HTMLDivElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const regex = /^[0-9\b]+$/;

    const handleOtpDivClick = (index: number) => {
      if(otpInputRef.current) {
        otpInputRef.current?.focus();
        // otpInputRef.current?.setSelectionRange(index, index+1)
      }
    }

    const handleHiddenOtpChange = (e) => {
      if(e.target.value.length <= 4) {
        if(isNaN(Number(e.target.value))) 
          return false;
        if(e.target.value === '' || regex.test(e.target.value)) {
          setOtpFromMessage(e.target.value)
        }
      }
    }

    const moveCursorToNext = (e) => {
      // otpInputRef.current?.setSelectionRange(e.target.selectionStart, e.target.selectionStart + 1)
      for(let i = 0; i < 4; i++) {
        if(i != e.target.selectionStart) {
          document.getElementById(`value${i}`)?.classList.remove('cursor');
        }
      }  
      document.getElementById(`value${e.target.selectionStart}`)?.classList.add('cursor'); 
    }

    useEffect(() => {
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        Keyboard.addListener('keyboardDidShow', () => {
          otpContainerRef.current?.scrollIntoView();
          emailInputRef.current?.scrollIntoView()
        });
      }
      calcHeightWidth();
    },[])
    const calcHeightWidth=()=>{
      let doc:any=document
      let div:any=document.querySelector('#main')
      let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
      let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
      let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
      let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));

        let device = getDeviceType()
        console.log("DEVICE:",device)
        if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
        setMobileStyle(true);
        setPaddingTopStyle(divpaddingTop);
        setPaddingRightStyle(divpaddingright);
        setPaddingBottomStyle(divpaddingbottom);
        setPaddingLeftStyle(divpaddingleft);
      }
    
    }

    useEffect(() => {
            setOtp([...otp.map((item,idx)=>{
              return  !otpFromMessage[idx] ? "" : String(otpFromMessage[idx])
            })])
    },[otpFromMessage])

    useEffect(()=>{
      App.addListener('appStateChange', async ({ isActive }) => {
        if(isActive) {
          dispatch(setRefreshChatList(true))
        }
      })
    },[]) 

    useEffect(() => {
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        
        PushNotifications.addListener('registration', token => {
          updatePushToken({
            "DeviceToken_Val": token.value,
            "Device_Typ": "iOS",
            "is_active": 'Y'
          },(res)=>{     
            })
        });
    
      
        PushNotifications.addListener('registrationError', err => {
          // alert("Token Error");
          console.error('Registration error: ', err.error);
        });
      
        PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('Push notification received: ', notification);
        });
      
        PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
        
        if(!sessionStorage.getItem("login") || sessionStorage.getItem("login") === "") {
          setIsLoggedIn(false);
          getUserDetails().then((users: Array<any>) => {
            setIsLoggingSilently(true);
            if(users.length>0) { 
                if(config.REACT_APP_IS_CROSS_PLATFORM) {
                  if(config.REACT_APP_AUTH_TYPE=="AD") {
                    capacitormsal.signinSilent({
                      authority: config.REACT_APP_AD_AUTHORITY as string,
                      clientId: config.REACT_APP_AD_CLIENTID as string,
                      redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
                      scope: config.REACT_APP_CROSS_SCOPE as string,
                      })
                      .then(async (response) => {
                          if(response["accesstoken"] && response["accesstoken"] !== "") {
                              console.log("---Silent login success----");
                              console.log(response["accesstoken"]);
                              dispatch(setChatShowChat(false));
                              dispatch(setCallModalView(false));
                              axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
                              sessionStorage.setItem("login",response["accesstoken"]);
                              sessionStorage.setItem('loginName',response["name"]);
                              dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
                              registerNotifications();
                              getAuthApi({authToken:"Bearer "+response["accesstoken"],type:config.REACT_APP_AUTH_TYPE},async (res)=>{
                                  if(res) {
                                      dispatch(setUserVirtualNumber({
                                          EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                                          Id: res[0].Id
                                      }))
          
                                      dispatch(setUserDetails({
                                          EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                                          EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                                          EmployeeFull_Nm:res[0].EmployeeFull_Nm
                                      }))
                                      
                                      await createDatabase();
                                      await addUserDetails(response["accesstoken"]);
                                      console.log("---Silent Token Done----");
                                      setIsLoggingSilently(false);
                                      setIsLoggedIn(true);
                                  }
                              });
              
                          // let langLocal = "es-ES"// window.navigator.language.toLowerCase();
                          // getlangTransData(window.navigator.language.toLowerCase(),(res)=>{     
                          //   if (res && res.data) {
                          //     console.log("lang trans val",res.data['translatedValues'][0]);
                          //     let langjson:any={};
                          //     let transjson:any={};
                          //     transjson['translation']=res.data['translatedValues'][0];
                          //     // console.log("lang trans",transjson);
                          //     langjson[langLocal]=transjson;
                          //     console.log("lang",langjson);
                          //     // console.log(langjson);
                          //     setTransData(langjson);
                          //     transLocData=langjson;
                          //     // 
                          //     console.log("lang before trans function",transLocData,"lang loc",langLocal);
                          //     // console.log("lang before trans lang function",translang,"lang loc",langLocal);
                          //     // ;
                      
                      
                          //     // if(langLocal === "en-us") {
                          //       i18n
                          //       .use(initReactI18next) // passes i18n down to react-i18next
                          //       .init({
                          //         // the translations
                          //         // (tip move them in a JSON file and import them,
                          //         // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
                          //         // resources: transData[0],
                          //         resources: transLocData,
                      
                          //         lng: langLocal, // if you're using a language detector, do not define the lng option
                                  
                      
                          //         interpolation: {
                          //           escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
                          //         }
                          //       });
                                  
                          //     // }
              
                          //     // window.location.href = "/Contacts";//window.location.origin;
                          //     sessionStorage.setItem("isloggedin","1");
                          //     }
                              
                                  
                          //   })
        
                          } else {
                              console.log("---Silent login fail----");
                              setIsLoggingSilently(false);
                              setIsLoggedIn(false);
                          }
                      })
                      .catch(error => {
                          console.log("Error");
                          console.log(error);
                          console.log("---Silent login fail----");
                          setIsLoggingSilently(false);
                          setIsLoggedIn(false);
                      })

                  } else if(config.REACT_APP_AUTH_TYPE=="zing") {
                    let previousAccessToken = users[0].authToken;
                    // alert(previousAccessToken);
                    dispatch(setChatShowChat(false));
                    dispatch(setCallModalView(false));
                    axios.defaults.headers.common["Authorization"] = "Bearer " + previousAccessToken;
                    sessionStorage.setItem("login", previousAccessToken);
                    sessionStorage.setItem('loginName', "");
                    dispatch(setLoginDetails({'token': previousAccessToken,'userName':"",'userAccount':null}));
                    registerNotifications();
                    getAuthApi(
                        {
                            authToken:"Bearer "+previousAccessToken,type:config.REACT_APP_AUTH_TYPE
                        }, async (res) => {
                            if(res){
                              setIsLoading(false)
                                console.log(res)
                                dispatch(setUserVirtualNumber({
                                    EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                                    Id: res[0].Id
                                }))
                
                                dispatch(setUserDetails({
                                    EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                                    EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                                    EmployeeFull_Nm:res[0].EmployeeFull_Nm
                                }))
                                setIsEmail(false);
                                setIsInvalidOtp(false);
                                registerNotifications()
                                setIsLoggedIn(true);
                                setIsLoggingSilently(false);
                                dispatch(setLoginDetails({'token': previousAccessToken,'userName':"",'userAccount':null}));
                            }
                        }
                    )
                  } else {
                    let previousAccessToken = users[0].authToken;
                    // alert(previousAccessToken);
                    dispatch(setChatShowChat(false));
                    dispatch(setCallModalView(false));
                    axios.defaults.headers.common["Authorization"] = "Bearer " + previousAccessToken;
                    sessionStorage.setItem("login", previousAccessToken);
                    sessionStorage.setItem('loginName', "");
                    dispatch(setLoginDetails({'token': previousAccessToken,'userName':"",'userAccount':null}));
                    registerNotifications();
                    getAuthApi({authToken:"",type:config.REACT_APP_AUTH_TYPE},async (res)=>{
                        alert(JSON.stringify(res));
                        if(res) {
                            dispatch(setUserVirtualNumber({
                                EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                                Id: res[0].Id
                            }))

                            dispatch(setUserDetails({
                                EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                                EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                                EmployeeFull_Nm:res[0].EmployeeFull_Nm
                            }))
                            
                            await createDatabase();
                            await addUserDetails(previousAccessToken);
                            console.log("---Silent Token Done----");
                            setIsLoggingSilently(false);
                            setIsLoggedIn(true);
                        } else {
                          setIsLoggingSilently(false);
                        }
                    });

                  }
                }
            } else {
                //Go to login
                setIsLoggingSilently(false);
                setIsLoggedIn(false);
            }
          });
        } else {
          setIsLoggedIn(true);
        }
      }
      else
      {
        if(!sessionStorage.getItem("login")){
          setIsLoggedIn(false);
        }
      }
    }, [])
    
    //const dispatch = useAppDispatch();
    const dispatch = useDispatch();
    const loginDetails=useSelector((state:any)=>state.login.userName)
  //   axios.interceptors.request.use(function (config) {
  //     const token = store.getState().session.token;
  //     config.headers.Authorization =  token;

  //     return config;
  // });

    
  let mobile = "Desktop"
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      mobile = "Mobile"
    }

    if(config.REACT_APP_AUTH_TYPE=="AD")
    {
        msalInstance.handleRedirectPromise().then((e: any) => {
      console.log("mobile", mobile);
      console.log("Hiii", JSON.stringify(e));
      // var loginData : any
      //  loginData = JSON.stringify(e)
      // console.log("loginData", e.account.username)
      console.log("REACT_APP_AUTH_API_BASE", config.REACT_APP_AUTH_API_BASE)
      try {
        axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + e.account.username + "&device=" + mobile).then(res => {
      //    callback(res.data);
          console.log("res.data", res.data)
        }).catch(() => {
        //  callback(false)
        })
      } catch (error) {
      //  callback(false)
      }

      if(e){

        //axios.defaults.headers.common["Authorization"] = "Bearer "+e.accessToken;
        //dispatch(setLoginDetails({'token':'Hi Hello','userName':e.account.username,'userAccount':e}));
        dispatch(setLoginDetails({'token':e.accessToken,'userName':e.account.username,'userAccount':e}));
        dispatch(setChatShowChat(false));
        dispatch(setCallModalView(false));
        getAuthApi({authToken:"Bearer "+e.accessToken,type:config.REACT_APP_AUTH_TYPE},(res)=>{
          if(res)
          dispatch(setUserVirtualNumber(
            {
              EmployeeV_Nbr: res[0].EmployeeV_Nbr,
              Id: res[0].Id
            }
          ))
          dispatch(setUserDetails(
            {
              // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
              EmployeePh_Nbr:res[0].EmployeePh_Nbr,
              EmployeeQR_Cd:res[0].EmployeeQR_Cd,
              EmployeeFull_Nm:res[0].EmployeeFull_Nm
            }
          ))
          console.log(res)
        })


        // getAutoPilot({authToken:"Bearer "+e.accessToken},(res)=>{

        // })
        sessionStorage.setItem("login",e.accessToken);
        sessionStorage.setItem('loginName',e.account.username);
      }});

    }

    axios.interceptors.response.use(response => {
        return response;
    },async error => {
      
        console.log(error);
        const originalRequest = error.config;
        if(error.response) {
        if (
          error.response.status === 401 &&
          originalRequest.url === "/auth/generate-token"
          // true
        ) {
          localStorage.clear();
        // router.push("/");
          // history.push('/');
          window.location.href = "/error"
          return Promise.reject(error);
        }
    
        if (error.response.status === 401 && !originalRequest._retry) {
          if(config.REACT_APP_AUTH_TYPE=="pingfederate")
          {
            //check refresh token
            //if refresh token is there then create another api for generate new acess token with current refresh token
            if(sessionStorage.getItem("refreshtoken") && sessionStorage.getItem("refreshtoken") !== "")
            {
              let payload: any= {
                  refresh_token:sessionStorage.getItem("refreshtoken")
              }
              PingAuthRefresh(payload,(res)=>{
                if(res.access_token!=null)
                {
                  console.log(res);      
                  if(res.refresh_token!=null)
                  {
                    sessionStorage.setItem("refreshtoken",res.refresh_token);
                  }
                // axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                  sessionStorage.setItem("login",res.access_token);
            
                  dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                  dispatch(setChatShowChat(false));
                  dispatch(setCallModalView(false));
                  //if api call is success add in local storage and give access to the user 
                  getAuthApi({authToken:"Bearer "+res.access_token, type:config.REACT_APP_AUTH_TYPE},(res)=>{
                    console.log(res);
                    if(res)
                    dispatch(setUserVirtualNumber(
                      {
                        EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                        Id: res[0].Id
                      }
                    ))
                    dispatch(setUserDetails(
                      {
                        // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                        EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                        EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                        EmployeeFull_Nm:res[0].EmployeeFull_Nm
                      }
                    ))
                    setIsLoggedIn(true);
                  })
                }
                else{
                      LogoutPing();
                }
        
              })

            }
            else
            {
              if(sessionStorage.getItem("login")&& sessionStorage.getItem("login") !== "")
                    {  
                      LogoutPing();
                    }
            }
                  
          }
          else if(config.REACT_APP_AUTH_TYPE=="zing"){
            if(!config.REACT_APP_IS_CROSS_PLATFORM) {
              if(sessionStorage.getItem("login") && sessionStorage.getItem("login")!==""){
                setIsLoggedIn(false);
                sessionStorage.clear();
                dispatch(setLoginDetails({'token':'','userName':'','userAccount':''}));
              }
            }
          }
          else if(config.REACT_APP_AUTH_TYPE=="AD")
          {
            originalRequest._retry = true;
          
            // const refreshToken = '0.AS0AQnsmL3Rw10OSf2zVwrpCdfpUxGM1yixKgwtXGlW_ewotAP4.AgABAAEAAAD--DLA3VO7QrddgJg7WevrAgDs_wQA9P9sQKbXIiQ1MC2W38gtPvD3gLLFb8EXXddnx3SvQhhfdqEc2_xywarLXU4DgGphVoaVXdxgn97JcEGBMBzDyZaeC-GLcT39o_D4YQSNUe2bMlnDKBqa_jZqxRiQU9C2XvcSCSwe3LAsX-PkROfSQOyXYAD8jIWc7oXGBSAM2NTggE5PVKjTaFsmrQISayd8C3RqosSes4SqOa0mJa1RWnDiRijXAdmOF2tS9r4OEc_oN6XUI6ZHQmTWPNkns2XKFhF-gL0pKHIHnW4kH3xT6zjbmTq_L5NPBdytDHo2oWpJMfvjIIoqX8a6D6Ikd7drqnlYod96Uc5uTIF5DYbw92NNqrWBEdtLTi5HEN6VuqeXD2CffQFkLV5Xa4zYkKrdDheMYUOh-h4NuCzMQbLW_7T4vhLl-PWfOQBQuNkDQX-Yz8AO3RK1THufbZVtr-bSmMzu1G6XBsEdR3uzV2jS228if4drQnm18p9YV3oKSpRxowMx1pn-TP66JZe91rgs_5OG4k18174dRNO_UCtto9q6ctrRlKSQcsLeb7PYa4PulVmEWEKCA42RdDfi5-jRbmIdkJyxSMD54hLf2aTTPn63ZEW2aevhgOGUvjWYG2Rc8WOC7IuB3EEqu-H8AqV1wkqt62hai6rI7dw3pXwraYO0wp0mP6xO8g7C0eyEPNMVZ3bb-16P_sxBAoyDaket44_PA3FzsAof03P6WdohqftXD1UIUb9ARWQ1CzeUXGFccngyUctnjBQEmZFbFtjyXjydzt9hL6s5f8RtqISMGL0XzGFcJEd3zFvU349M8BN3G5lCRcEotOr6Ng'//store.state.refresh_token;
            console.log('hi')
            var currentAccount:any = msalInstance.getAccountByUsername(loginDetails as string);
            // localStorage.setItem('errorauth',error.response.status);
            // window.location.href = "/error"
              var silentRequest:any = {
                  scopes:loginRequest.scopes,
                  account: currentAccount,
                  forceRefresh: false
              };
              var request = {
                scopes: loginRequest.scopes,
                loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
              };
              const tokenResponse:any = await msalInstance.acquireTokenSilent(silentRequest).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return msalInstance.acquireTokenRedirect(request as any)
                }
              });
              console.log("Silent Response");
              console.log(tokenResponse);
                    if (tokenResponse) {
                      console.log("tokenResponse", tokenResponse)
            
              sessionStorage.setItem("login",tokenResponse.accessToken)
              dispatch(setLoginDetails({'token':tokenResponse.accessToken,'userName':tokenResponse.account.username,'userAccount':tokenResponse}));
              
              originalRequest.headers.Authorization = "Bearer "+tokenResponse.accessToken;
              console.log(originalRequest)
              return axios(originalRequest);
          }
      
    }
          // return axios
          //   .post("/auth/generate-token", {
          //     refresh_token: refreshToken,
          //   })
          //   .then((res) => {
          //     if (res.status === 200) {
          //       //store.commit("updateAccessToken", res.data.data);
          //       // store.state.refresh_token = res.data.data.refresh_token;
          //       // store.state.access_token = res.data.data.access_token;
          //       
          //     }
          //   });
        
        }
        return Promise.reject(error);
      }
    });

    function LogoutPing(){
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        if(config.REACT_APP_AUTH_TYPE=="AD"){
          capacitormsal.signOut({
            authority: config.REACT_APP_AD_AUTHORITY as string,
            clientId: config.REACT_APP_AD_CLIENTID as string,
            redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
            scope: config.REACT_APP_CROSS_SCOPE as string,
            })
            .then(async (response) => {
              sessionStorage.setItem("login", "");
              deleteUserDetails().then(() => {
                window.location.href = "/"  
              });               
            })
            .catch(error => {
              sessionStorage.setItem("login", "");
              deleteUserDetails().then(() => {
                window.location.href = "/"  
              });
            })
        } else {
          let browser = InAppBrowser.create('https://pingfed-ut.merck.com/idp/startSLO.ping', '_blank', 'location=no');
          browser.on('loadstop').subscribe(async event => {
            browser.close();
          });
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/"  
          });
        }
      }
      else
      {
        sessionStorage.clear();
        localStorage.clear();
        var logoutUrl = "https://pingfed-ut.merck.com/idp/startSLO.ping";
        window.location.href = logoutUrl     
        window.location.href =config.REACT_APP_PING_REDIRECT_URL;
      }
                
    }
    useEffect(() => {
      setAuthType(config.REACT_APP_AUTH_TYPE)
      
      //check if you have code query param
      const state = new URLSearchParams(window.location.search).get("state");
      const code = new URLSearchParams(window.location.search).get("code");
      console.log(state,code) 
      
      //if yes call api and show the loader and not the loggin button
      if(!config.REACT_APP_IS_CROSS_PLATFORM) {
        if(state!=undefined&&code!=undefined)
      {
        // let payload: any= {

        //   grant_type: 'authorization_code',

        //   client_id: config.REACT_APP_PING_CLIENTID,

        //   redirect_uri: config.REACT_APP_PING_REDIRECT_URL,

        //   code: code,

        //   client_secret: config.REACT_APP_PING_CLIENTSECRET

        // };
        let payload: any= {
          code: code
        }
          sessionStorage.clear();
          localStorage.clear();
        PingAuthApi(payload,(res)=>{
          if(res.access_token!=null)
          {
            console.log(res);      
            if(res.refresh_token!=null)
            {
              sessionStorage.setItem("refreshtoken",res.refresh_token);
            }
          // axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
            sessionStorage.setItem("login",res.access_token);
      
            dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
            dispatch(setChatShowChat(false));
            dispatch(setCallModalView(false));
            //if api call is success add in local storage and give access to the user 
            getAuthApi({authToken:"Bearer "+res.access_token, type:config.REACT_APP_AUTH_TYPE},(res)=>{
              console.log(res);
              if(res)
              dispatch(setUserVirtualNumber(
                {
                  EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                  Id: res[0].Id
                }
              ))
              dispatch(setUserDetails(
                {
                  // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                  EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                  EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                  EmployeeFull_Nm:res[0].EmployeeFull_Nm
                }
              ))
              setIsLoggedIn(true);
            })
          }

        })
      } else {
          if(config.REACT_APP_AUTH_TYPE==="pingfederate") {
            if(sessionStorage.getItem("login") && sessionStorage.getItem("login") !== "") {
              setIsLoggingSilently(true);
              getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},(res)=>{
                console.log(res);
                setIsLoggingSilently(false);
                if(res) {
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  setIsLoggedIn(true);
                }
              })

            }
          }
      } 
      }
    }, [])
    function generateRandomString() {
      var array = new Uint32Array(28);
      window.crypto.getRandomValues(array);
      return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
        ""
      );
    }
    var nonce = generateRandomString();
    var state = generateRandomString();
    function encodeQueryData(data) {
      let ret:any = [];
      for (let d in data){    console.log(data[d]);
        ret.push(encodeURIComponent(d)+ "=" + encodeURIComponent(data[d]));
      }
      return ret.join("&");
    }
    function sha256(plain) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest("SHA-256", data);
    }
    // function base64urlencode(str) {
    //   
    //   console.log(str);
    //   // return btoa(String.fromCharCode.apply(new Uint8Array(str)));
    //   console.log(typeof(new Uint8Array(str)));
    //  // console.log(String.fromCharCode.apply( new Uint8Array(str)));
    //  console.log(JSON.stringify(new Uint8Array(str)));
    //   let temp=  btoa(JSON.stringify( new Uint8Array(str)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''))
    //  // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    //   console.log(temp)
    //   return temp;
    //   // return btoa(str)
    //   // .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    //   // // return str.toString('base64')
    //   // .replace(/\+/g, '-')
    //   // .replace(/\//g, '_')
    //   // .replace(/=/g, '');
    // }
    function base64urlencode(a) {
      var str = "";
      var bytes = new Uint8Array(a);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
      }
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }
    async function pkceChallengeFromVerifier(v) {
      ;
      let hashed:any = await sha256(v);
      return base64urlencode(hashed);
    }
    async function handleLogin ()  {
      if(config.REACT_APP_IS_CROSS_PLATFORM) {
        if(config.REACT_APP_AUTH_TYPE=="AD"){
          capacitormsal.signin({
            authority: config.REACT_APP_AD_AUTHORITY as string,
            clientId: config.REACT_APP_AD_CLIENTID as string,
            redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
            scope: config.REACT_APP_CROSS_SCOPE as string,
          })
            .then(async (response) => {
                setIsLoggingSilently(true);
                registerNotifications();
                axios.defaults.headers.common["Authorization"] = "Bearer "+response["accesstoken"];
                sessionStorage.setItem("login",response["accesstoken"]);
                sessionStorage.setItem('loginName',response["name"]);
                dispatch(setLoginDetails({'token':response["accesstoken"],'userName':response["name"],'userAccount':null}));
                dispatch(setChatShowChat(false));
                dispatch(setCallModalView(false));
    
                
                getAuthApi({authToken:"Bearer "+response["accesstoken"],type:config.REACT_APP_AUTH_TYPE},async (res)=>{
                  
                  if(res)
                  dispatch(setUserVirtualNumber(
                    {
                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                      Id: res[0].Id
                    }
                  ))
                  dispatch(setUserDetails(
                    {
                      // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                    }
                  ))
                  
                  await createDatabase();
                  await addUserDetails(response["accesstoken"]);
                  let user = await getUserDetails();
                  setIsLoggingSilently(false);
                  setIsLoggedIn(true);
                });
    
    
                
                // let langLocal = "es-ES"// window.navigator.language.toLowerCase();
                // getlangTransData(window.navigator.language.toLowerCase(),(res)=>{     
                //   if (res && res.data) {
                //     console.log("lang trans val",res.data['translatedValues'][0]);
                //     let langjson:any={};
                //     let transjson:any={};
                //     transjson['translation']=res.data['translatedValues'][0];
                //     // console.log("lang trans",transjson);
                //     langjson[langLocal]=transjson;
                //     console.log("lang",langjson);
                //     // console.log(langjson);
                //     setTransData(langjson);
                //     transLocData=langjson;
                //     // 
                //     console.log("lang before trans function",transLocData,"lang loc",langLocal);
                //     // console.log("lang before trans lang function",translang,"lang loc",langLocal);
                //     // ;
            
            
                //     // if(langLocal === "en-us") {
                //       i18n
                //       .use(initReactI18next) // passes i18n down to react-i18next
                //       .init({
                //         // the translations
                //         // (tip move them in a JSON file and import them,
                //         // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
                //         // resources: transData[0],
                //         resources: transLocData,
            
                //         lng: langLocal, // if you're using a language detector, do not define the lng option
                        
            
                //         interpolation: {
                //           escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
                //         }
                //       });
                      
                //     // }
    
                //     // window.location.href = "/Contacts";//window.location.origin;
                //     sessionStorage.setItem("isloggedin","1");
                //     }
                  
                        
                //   })
    
            })
            .catch(error => {
                console.log("Error");
                console.log(error);
                setIsLoggingSilently(false);
                setIsLoggedIn(false);
            })
          } else if(config.REACT_APP_AUTH_TYPE=="zing"){
            // 
          }
          else {
            var code_verifier = generateRandomString();
            var code_challenge = await pkceChallengeFromVerifier(code_verifier);
            const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
            const params = {
              client_id: config.REACT_APP_PING_CLIENTID,
              redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
              response_type: ["code"],
              scope: "openid profile email",
              state: state
            };
        
    
            sessionStorage.clear();
            localStorage.clear();
            // Build the authorization URL
            const url = oAuthurl + "?" + encodeQueryData(params);
            console.log(url);
            let browser = InAppBrowser.create(url, '_blank', 'location=no');
            browser.on('loadstart').subscribe(async event => {
                console.log("---event.url---");
                console.log(event.url);
                if(event.url.startsWith(config.REACT_APP_PING_REDIRECT_URL)) {
                    let code = getParameterByName('code', event.url);
                    let state = getParameterByName('state', event.url);
                    browser.close();
                    alert("--code--" + code);
                    if(code && code !=="") {
                      let payload: any= {
                        code: code
                      }
                      PingAuthApi(payload,(res)=>{
                        // alert(JSON.stringify(res));
                        alert("Access token:" + res.access_token);
                        // alert(res.access_token);
                        if(res.access_token!=null) {
                          console.log(res); 
                                
                          // axios.defaults.headers.common["Authorization"] = "Bearer "+res.access_token;
                          sessionStorage.setItem("login",res.access_token);
                          sessionStorage.setItem('loginName','');
                          dispatch(setLoginDetails({'token':res.access_token,'userName':"",'userAccount':null}));
                          dispatch(setChatShowChat(false));
                          dispatch(setCallModalView(false));
                          //if api call is success add in local storage and give access to the user 
                          getAuthApi({authToken:"", type:config.REACT_APP_AUTH_TYPE},async (apires)=>{
                            alert(JSON.stringify(apires));
                            console.log(apires);
                            if(apires) {
                              dispatch(setUserVirtualNumber(
                                {
                                  EmployeeV_Nbr: apires[0].EmployeeV_Nbr,
                                  Id: apires[0].Id
                                }
                              ))
                              dispatch(setUserDetails(
                                {
                                  // EmployeeEmail_Adr:res[0].EmployeeEmail_Adr,
                                  EmployeePh_Nbr:apires[0].EmployeePh_Nbr,
                                  EmployeeQR_Cd:apires[0].EmployeeQR_Cd,
                                  EmployeeFull_Nm:apires[0].EmployeeFull_Nm
                                }
                              ))
                              await createDatabase();
                              await addUserDetails(res.access_token);
                              let user = await getUserDetails();
                              setIsLoggedIn(true);

                            }
                          })
                        }
                
                      })
                    }
                }
            });
    
          }
      } else {
        if(config.REACT_APP_AUTH_TYPE=="AD"){
        msalInstance.loginRedirect(loginRequest).then(e=>{
          console.log(e)
          
        }).catch((e:any) => {
            
          console.log(e);
          
        });
        }
        else
        {
          var code_verifier = generateRandomString();
          var code_challenge = await pkceChallengeFromVerifier(code_verifier);
          const oAuthurl = config.REACT_APP_PING_OAUTH_URL;
          const params = {
            client_id: config.REACT_APP_PING_CLIENTID,
            redirect_uri: config.REACT_APP_PING_REDIRECT_URL,
            response_type: ["code"],
            scope: "openid profile email",
            state: state
          };
      
          sessionStorage.clear();
          localStorage.clear();
          // Build the authorization URL
          const url = oAuthurl + "?" + encodeQueryData(params); 
          console.log(url);
          // const authClient = new PingOneAuthClient({
          //   environmentId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
          //   clientId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
          //   redirectUri: 'http://localhost:8080',
          //   postLogoutRedirectUri: 'http://localhost:8080',
          //   scopes: ['openid','profile', 'email', 'address'],
          //   responseType: ['token', 'id_token'],
          //   pkce: false
          // });
          // authClient.signIn();
          console.log(url);
          window.location.href=url;

        }
      }
    }

    function getParameterByName(name: string, url: string) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();
    
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
    
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }
      await PushNotifications.register();
    }
    
    const getDeliveredNotifications = async () => {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      console.log('delivered notifications', notificationList);
    }

    const handleOtpChange = (element, index) => {
      setIsInvalidOtp(false);
      const re = /^[0-9\b]+$/;

      if(isNaN(element.target.value)) return false;

      if (element.target.value === '' || re.test(element.target.value)) {
      setOtp([...otp.map((d,idx)=>(idx === index ? element.target.value : d))])
      }

      if(element.nativeEvent.inputType === 'insertText' && element.target.nextSibling){
          element.target.nextSibling.focus();
      }   

      if(element.nativeEvent.inputType === 'deleteContentBackward' && element.target.value === '' && element.target.previousSibling){
          element.target.previousSibling.focus();
      }
  }

    const handleBackClick = () => {
        setEmail("");
        setIsEmail(!isEmail);
    }

    const validateEmail = (value) => {
      console.log(value);
      setEmail(value);
        if(!value || value.length == 0) {
            setErrorText('Email is required');
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){
            setErrorText(' Please enter valid email ID')
        }else{
            setErrorText("")
            setEmail(value);
        }
    }

    async function sendOtp() {
      if(email && email.trim().length>0) {
        let payload = {
          EmailId : email
        }
        setIsLoading(true);
        await axios.post(config.REACT_APP_LOGIN_API_BASE+"/Login/SendOTP",payload).then(
          (res) => {
            if(res.status == 200) {
              setIsLoading(false);
              setIsEmail(true);
              
            }
          }
        ).catch((err)=> {
          if(err.request.status === 401 || 500){
            setErrorText(" Please enter valid email ID")
            setIsLoading(false);
          }
        })
      }
      setOtp([...otp.map(e => "")])
    }

    async function handleOTPSubmit() {
      if(email.length == 0){
        validateEmail(email);
      }
      if(!isEmail && email.length>0){
          sendOtp();
          setIsInvalidOtp(false);
      }

      if(isEmail){
      setIsLoading(true);
      let pass = otp.join("");
      if(pass && pass.length==4){
          let payload = {
              EmailId: email,
              OTP: String(pass),
          }

          await axios.post(config.REACT_APP_LOGIN_API_BASE+"/Login/ValidateOTP", payload).then(
              (response) => {
                  console.log(response)
                  console.log("token:"+response.data?.token)
                  if(response.data?.token!=null) {
                      axios.defaults.headers.common["Authorization"] = "Bearer "+response.data?.token;
                      sessionStorage.setItem("login",response.data?.token);
                      sessionStorage.setItem('loginName','');
                    debugger;
                      dispatch(setLoginDetails({'token':response.data?.token,'userName':"",'userAccount':null}));
                      dispatch(setChatShowChat(false));
                      dispatch(setCallModalView(false));
                      getAuthApi(
                          {
                              authToken:"Bearer "+response.data?.token,type:config.REACT_APP_AUTH_TYPE
                          }, async (res) => {
                              if(res){
                                setIsLoading(false)
                                  console.log(res)
                                  dispatch(setUserVirtualNumber({
                                      EmployeeV_Nbr: res[0].EmployeeV_Nbr,
                                      Id: res[0].Id
                                  }))
      
                                  dispatch(setUserDetails({
                                      EmployeePh_Nbr:res[0].EmployeePh_Nbr,
                                      EmployeeQR_Cd:res[0].EmployeeQR_Cd,
                                      EmployeeFull_Nm:res[0].EmployeeFull_Nm
                                  }))
                                  if(config.REACT_APP_IS_CROSS_PLATFORM ) {
                                    await createDatabase();
                                    await addUserDetails(response.data?.token);
                                  }
                                  setIsEmail(false);
                                  setIsInvalidOtp(false);
                                  registerNotifications()
                                  setIsLoggedIn(true);

                                  pushDetailsToBI(payload)
                              }
                          }
                      )
                  }
              
            }
          ).catch((err)=> {
            if(err.request.status === 401 ){
              let errorPayload = {
                EmailId: email,
                OTP: String(pass),
                status: false,
              }
              setIsInvalidOtp(true);
              setisOtpEmpty(false);
              setIsLoading(false);
              pushDetailsToBI(errorPayload);
            }
            if(err.request.status === 500){
              sessionStorage.setItem("login",'');
              sessionStorage.setItem('loginName','');
              setEmail("");
              setIsEmail(!isEmail);
              setIsLoading(false);
              setErrorText("There was an error in logging you in. Please re-enter the email to login");
            }
          })
      } 
      else{
        // setIsInvalidOtp(true);
        setisOtpEmpty(true);
        setIsInvalidOtp(false);
        setIsLoading(false);
      }
      }
      else{
        validateEmail(email);
      }
      
  }

  const pushDetailsToBI=(payload:{EmailId:string, status?: boolean})=>{
    try {
      axios.post(config.REACT_APP_AUTH_API_BASE + "/auth/login-details?email=" + payload.EmailId + "&device=" + mobile + "&status=" + payload.status).then(res => {
    //    callback(res.data);
        console.log("res.data", res.data)
      }).catch(() => {
      //  callback(false)
      })
    } catch (error) {
    //  callback(false)
    }
  }

    let IsCrossPlatForm = config.REACT_APP_IS_CROSS_PLATFORM
    let IsPingLogIn = config.REACT_APP_AUTH_TYPE=='pingfederate'
    let IsZingLogin = config.REACT_APP_AUTH_TYPE=='zing'
    return (
      <>
      { IsCrossPlatForm && IsLoggedIn && (
            <Home />
      )}
      { !IsCrossPlatForm && IsLoggedIn && IsZingLogin &&(
            <Home />
      )}
      { IsZingLogin && !IsLoggedIn && !IsPingLogIn && (
        <>
      <div className="login">
      {mobileStyle ?<div className="top-band-white-login"></div>:null}
          <div className="top-band-kki-login">
          <img src={KKITextLogo} alt="logo brand" /> 
          </div>
          
          <div className="icon-welcome-dev">
          {isEmail? <div className="btn-back-wrapper">
          
          <h1 className="login-title fadeIn mt-3">
            <i className="material-icons i-2p5" onClick={e => handleBackClick()}>{('arrow_back_ios')}</i>Login
          </h1> 
          </div> 
          : null}
          {!isEmail && 
            <div className="login-image">
              <img src={config.REACT_APP_LOGIN_IMAGE} className='d-block m-auto login-icon mb-2' alt="login logo" />
              {/* <img src={config.REACT_APP_LOGIN_BRAND_IMAGE} alt="logo brand" /> */}
              <img src={LogoImage} alt="logo brand" /> 
          </div>
          }
          {isEmail? 
          <div className="login-image login-image-otp">
          {/* <img src={config.REACT_APP_LOGIN_BRAND_IMAGE} alt="logo brand" /> */}
          <img src={config.REACT_APP_LOGO} alt="Logo Brand" />
          </div>
          : null}
        
          {/* Add spinner */}
          <div className="login-detail">
              { !isEmail? <h1 className="login-title fadeIn fw-bold">
                  Welcome
              </h1> : null}
          {(IsLoggingSilently) ? <Spinner /> :
            <div className="login-top-box">
              <h4 className="login-subtitle fadeIn text-small">
                  <small>
                  {isEmail ? 'Please enter the verification code sent to your number.' : 'Please enter your Kyowa Kirin email, and you will receive a verification code to associated mobile number.' }  
                  </small> 
              </h4>

              { !isEmail ? 
              <>    
              <div className="email-wrapper fadeIn">
                  <input 
                  ref={emailInputRef}
                  type="text" 
                  className="email-input" 
                  placeholder="Enter KKNA email"
                  onChange={e=> validateEmail(e.target.value)}/>
              </div>
              <h4 className="login-subtitle error-msg fadeIn">
                  {errorText? errorText : null}
              </h4>
              </>
              : <>
              <input id='hiddenOtpInput' inputMode='numeric' style={{height: '0', border: 'none', display: 'absolute'}} ref={otpInputRef} autoComplete='one-time-code' value={otpFromMessage} onInput={(e) => {moveCursorToNext(e)}} onChange={(e) => {handleHiddenOtpChange(e)}} type='text'  pattern="\d*" maxLength={4}/>
              <div className="otp-wrapper fadeIn" ref={otpContainerRef}>
                {
                  otp.map((data, index)=> {
                    return (
                        <div
                        className="otp-field d-flex justify-content-center fadeIn"
                        id={`item${index}`}
                        key={index}
                        onClick={() => {handleOtpDivClick(index)}}
                        >
                           <span id={`value${index}`} >{data}</span> 
                        {/* {data?'*':''} */}
                        
                        </div>
                        );
                    })    
                }
              
              {/* {
                  otp.map((data, index)=> {
                      return (1
                          <input
                          className="otp-field fadeIn"
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          name="otp"
                          maxLength={1}
                          key={index}
                          value={data}
                          onChange={e=> handleOtpChange(e,index)}
                          onFocus={e=> e.target.select()}
                          />
                          );
                      })    
                  } */}
              </div> 
              {isInvalidOtp && (
              <h4 className="login-subtitle error-msg fadeIn">
                OTP entered is invalid.
              </h4>
              )}
              {isOtpEmpty && (
                  <h4 className="login-subtitle error-msg fadeIn">
                      OTP required.
                  </h4>
                )}
              {/* {isInvalidOtp && (
              <h4 className="login-subtitle error-msg fadeIn">
                OTP entered is invalid.
              </h4>
              )}

              <h4 className="login-subtitle fadeIn">
                  Didn't receive the code?  
              </h4>

              <button disabled={isOtpBtnDisabled} id='otpBtn' className="btn-resend mt-0 fadeIn" type="submit" onClick={() => {
                setOtp([...otp.map(e => "")])
                sendOtp();
                setIsInvalidOtp(false);
                setIsOtpBtnDisabled(true);
                setTimeout(()=> {
                  setIsOtpBtnDisabled(false);
                },30000)
              } }>
                  {isOtpBtnDisabled ? 'Please wait for 30 seconds' : 'RESEND CODE'}
              </button> */}
              </>
              }
              
              <button className="otp-btn btn font--btn" type="submit" onClick={e => handleOTPSubmit()} disabled={isLoading ? true : false}>
                  {isLoading ? <CircularProgress color="success" size="1rem"/> :  isEmail ? 'Verify' : 'Submit'}
              </button>
            </div>
          }
            {!isEmail ? null : 
            <div className="resend-otp-div">
            {/* {isInvalidOtp && (
              <h4 className="login-subtitle error-msg fadeIn">
                OTP entered is invalid.
              </h4>
              )} */}

              <h4 className="login-subtitle fadeIn">
                  Didn't receive the code?  
              </h4>

              <button disabled={isOtpBtnDisabled} id='otpBtn' className="btn-resend mt-0 fadeIn" type="submit" onClick={() => {
                setOtp([...otp.map(e => "")])
                sendOtp();
                setIsInvalidOtp(false);
                setIsOtpBtnDisabled(true);
                setisOtpEmpty(false);
                setOtpFromMessage('');
                for(let i = 0; i < 4; i++) {
                    document.getElementById(`value${i}`)?.classList.remove('cursor');
                } 
                setTimeout(()=> {
                  setIsOtpBtnDisabled(false);
                },0)
              } }>
                  RESEND CODE
              </button>
              </div>
              }
          </div>
          </div>
      </div>
      </>
      )}
      
      { IsCrossPlatForm && !IsZingLogin && !IsLoggedIn && (
                <div className="login-layout-bg login-bg">
                
                <div className="login-left-div">
                  <img src={config.REACT_APP_LOGIN_IMAGE ? config.REACT_APP_LOGIN_IMAGE : LoginBgImage} alt="LoginImage" />
                </div>
                {(IsLoggingSilently) ? <Spinner /> :
                <div className="login-right-div">
                  <h1 className="login-text font--xl">{("let's sign you in")}</h1>
                  <h4 className="login-text font--lg">
                    {('Your organization uses the following login modes to access the application')}
                  </h4>
                <button
                    className="rounded-primary-40 btn font--btn"
                    type="button"
                    onClick={handleLogin}
                  >
                    {config.REACT_APP_LOGIN_BUTTON_TEXT ? config.REACT_APP_LOGIN_BUTTON_TEXT : config.REACT_APP_AUTH_TYPE==="pingfederate" ? ('Login with PING') : ('Login with AD')}
                  </button>
                </div>
                }
              </div>
      )}

      { !IsCrossPlatForm && !IsZingLogin && !IsPingLogIn &&(
        <><AuthenticatedTemplate>
            <Home /> {/*HomePage*/}
          </AuthenticatedTemplate><UnauthenticatedTemplate>
              <div className="login-layout-bg login-bg">

                <div className="login-left-div">
                  <img src={config.REACT_APP_LOGIN_IMAGE ? config.REACT_APP_LOGIN_IMAGE : LoginBgImage} alt="LoginImage" />
                </div>

                <div className="login-right-div">
                  <h1 className="login-text font--xl">let's sign you in</h1>
                  <h4 className="login-text font--lg">
                    Your organization uses the following login modes to access the
                    application
                  </h4>
                  <button
                    className="rounded-primary-40 btn font--btn"
                    type="button"
                    onClick={async () => {await handleLogin();} }
                  >
                    {config.REACT_APP_LOGIN_BUTTON_TEXT ? config.REACT_APP_LOGIN_BUTTON_TEXT : 'Login with AD'}
                  </button>


                </div>
              </div>
            </UnauthenticatedTemplate></>
      )

      
      }
      {!IsCrossPlatForm && IsLoggedIn && IsPingLogIn &&(
            <Home />
      )}
      
      { !IsCrossPlatForm && !IsZingLogin && !IsLoggedIn && IsPingLogIn &&(
                <div className="login-layout-bg login-bg">
                
                <div className="login-left-div">
                  <img src={config.REACT_APP_LOGIN_IMAGE ? config.REACT_APP_LOGIN_IMAGE : LoginBgImage} alt="LoginImage" />
                </div>
                {(IsLoggingSilently) ? <Spinner /> :
                <div className="login-right-div">
                  <h1 className="login-text font--xl">{("let's sign you in")}</h1>
                  <h4 className="login-text font--lg">
                    {('Your organization uses the following login modes to access the application')}
                  </h4>
                <button
                    className="rounded-primary-40 btn font--btn"
                    type="button"
                    onClick={handleLogin}
                  >
                    {('Login with PING')}
                  </button>
                </div>
                }
              </div>
      )}
    </>
    )
  }

  export default Login