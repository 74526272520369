import React, { useState,useEffect } from "react";
import ZingLogoHome from "../../assets/images/ZingLogoHomePage.png";
import config from "../../env.json";
import "./MobileHeader.css";
import MobileSideBar from "../mobileSidebar/MobileSideBar";
import {useSelector} from 'react-redux'
import VideoFloater from "../../components/video/videoFloater/VideoFloater";
import MobileProfileSidebar from "../../components/mobileProfileSidebar/MobileProfileSidebar";
function MobileHeader() {
  const [showSideBar,setShowSideBar ]=useState(false);
  const [header,setHeader]=useState('');
  const mobileProfileSidebar=useSelector((state: any) => state.login.mobileProfileSidebar);
  useEffect(() => {
    window.addEventListener('orientationchange', function() {
      setShowSideBar(false)
    })
  }, [])
  
  return (
    <div className="MobileHeader" id="MobHeader">
      <div className="content__header">
        <div className="row row--one">
          <div className="col-1 content__header__bars" onClick={()=>{setShowSideBar(!showSideBar)}}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <span className="col-2 offset-4 content__header__logo">
            <img src={config.REACT_APP_LOGO ? config.REACT_APP_LOGO : ZingLogoHome} alt="App Logo" />
          </span>
        </div>
      </div>
      <MobileSideBar show={showSideBar} hideSideBar={() => {setShowSideBar(false)}}/>
      { mobileProfileSidebar === true?
        <MobileProfileSidebar  /> :null}
      
    </div>
  );
}

export default MobileHeader;
