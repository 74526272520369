import axios from "axios";
import config  from '../../env.json';

function getAllContacts(params,callback){
  let {page,pageSize,search,CancelToken}=params;
    try {
        
      let can:any={
        cancelToken: CancelToken
      }
      if(!CancelToken || search === '' || !search) can={}
      
      axios.get(config.REACT_APP_CONTACTS_API_BASE + '/contact?IsPrescriberOnly=false&Page=' + page + '&PageSize=' + pageSize + '&Search=' + search,can)
        .then(res=>{ 
          
          if(res)
          callback(res.data.data);
        }).catch((error)=>{
          console.log('error aya first catch',axios.isCancel(error))
          if (axios.isCancel(error)) {
            callback({error:true})
          }
          else{
            callback([])
          }
          
        })
        //controller.abort()
    } catch (error) {
      console.log('error aya second catch')
     
        callback([])
    }
   
}

function contactDetails(params,callback){
    let {Id,Is_Prescriber}=params;
    debugger
    try {
        axios.get(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+Id,{
            params:{
              "IsPrescriber":Is_Prescriber,
            }
          }).then(res=>{
            console.log(res)
          callback(res.data.data);
        }).catch((err)=>{
          console.log(err)
          if(err.response && err.response.data){
            if(err.response.data.message=="No data found"){
              callback({message:"No data found"})    
            }
            else{
              callback({})
            }
          }
          else{
            callback({})
          }
        })
    } catch (error) {
        callback({})
    }
} 

function editContact(payload,callback){
  
  try {
      axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact",payload).then(res=>{
        callback(res.data.data);
      }).catch(()=>{
        callback(null)
      })
  } catch (error) {
      callback(null)
  }
}

function addNewContact(payload,callback){
  
  try {
      axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateprescriber",payload).then(res=>{
        callback(res.data.data);
      }).catch(()=>{
        callback(false)
      })
  } catch (error) {
      callback(false)
  }
}

function deleteContactApi(contactId,callback){
  try {
    axios.delete(config.REACT_APP_CONTACTS_API_BASE+"/contact/"+contactId).then(res=>{
      callback(true);
    }).catch(()=>{
      callback(false)
    })
} catch (error) {
    callback(false)
}
}

function getPrescriberOnlyContacts(params,callback,cancel){
  
  let {page,pageSize,search}=params;
    try {
      // debugger
      // if (typeof cancelToken != typeof undefined) {
      //   cancelToken.cancel("Operation canceled due to new request.")
      // }
      // cancelToken = axios.CancelToken.source()

      
      let can=cancel?cancel.cancel:'';
      axios.get(config.REACT_APP_CONTACTS_API_BASE + '/contact?IsPrescriberOnly=true&Page=' + page + '&PageSize=' + pageSize + '&Search=' + search,
        {
          cancelToken:can
        }
        )
        .then(res=>{ 
          callback(res.data.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
   
}

async function addAssociation(params){
  
    try {
 await  axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",params).then(res=>{
        return true;
      }).catch(()=>{
        return false;
      })
  } catch (error) {
      return false;
  }
  
}
async function deleteAssociation(id){
  
  try {
  await  axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",id).then(res=>{
      return true;
    }).catch(()=>{
      return false;
    })
} catch (error) {
    return false;
}

}
function checkNumberExist(number:string,callback:any){
  try {
     axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/numberexists",{
      "AcctPh_Nbr":number
  
  }).then(res=>{
    if(res.data){
      callback(res.data.data);
    }
    else{
      callback( null)
    }
      }).catch((error:any)=>{
        callback( null)
      })
  } catch (error) {
      callback( null);
  }
}
export {getAllContacts,contactDetails,editContact,addNewContact,deleteContactApi,getPrescriberOnlyContacts,addAssociation,deleteAssociation,checkNumberExist}