import React, { useEffect, useState } from "react";
import "./MobileSideBar.css";
import Avatar from "@mui/material/Avatar";
import logout from "../../assets/icons/logout.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useSelector,useDispatch} from 'react-redux'
import { useTheme } from '@mui/material/styles';
import qrcode from "../../assets/images/qr-code.svg"
import { msalInstance } from "../../index";
import { setProfileSidebarView, setMobileProfileSidebarView} from '../../app/slice/loginSlice';
import poweredByP360 from "../../assets/images/powered_by_p360.png"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../../env.json'
import axios from "axios";
import HomeIcon from '@mui/icons-material/Home';
import LogoutModal from "../../components/logoutModal/LogoutModal";
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
// import MobileProfileSidebar from '../../components/mobileProfileSidebar';
import MobileProfileSidebar from '../../components/mobileProfileSidebar/MobileProfileSidebar'
// import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { setUserMsgChannelType } from "../../app/slice/authApiSlice";
import {capacitormsal} from "capacitormsal";
import { Keyboard } from '@capacitor/keyboard';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { setTitle } from "../../app/slice/sideBarSlice";
import {
  setPage
} from "../../app/slice/chatSlice";
const Background:any = config.REACT_APP_PROFILE_BG_IMG;


function stringAvatar(name: string) {
  let tempname: any = name ? name.split(" ") : "U K".split(" ");
  let shortName = tempname[0][0];
  if (tempname[1]) shortName += tempname[1][0];

  return {
    sx: {
      bgcolor: "#FAF8FF",
      color: document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize: "2rem",
      fontWeight: "bold",
      width: "100%",
      height: "100%",
      border: "1px solid #734BD14D",
    },
    children: shortName,
  };
}

function MobileSideBar({ show ,hideSideBar}) {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const [heightOfMain, setHeightOfMain] = useState(0);
  const [MainDivHeight, setMainHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [showLogoutModal,setShowLogoutModal]=useState(false);

  const [widthOfMain, setWidthofMain] = useState(0);
  const [paddingValTop,setPaddingValTop]=useState(0);
  const mobileProfileSidebar=useSelector((state: any) => state.login.mobileProfileSidebar);
  const userPhno=useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const userEmail=useSelector((state: any) => state.authApi.EmployeeEmail_Adr);
  const userQR=useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const userName=useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const [paddingValBottom,setPaddingValBottom]=useState(0);
  const [showProfileSidebar,setShowProfileSidebar]=useState<any>(false);
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

 
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [menuItems,setMenuItems] = useState<any[]>([]);
  const token = useSelector((state: any) => state.login.token);

const getMenuItems = async()=>{
    debugger
    // axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    console.log("Mobilesidebar"+token)
    delete axios.defaults.headers.common["Authorization"];
    axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem('login');

    
    const menuItems =  axios.get(`${config.REACT_APP_AUTH_API_BASE}/auth/getuserpermissions`)
    .then(response => {
      response.data.sort((a, b) => parseFloat(a.Display_Or) - parseFloat(b.Display_Or));
      response.data = response.data.filter(item => item.Display_Or != -1);
      // response.data.map(d => d.Display_Nm === "WHATSAPP",dispatch(setUserMsgChannelType(true)));

      let x = response.data.filter(d => d.Display_Nm === "WHATSAPP")
        if(x.length>0){
          console.log(x);
          dispatch(setUserMsgChannelType(true))
       } else{
        dispatch(setUserMsgChannelType(false))
       }
        let y=  response.data.filter(d => d.Display_Or >= 0);
      setMenuItems(y);
      
    })
    .catch(error => {
        console.error('There was an error!', error);
    });

    
}

  const handleClose = () => {
    setOpen(false);
  };
  // const handleLogoutPopup=(torf)=>{
  //   handleClose();
  //   if(torf){
  //     logoutFunction();
  //   }
  // }
  const logoutFunction=()=>{
    
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
      capacitormsal.signOut({
        authority: config.REACT_APP_AD_AUTHORITY as string,
        clientId: config.REACT_APP_AD_CLIENTID as string,
        redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
        scope: config.REACT_APP_CROSS_SCOPE as string,
        })
        .then(async (response) => {
          dispatch({ type: 'RESET_APP' })
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/"  
          });              
        })
        .catch(error => {
          dispatch({ type: 'RESET_APP' })
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/"  
          });
        })
      } else if(config.REACT_APP_AUTH_TYPE==='zing'){
        sessionStorage.clear();
        localStorage.clear();
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });   
       } else {
        let browser = InAppBrowser.create('https://pingfed-ut.merck.com/idp/startSLO.ping', '_blank', 'location=no');
        browser.on('loadstop').subscribe(async event => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/"  
        });
      }
    } else {
      if(config.REACT_APP_AUTH_TYPE=="AD"){
      let account=sessionStorage.getItem('loginName') as string
      dispatch({ type: 'RESET_APP' })
      msalInstance.logoutRedirect({
        account: msalInstance.getAccountByUsername(account)
      }).catch((e:any) => {
        console.log(e);
         
      });
    }
    else if(config.REACT_APP_AUTH_TYPE==='zing'){
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/"  
     }
    else
    {
        sessionStorage.clear();
        localStorage.clear();
        var logoutUrl = "https://pingfed-ut.merck.com/idp/startSLO.ping";
        window.location.href = logoutUrl
       window.location.href =config.REACT_APP_PING_REDIRECT_URL;
      // window.open(config.REACT_APP_PING_REDIRECT_URL, "_blank")
    }

    }

  }

  const handleLogoutPopup =(e:any) =>{
    // console.log(e)
    if(e=== true){
      logoutFunction();
    } 
  }
  function handleResize() {
    let doc: any = document;
    let height = 0;
    if (
      doc.getElementsByClassName("mobileSideBar_upper")[0] &&
      doc.getElementsByClassName("mobileSideBar_header")[0]
    ) {
      height =
        doc.getElementsByClassName("mobileSideBar_upper")[0].clientHeight -
        doc.getElementsByClassName("mobileSideBar_header")[0].clientHeight -
        70;
      setHeightOfMain(height);
    }
  }

  useEffect(() => {
    if(show) {
      getMenuItems();
      getHeightWidth();
    }
  }, [show]);

  useEffect(() => {
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener('keyboardWillShow', info => {
        // console.log('keyboard will show with height:', info.keyboardHeight);

      });

      Keyboard.addListener('keyboardDidShow', info => {
        getHeightWidth();
      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide');
        getHeightWidth();
      });
    }

  }, []);

  

  // useEffect(() => {
  //   console.log(show);
  //   window.addEventListener('orientationchange', () => {
  //     console.log(show);
  //     // getHeightWidth()
  //     console.log("orientation changed!");
  //   })
  // },[])


  const getHeightWidth = () => {
    let doc: any = document;
      let div:any=document.querySelector('#main') ;
      let divsidebar:any=document.querySelector('.mobileSideBar_main') ;
      console.log(divsidebar);
      
      let divpaddingSidebar:any=parseInt(window.getComputedStyle(divsidebar, '').getPropertyValue('padding-top'));
      let divbottomSidebar:any=parseInt(window.getComputedStyle(divsidebar, '').getPropertyValue('padding-bottom'));
      let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
      let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
      let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
      let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
      let height = 0;
      let width=0;
      let mainheight=0;
      if (
        doc.getElementsByClassName("mobileSideBar_upper")[0] &&
        doc.getElementsByClassName("mobileSideBar_header")[0]
      ) {
        // height =
        //   doc.getElementsByClassName("mobileSideBar_upper")[0].clientHeight -
        //   doc.getElementsByClassName("mobileSideBar_header")[0].clientHeight -
        //   50;
        setPaddingValTop(divpaddingTop);
        setPaddingValBottom(divpaddingbottom);
        debugger;
        console.log("MenuHeader"+doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight);
        console.log("Menufooter"+doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight);
        width=window.innerWidth-(divpaddingright+divpaddingleft);
        mainheight=doc.getElementById("main").offsetHeight-(divpaddingTop+divpaddingbottom)-doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight;
        //  mainheight=doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight+doc.getElementsByClassName("mobileSideBar_main")[0].offsetHeight
        height=window.innerHeight-(doc.getElementsByClassName("mobileSideBar_header")[0].offsetHeight+divpaddingSidebar+divbottomSidebar+doc.getElementsByClassName("mobileSideBar_footer")[0].offsetHeight)-(divpaddingTop+divpaddingbottom)
        setHeightOfMain(height);
        setMainHeight(mainheight);
        setWidthofMain(width);
      }
  }


  if (show) {
    return (
      
      <div className="mobileSideBar" style={{width:widthOfMain,top:paddingValTop,bottom:paddingValBottom,height:MainDivHeight}}>
       
        <div className="mobileSideBar_upper">
          <div
            className="mobileSideBar_header"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row--one">
              <div className="col-1 content__header__bars" onClick={()=>{hideSideBar()}}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </div>
            <div className="row--five d-flex" onClick={()=>{ dispatch(setMobileProfileSidebarView(true))}}>
              <div className="avatar-div">
                <Avatar {...stringAvatar(userName)} />
              </div>
              <div className="user-name">
                <h3 className="font-lg">
                  <b>{userName}</b>
                </h3>
                <h4 className="font-md">{getFormatedPhoneNumber(userPhno)}</h4>
              </div>
            </div>
          </div>
          <div
            className="mobileSideBar_main"
            style={{ height: heightOfMain + "px" ,overflow:'auto' }}
          >
            <div className="mobileSideBar_main_links">
              <ul className="mob-nav">
              {/* <li className="d-flex" key={1000}>
                    <Link 
                           onClick={()=>{hideSideBar()}}
                            to={"/Home"}>
<HomeIcon fontSize="medium" className={"mobileSideBarIcons homeIcon"}/>

                    <h4 className="">
                      <b>{t('Home')}</b>
                    </h4>
                              
                      </Link> 
                   
                  </li> */}
                {menuItems.map((x: any) => (
                  
                  x.Display_Nm!="Forms" && x.Display_Nm!= "Dynamic Template"?
                  <li className="d-flex" key={x.PermissionsId}>
                      
                    <Link 
                           onClick={()=>{hideSideBar();  dispatch(setTitle(x.Display_Nm == 'Templates'?"Pre-Approved Messages":x.Display_Nm)); dispatch(setPage("/"+ x.Display_Nm.replace(/ /g,"")))}}
                            to={"/"+ x.Display_Nm.replace(/ /g,"")}>

<i
                      className={"mobileSideBarIcons " + x.ClassNameForUI}
                    ></i>

                    <h4 className="">
                    <b>{t(x.Display_Nm == 'Templates'?"Pre-Approved Messages":x.Display_Nm)}</b>
                    </h4>
                              
                      </Link> 
                   
                  </li>:null
                
                  
                ))}
              </ul>
            </div>
            <div className="mobileSideBar_main_logout " onClick={()=>{setShowLogoutModal(true)}}>
              <div className="d-flex">
                <i className="mobileSideBarIcons">
                  <img src={config.REACT_APP_LOGOUT_LOGO ? config.REACT_APP_LOGOUT_LOGO : logout} alt="Logout" /> 
                </i>
                <h4>
                  <b>{t('Logout')}</b>
                </h4>
              </div>         
              <p className="app-version mt-1 ms-4">
                <small>
                  {process.env.REACT_APP_VERSION}
                </small>
              </p>
            </div>
          </div>
        </div>
  
        {/* <div className="mobileSideBar_footer">
          <footer className="mob-footer col-4">
            <img src={poweredByP360} alt="" />
          </footer>
        </div> */}
        {/* <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        Logout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure want to Logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>handleLogoutPopup(true)}>
            Yes
          </Button>
          <Button onClick={()=>handleLogoutPopup(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog> */}
     <LogoutModal
      show={showLogoutModal}
      yorn={handleLogoutPopup}
      modalText={t('Are you sure you want to Logout ?')}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowLogoutModal(false)
      }} />
      </div>
      
    );
  } else {
    return null;
  }
}

export default MobileSideBar;
