import React, { useEffect, useState } from 'react';
import MainRoutes from './Routes'
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import './App.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from './env.json';
import { useSQLite } from 'react-sqlite-hook';
export let sqlite: any;
export let existingConn: any;

type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  document.documentElement.style.setProperty('--primary-color', `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_COLOR}`);
  document.documentElement.style.setProperty('--secondary-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_COLOR}`);
  document.documentElement.style.setProperty('--tertiary-color', `${config.REACT_APP_MAIN_APP_CSS_TERTIARY_COLOR}`);
  document.documentElement.style.setProperty('--primary-text-color', `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--secondary-text-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--placeholder-text-color', `${config.REACT_APP_MAIN_APP_CSS_PLACEHOLDER_TEXT_COLOR}`);
  document.documentElement.style.setProperty('--title-text-color', `${config.REACT_APP_MAIN_APP_CSS_TITLE_TEXT_COLOR}`);
 // document.documentElement.style.setProperty('--AUTH-TYPE', `${config.REACT_APP_AUTH_TYPE}`);
  const token = useSelector((state: any) => state.login.token);
  const skipHeader = useSelector((state: any) => state.login.skipHeader);
  axios.interceptors.request.use((request: any) => {
    console.log(token);
    //debugger
    console.log("request", request)
    // Edit request config
    console.log("skipHeader", skipHeader)
    if (token && !skipHeader) {
      if (token.length > 0)
        request.headers.common.Authorization = "Bearer " + sessionStorage.getItem('login');
    }

    return request;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });
  if(config.REACT_APP_IS_CROSS_PLATFORM) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [existConn, setExistConn] = useState(false);
    existingConn = {existConn: existConn, setExistConn: setExistConn};
    const {echo, isConnection, getPlatform, createConnection, closeConnection,
          retrieveConnection, retrieveAllConnections, closeAllConnections,
          addUpgradeStatement, importFromJson, isJsonValid, copyFromAssets,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          isAvailable} = useSQLite();
    sqlite = {echo: echo, isConnection: isConnection, getPlatform: getPlatform,
              createConnection: createConnection,
              closeConnection: closeConnection,
              retrieveConnection: retrieveConnection,
              retrieveAllConnections: retrieveAllConnections,
              closeAllConnections: closeAllConnections,
              addUpgradeStatement: addUpgradeStatement,
              importFromJson: importFromJson,
              isJsonValid: isJsonValid,
              copyFromAssets: copyFromAssets,
              isAvailable:isAvailable};
  }
  useEffect(() => {

  }, []);
  return (
    <>
      <MsalProvider instance={pca}>
        <MainRoutes />
      </MsalProvider>
    </>
  );
}

export default App;
