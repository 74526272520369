import React, { useEffect, useState } from 'react'
import Avatar from "@mui/material/Avatar";
import './Video.css';
import VideoModal from '../../components/video/VideoModal';
import Tooltip from '@mui/material/Tooltip';

import {getAllVideoHistory} from '../../apis/video/video'
import moment from 'moment';
import {formatDateIntoTodayYesterdayFormat} from '../../utils/Date'
import VideoFloater from '../../components/video/videoFloater/VideoFloater';
import {setVideoCallDetails, setVideoCallShowFloater,setVideoCallRoom,setVideoCallCallStarted,setVideoCallEnded,setVideoCallChats} from '../../app/slice/videoCallSlice';
import { useSelector, useDispatch } from 'react-redux'
import config from '../../env.json'
import VideoCall from '../../components/video/videoCall/VideoCall';
import axios from 'axios';
import {connect, LocalDataTrack} from 'twilio-video';
import { Videocam } from '@mui/icons-material';
import Tab from "@mui/material/Tab";
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';


import { useTranslation } from "react-i18next";
import Button from '../../layouts/button/Button'
function stringAvatar(name: string) {
  let tempname:any=name.split(' ');
  let shortName=tempname[0][0];
  if(tempname[1])
  shortName+=tempname[1][0];
  return {
    sx: {
      bgcolor: "#FAF8FF",
      color: document.documentElement.style.getPropertyValue('--primary-color'),
      fontSize: ".8rem",
      fontWeight: "bold",
      width: "35px",
      height: "35px",
      border: "1px solid #734BD14D",
    },
    children:shortName,
    //children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}



export default function Video() {
  const dispatch=useDispatch();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const videoCallCallStarted=useSelector((state: any) => state.floaterVideoCall.videoCallCallStarted)
  const [histData,setHistData] =useState([]);
  const [page,setPage]=useState();
  const [pageSize,setPageSize]=useState();
  const [ongoing,setOngoing]=useState(false);
  const [infiniteScrollHeight,setInfiniteScrollHeight]=useState(0);
  const [paddingValBottom,setPaddingValBottom]=useState(0);
 const [paddingValRight,setPaddingValRight]=useState(0);

  // const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  const getData=()=>{
    debugger;
    console.log("eroor")
    getAllVideoHistory(
      // {page:this.state.page,pageSize:this.state.pageSize,search:this.state.search
      //     cancelToken:new CancelToken((c) => {
      //     //cancel = c;
      //   })
      //  },
      (res:any)=>{
            // let temp=this.state.contactList;
            // if(this.state.page===0)
            // temp=res;
            // else
            // temp=temp.concat(res);
            // let hasMore=this.state.hasMore;
            // if(res.length===0 ||  res.length < 25 )
            // hasMore=false
            // else
            // hasMore=true
            // this.setState({
            //   contactList:temp,
            //   hasMore:hasMore,
            //   loading:false
            // })
  debugger;
            setHistData(res.reverse())
            console.log('gc data is here',res);
       })
  }
 
  const openInNewTab = async(item:any) => {
 
    let temp=item.WebHookUrl.split('?')[1].split('&');
    let randomToken=temp[0].split("=")[1];
    let name=temp[1].split("=")[1];
    let otp=temp[2].split("=")[1];

    debugger
    // dispatch(setVideoCallDetails({
    //   videoCallName:item.RoomName,
    // videoCallToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzEzNWFmMzEwMTQ1ZjFkYmZiMTI5NjEwOWUwZTUwYmVlLTE2NjEyNzYwOTAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJwYXJhZyIsInZpZGVvIjp7fSwiZGF0YV9zeW5jIjp7InNlcnZpY2Vfc2lkIjoiSVNiOTllOTc3MGFlNGUxOTJhOTI2M2RmZmVjNjZmZDBmNSJ9fSwiaWF0IjoxNjYxMjc2MDkwLCJleHAiOjE2NjEyNzk2OTAsImlzcyI6IlNLMTM1YWYzMTAxNDVmMWRiZmIxMjk2MTA5ZTBlNTBiZWUiLCJzdWIiOiJBQzE5YTljNzRjZGQ5YTNmMzRkNDQ4YjI4Y2IyZTFjYjE0In0.KLzFwRJUjp71jziX9cQId3yHdi__RvHbXv4LjcRl4QY",
    // videoCallId:item.RoomId,
    // videoCallUrl:item.WebHookUrl,
    // }))
    // // window.open(url, '_blank', 'noopener,noreferrer');
    // dispatch(setVideoCallShowFloater(true))
   
    axios.get(config.REACT_APP_VIDEO_API_BASE+'/meeting/GetAuthTokenFromZing',{params:{
      RandomToken:randomToken ,
      OTP: otp,
      RoomId:item.RoomId,
      UserName:name
    }}).then((res)=>
    {
      console.log(res)
      getChat(item.RoomId)
      dispatch(setVideoCallDetails({
        videoCallName:item.RoomName,
      videoCallToken:res.data.data,
      videoCallId:item.RoomId,
      videoCallUrl:item.WebHookUrl,
      }))
      connect(res.data.data, {
        name:item.RoomId,
        audio:true,
        video:true,
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
        dominantSpeaker: true,
        
      }).then((room:any) => {
        room.localParticipant.setNetworkQualityConfiguration({
          local: 2,
          remote: 1
        });
        room.localParticipant.publishTrack(new LocalDataTrack());
        dispatch(setVideoCallShowFloater(false))
        dispatch(setVideoCallRoom(room));
        dispatch(setVideoCallEnded(false))
        dispatch(setVideoCallCallStarted(true))
        setOngoing(true)
        
      })
        // setRoom(room);
        // dispatch(setVideoCallRoom(room));
      // window.open(url, '_blank', 'noopener,noreferrer');
     // dispatch(setVideoCallShowFloater(true))
      
    })

    // dispatch(setVideoCallDetails({
    //   videoCallName:item.RoomName,
    // videoCallToken:'',
    // videoCallId:"",
    // videoCallUrl:"",
    // }))
    // window.open(url, '_blank', 'noopener,noreferrer');
  };

  const getChat=(roomId)=>{

    axios.get(config.REACT_APP_VIDEO_API_BASE+"/meeting/GetChat?RoomId="+roomId).
    then(res=>{
     console.log(res)
     debugger
     if(typeof res.data!='string')
     dispatch(setVideoCallChats(res.data.data))
   }).catch(err=>{console.log(err)})
 }
 const getHeight=()=>{
  let viewportHeight = window.innerHeight;
  let doc:any=document;
  if(doc.getElementsByClassName('videoListScroll')[0])
  return (viewportHeight - doc.getElementsByClassName('videoListScroll')[0].offsetTop - 5) ;
  else return "100%"
}
  useEffect(() => {
  
    document.documentElement.style.setProperty('--visibility', 'hidden');
    document.getElementById("page-name")?.setAttribute('data-content',t('Video'));
    getData();

    let doc:any=document
    let div:any=document.querySelector('#main') 
    console.log("gc MobHeader"+doc.getElementById('MobHeader').offsetHeight);
    console.log("gc Header"+doc.getElementById('headerVal').offsetHeight);
    // console.log("search"+doc.getElementById('search').offsetHeight);
    console.log("gc footer"+doc.getElementById('footer').offsetHeight);     
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    setPaddingValBottom(divpaddingbottom);
     setPaddingValRight(divpaddingright);
    setInfiniteScrollHeight(window.innerHeight-(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom);




  }, [])

  const handleNewVideo=()=>{
  setModalShow(true);
  }

  useEffect(()=>{
    if(videoCallCallStarted){
      setOngoing(true)
    }
    else{
      setOngoing(false) 
    }
  },[videoCallCallStarted])
  const getaddChange=(timestring1)=>{
    var timestring2 = moment();
    //  timestring1 = moment("2022-08-18T08:30:00Z");
    var startdate = moment(timestring1);
    // var startdate = moment(timestring1);
    var expected_end = moment(timestring2).subtract(2, 'hours');
    var returned_end = moment(startdate).add(2, 'hours');  
    returned_end.isSameOrAfter(expected_end) 

    console.log(timestring1);
    console.log(timestring2);
    console.log(returned_end.isSameOrAfter(expected_end))

    if (!returned_end.isSameOrAfter(expected_end)) {
      return (false);
    } else {
      return (true);
    }

    // return (false)

  }
   
  // var timestring1 = "2022-17-08T00:00:00Z";
  // var timestring2 = "2022-17-08T02:00:00Z";
  // var startdate = moment(timestring1);
  // var expected_enddate = moment(timestring2);
  // var returned_endate = moment(startdate).add(2, 'hours');  
  // returned_endate.isSame(expected_enddate) 


  return (
    <div className="calllog-container videoContainer">
    <div className="calllog-header  videoHeader notMobile">
   
    
      <div>
        <button className={+!ongoing?"activeMeeting meetingHeading":'meetingHeading'}  onClick={()=>{setOngoing(false)}}>{t('Meetings')}</button>&nbsp;&nbsp;
        {
          videoCallCallStarted?<button  className={ongoing?"activeMeeting meetingHeading ":'meetingHeading'} onClick={()=>{setOngoing(true)}}>{t('Ongoing call')}</button>:null
        }
        
        </div>   
      <div className="video-btn" onClick={handleNewVideo}>
        <i className="material-icons i-2p5">{t('video_call')}</i>
        <h4 className="font-video-meeting">{t('New Video Meeting')}</h4>
      </div>
    </div>
    {
      ongoing?

      <VideoCall />:
      <div className="videolist-body">
      <div className="videolist-title call-log-sub-header notMobile">
        <div className="video-call-log-list-item">{t('Name')}</div>
        <div className="video-call-log-list-item">{t('Date')}</div>
        <div className="video-call-log-list-item">{t('Time')}</div>
        <div className="video-call-log-list-item">{t('Action')}</div>
      </div>

      <div className='videoListScroll' style={{height:infiniteScrollHeight}}>

      
      {/* Desktop */}
      <ul className="notMobile">
{/*         
          return ( */}
          
          {
            histData.map((item:any)=>{
              return(
                
            <li>
              <div className="calllogListItem-div">
                <div className="avatar-div video-calllogListItem-content">
                  <Avatar {...stringAvatar(item.otp.length>0?item.otp[0].UserName:"UN")} />

                  <div
                    className="video-calllogListItem-content"
                    style={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                    }}
                  >
                   {/* Jack Brown  */}
                   {item.otp.length>0?item.otp[0].UserName:"UN"}
                  </div>
                </div>

                <div
                  className="video-calllogListItem-content"
                  style={{ fontSize: "medium" }}
                >                
                   {t(formatDateIntoTodayYesterdayFormat(item['CreatedDate']))}
                </div>
                <div
                  className="video-calllogListItem-content"
                  style={{ fontVariant: "small-caps", fontSize: "medium" }}
                >
                {moment(item['CreatedDate']).format("hh:mm")}
                </div>
                {/* <div
                  className="video-calllogListItem-content"
                  style={{ fontVariant: "small-caps", fontSize: "medium" }}
                > */}
                 <button id="v-join" type='button' className="video-join" onClick={() => openInNewTab(item)}>{t('Start')} </button>
                {/* </div> */}
                {/* <div  style={{fontVariant:"small-caps",fontSize:"medium",paddingRight:"1rem"}}>{item.Contact}</div> */}
                
                 {/* <button id="v-join" className="video-join" onClick={() => openInNewTab(item.WebHookUrl)}> Join </button> */}
                {/* {
                  getaddChange(item.CreatedDate)?
                   <button id="v-join" className="video-join" onClick={() => openInNewTab(item)}> Start </button>
                   : <button id="v-join" className="video-join disableVideoJoin" disabled> Start </button>
                } */}
              </div>
            </li>
            )
          })
        }
          {/* ); */}
       
      </ul>
      {/* Mobile view */}
      <ul>
        {/* {CalllogList.map((item) => { */}
          {/* return ( */}
          {
            histData.map((item:any)=>{
              return(
                <li >
                    <div className="contacttListItem-div contacttListItem-divMobile">
                      <div className="avatar-div VideoName">
                      <div className='videoNameDiv'>
                        <Avatar {...stringAvatar(item.otp.length>0?item.otp[0].UserName:"UN")} />
                        <div>
                        <div
                          style={{
                            fontSize: "medium",
                            fontWeight: "bold",
                            paddingLeft: "1rem",
                          }}
                        >
                           {item.otp.length>0?item.otp[0].UserName:"UN"}
                          {/* {item['UserName']} */}
                        </div>
                        <div
                      className='DateTime'
                        style={{
                          // fontVariant: "small-caps",
                          fontSize: "medium",
                          paddingLeft: "1rem",
                        }}
                      >
                          {t(formatDateIntoTodayYesterdayFormat(item['CreatedDate']))+" "}
                          {moment(item['CreatedDate']).format("hh:mm")}
                         
                      </div>
                      </div>
                      </div>
                       
                      <button id="v-join" type='button' className="video-join" onClick={() => openInNewTab(item)}> {t('Start')} </button>
                      {/* <button id="v-join" className="video-join" onClick={() => openInNewTab(item.WebHookUrl)}> Join </button> */}
                      {/* {
                         getaddChange(item.CreatedDate)?
                         <button id="v-join" className="video-join" onClick={() => openInNewTab(item)}> Start </button>
                        : <button id="v-join" className="video-join disableVideoJoin" disabled > Start </button>
                      } */}
                      
                        </div>
                        <div className='onlyMobile eachContactsIcons'>
                      {/* <i className="material-icons i-2p5">chat</i>
      <i className="material-icons i-2p5">phone</i> */}
                      </div>
        
                    </div>
          </li>
           )
          })
        }
          {/* ); */}
        {/* })} */}
      </ul>
      <div className="floating onlyMobile" style={{paddingBottom:paddingValBottom,paddingRight:paddingValRight}}> 
      <div className="video-btn" onClick={handleNewVideo}>
        <i className="material-icons i-2p5" style={{fontSize:"2rem"}}>{t('video_call')}</i>
        <h4 className="font-video-meeting">{t('New Video Meeting')}</h4>
      </div>
         </div>
    </div>
    </div>
    }
    
    <VideoModal  show={modalShow}
            handleCloseParent={(e)=>{if(e){getData()} setModalShow(false) }}/>
  </div>
  
  )
}
