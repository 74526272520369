import  { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from 'usehooks-ts'
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import DropdownInupt from "../shared/Dropdown";
import config from "../../env.json";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import {getFormatedPhoneNumber} from '../../utils/phoneNumberUtil';
import {sendMessageApi} from '../../apis/chat/messageApi'
import { parsePhoneNumber } from "react-phone-number-input";
import { Tooltip } from "@mui/material";
import {addNewContact, editContact} from '../../apis/contacts/contactsApi'
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import {setShowSuccessSnackBar, setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import "./NewChatModal.css";
import GenericButton from "../../layouts/button/Button";
import { setChatDetails,setRefreshChatComponent,setRefreshChatList,setChatType} from "../../app/slice/chatSlice";
import { useTranslation } from "react-i18next";
import { escapeSelector } from "jquery";
import { Console } from "console";


const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
console.log("gaurav test color",document.documentElement.style.getPropertyValue('--primary-color'));
let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function NewChatModal({ show, handleCloseParent }) {
  const dispatch=useDispatch()
  const messageChannelType=useSelector((state:any)=>state.authApi.EmployeeMsgorWhatsAppChannelType)
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: '' });
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(true);

  const [templateValue, setTemplateValue] = useState<any>({});
  const [campaignId,setCampaignId]=useState(0)
  const [templateId,setTemplateId]=useState(0);
  const [templateText,setTemplateText]=useState('')
const [isSMS,setIsSms]=useState(false);
const [disableCampaignInput,setDisableCampaignInput]=useState(true)
const [disableWaveInput,setDisableWaveInput]=useState(true)
const [disableSMSInput,setDisableSMSInput]=useState(true)
  const [pageSize,setPageSize]=useState(10);
  const [page,setPage]=useState(0);
  const [campaign,setCampaign]=useState([]);
  const [activeCampaign,setActiveCampagign]=useState<any>(null);//userId
  const [template,setTemplate]=useState<any>([])
  const [othersData,setOthersData]=useState([])
  const [othersDatahidden,setOthersDataHidden]=useState(true)
  const [selectedOthers,setSelecteedOthers]=useState<any>({})
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [assets,setAssets]=useState([])
  const [disableBrandInput,setDisableBrandInput]=useState(true)
const [phoneError,setPhoneError]=useState(false);
const [loading,setLoading]=useState(false);
const [refreshTemplate,setRefreshTemplate]=useState(true);
const [associated,setAssociated]=useState<any>({})
const [isForm,setIsForm]=useState(false)
const [formQuestion,SetFromQuestion]=useState("");
const [forms,setForms]=useState([])
const [selectedForm,setSelectForm]=useState<any>({})
const [optedOut,setOptedOut]=useState(false);
const [formsValues,setFormValues]=useState<any>([])
const [allCampaign,setAllCampaign]=useState<any>([]);
const [allWaves,setAllWaves]=useState<any>([]);
const refForm=useRef<any>(null);
const [activeWave,setActiveWave]=useState<any>(null);
const [activeProduct,setActiveProduct]=useState<any>(null);
const [disableTemplate,setDisableTemplate]=useState<any>(true)
const [initialPhone,setInitialPhone]=useState<any>(true);
const [showOnce,setShowOnce]=useState(false);
const [OthersPrescriberId,setOthersPrescriberId]=useState(0);
// const [prescriberName,setPrescriberName]=useState<any>('')

 
  // const {
  //   data: post,
  //   isFetching,
  //   isSuccess,
  // } = useGetPrescribersQuery({
  //   page: 0,
  //   pageSize: 5,
  //   search: assoPrescName,
  // });
  // const pullTemplateData = (data) => {
  //    console.log(data.length); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  //   // { ? : ''}
  //   if (data.length > 0) {
  //     setTemplateValue(true);
  //   } else {
  //     return null;
  //   }
  // };

  useEffect(() => {
    console.log(axios.CancelToken.source());
    getTemplatesFromCampign()
   // getFormsData(1001)
  }, []);

  const isReadOnly = true;

  const getContactsData = (value) => {
     
  
    if (value.length >= 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?IsPrescriberOnly=false&Page=" +
            0 +
            "&PageSize="+pageSize+"&Search=" +
          encodeURIComponent(value),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
         // setOthersPrescriberId(res.data.data.Id)
          setPost(res.data.data.filter((x:any)=>x.Is_Prescriber=="Y"));
          
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };
  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () {}, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);
    
    
  }, [debouncedValue])
  const setPrescriber = (x:any) => {
 
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    brandInputdisable(x.AcctFull_Nm.length >0,(x.AcctPh_Nbr.length >0),true)    
    setOptedOut(x.Opt_In=="N");
 
    
    
    
  };
const getNumberFromItem=(item)=>{
  if(item.AcctPh_Nbr){
    if(item.AcctPh_Nbr.indexOf("+")>=0){
      return item.AcctPh_Nbr
    }else
    return '+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr
  }
  else return ""
}
  async function addContact() {
    // const { data: post, isFetching, isSuccess } =await useAddContactQuery({
    //   AcctPh_Nbr:othersPhone,
    //   Prescriber_Id:x.Id,
    //   AcctFull_Nm:othersName,

    //     })

    //     if(isSuccess){
    //       alert('succee')
    //       ;
    //     }
    //     handleCloseParent()

    let payload = {
      AcctPh_Nbr: othersPhone,
      Prescriber_Id: assoPresc.Id as any,
      AcctFull_Nm: othersName,
      IsPrescriber: false,
    };
    console.log(payload);
    axios
      .post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact",
        {
          AcctPh_Nbr: othersPhone,
          Prescriber_Id: assoPresc.Id as any,
          AcctFull_Nm: othersName,
          IsPrescriber: false,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        handleCloseParent(true);
      })
      .catch((err) => {
        handleCloseParent(false);
      });
  }
  const sendMessageForPresOrOthers=(formData)=>{
    debugger
    if(formType){
      //prescriber

     if(getNumberFromItem(assoPresc)!==othersPhone){
      let phn:any=parsePhoneNumber(othersPhone)
      let payload:any={
        AcctPh_Nbr:phn.nationalNumber,
        IntlCallPfx_Nbr:phn.countryCallingCode,
        Id:assoPresc.Id,
      }
      editContact(payload,(res)=>{
      })
     }
      formData.append("role_id", '0');
      formData.append("recipient_id",assoPresc.Id );
      formData.append("recipient_name",assoPresc['AcctFull_Nm']);
      formData.append("physcian_id",assoPresc.Id);
      sendMessageApi(formData,(res)=>{
      //console.log(res)
      dispatch(setChatType("PRESCR"))
      dispatch(setChatDetails(
        {
          chatId:assoPresc.Id,
          chatIsPrescriber:true,
          showChat:true,
          chatName:assoPresc['AcctFull_Nm']
        }))
        
        setLoading(false);
        reSetFormInputs(true);
        handleCloseParent(true);
        dispatch(setRefreshChatList(true))
        dispatch(setRefreshChatComponent(true))
     })
    
     }
     else{
      //others
      if(!selectedOthers.Id){
        let phn:any=parsePhoneNumber(othersPhone);
        let payload={
          AcctPh_Nbr:phn.nationalNumber,
          Prescriber_Id:[associated.Id],
          AcctFull_Nm:othersName,
          IntlCallPfx_Nbr:phn.countryCallingCode
        }
        addNewContact(payload,(res:any)=>{
          if(res){

     
      formData.append("recipient_id",res.Id);
      formData.append("recipient_name",othersName);
      formData.append("role_id", '1');
      formData.append('association_id',res.Prescribers.filter(x=>x.Id==associated.Id)[0].Association_Id)
      formData.append("physcian_id",associated.Id);
      sendMessageApi(formData,(resp:any)=>{
        //console.log(res)
        if(resp){
          dispatch(setChatType("ASSPRS"))
          debugger
          dispatch(setChatDetails(
            {
              chatId:res.Id,
              chatIsPrescriber:false,
              showChat:true,
              chatName:othersName
            }))
            setLoading(false);
            reSetFormInputs(true);
            handleCloseParent(true);
            dispatch(setRefreshChatList(true))
          dispatch(setRefreshChatComponent(true))
        }
       })
          }
          else{
            
          }
        })
      }else{
        debugger
        console.log(othersData);
        let phn:any=parsePhoneNumber(othersPhone);
          console.log(phn)
          let payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:[associated.Id],
            Id:othersData.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode
          }  
          // let payload:any={
          //   AcctPh_Nbr:phn.nationalNumber,
          //   IntlCallPfx_Nbr:phn.countryCallingCode,
          //   Id:contact.Id,
          // }
        formData.append("recipient_id",selectedOthers.Id);
        formData.append("recipient_name",othersName);
         formData.append("role_id", '1');
         formData.append('association_id',associated.Association_Id)
         formData.append("physcian_id",associated.Id);

        if(othersPhone!=selectedOthers.AcctPh_Nbr)
        {
          addNewContact(payload,async (cont)=>{
            if(cont)
            {
              dispatch(setShowSuccessSnackBar(true));
            }
            else
            {
              dispatch(setShowErrorSnackBar(true));
            }
          })
        }

         sendMessageApi(formData,(res)=>{
          //console.log(res)
          dispatch(setChatType("ASSPRS"))
          dispatch(setChatDetails(
            {
            chatId:selectedOthers.Id,
            chatIsPrescriber:false,
            showChat:true,
            chatName:othersName
            }))
            setLoading(false);
            reSetFormInputs(true);
            handleCloseParent(true);
            dispatch(setRefreshChatList(true))
          dispatch(setRefreshChatComponent(true))
         })
      }
     
    
     }
  }
  const sendMessage=()=>{
    // let data=messageData[0];
    // console.log(contactData);
    setLoading(true);

let formData = new FormData();

debugger
formData.append("recipient_phone",othersPhone);
formData.append("Campaign_ID", '1000')//templateValue.campaginId.toString());
formData.append('wave_id',templateValue.mainWaveId)//templateValue.waveId.toString())
formData.append('product_id',templateValue.productId)//templateValue.productId.toString())


if (!isSMS) {
  formData.append("channel", "SMS");
 
 } else {
  formData.append("channel", "whatsa");
 }
 debugger



 if(isForm){
  let fdata:any=new FormData(refForm.current)
  let details:any=[];
  for (const [key, value] of fdata) {
    details.push({"idOfFieldToAskFromEmp": JSON.parse(key),"valueOfFieldToAskFromEmp": value})
  }
 
  let formPay={  "formId" : selectedForm.FormId,
  "fieldDetails": details,
  "recipientId" : assoPresc.Id,
  "recipientType" :formType?"PRESCR":'ASSPRS'

}
  console.log(JSON.stringify(formPay))
  axios.post(config.REACT_APP_FORMS_API_BASE+'/formRequest',formPay).then((res:any)=>{
    console.log(res.data)
    let temptest=templateText.toString();
    if(temptest.indexOf('shorturl')>=0)
      formData.append("template",templateText.toString().replace('[shorturl_1]',res.data.FormUrl));
    else
    formData.append("template",templateText+" "+res.data.FormUrl);
    formData.append('urls',res.data.FormUrl)
    sendMessageForPresOrOthers(formData);
  })
  .catch(err=>{
    setLoading(false)
  })
}
else{
  debugger
  formData.append("template_id",templateId.toString());
  formData.append("template",templateText.toString());
  sendMessageForPresOrOthers(formData);
}




//for prescriber


// formData.append("recipient_name", data['Sender Name']);
// formData.append("recipient_phone", data.recipient_phone);
//  formData.append("physcian_id", data.physcian_id);
//  formData.append("role_id", data.type);
//  formData.append("template", data.template);
//  formData.append("Campaign_ID", 1000);
//  formData.append("template_id");
//  formData.append('wave_id',data.)

debugger

// formData.append("file", this.files);
//  for (var i = 0; i < files.length; i++) {
//    let file = files[i];
//    formData.append("file", file);
//  }


   // axios.post(config.REACT_APP_MESSAGE_API_BASE+"/message",formData,{
    //   axios.post("http://localhost:8000/api/message",formData,{
    //     params:{"Authorization":"Bearer "+sessionStorage.getItem('login')}
    // }).then((res)=>{
    //   console.log(res)
    //   setLoading(false);
    //   reSetFormInputs(true);
    //   handleCloseParent(true)
    // }).catch(res=>setLoading(false))
  }
  const setChat=(post:any)=>{
    dispatch(setChatDetails(
      {
        chatId:post.Id,
        chatIsPrescriber:post.Is_Prescriber=="Y",
        showChat:true,
        chatName:post.AcctFull_Nm
      }))
    window.location.href = "/chat";
  }

  const TemplatesForms=[
   
    {

      "formId": 1000,
      "formName": "MIR",
      "fieldName": "Question",
      "fieldType": "string",
      "File_Nm":"MIR Form",
    waveId:'1000',
    form:true,
    productId:'1000',
    campaginId:'1000',
    Template_Val:'As per your request. I have created the MIR form. Please review and submit the form here: [shorturl_1]',
    isForm:true,
    Id:7000
    }
]

//
// useEffect(() => {
//       let p:any=template;
//       p.push(
//         {

//           "formId": 1000,
//           "formName": "MIR",
//           "fieldName": "Question",
//           "fieldType": "string",
//           "File_Nm":"MIR Form",
//         waveId:'1000',
//         form:true,
//         productId:'1000',
//         campaginId:'1000',
//         Template_Val:'As per your request. I have created the MIR form. Please review and submit the form here: [shorturl_1]',
//         isForm:true
//       }
//       );
//       setTemplate(p);
// }, [template])

  const getTemplatesFromCampign = () => {
   
   
    getCampaignsData({page:0,pageSize:10},(res)=>{
     if (res.length>0) {
   
             let tempAssets: any = [];
             let campaigns:any=[];
             let waves:any=[];
             let products:any=[];
 


res.map((x:any) => {

  campaigns.push(x);  
  x.type = "Campaign";
  x.idName = ["type", "CampaignName", "CamapignId"];
  x.labelName = "CampaignName";
  x.childName = "WaveDetails";
  x.WaveDetails.map((y: any) => {
    waves.push(y);
    y.type = "Wave";
    y.idName = ["type", "WaveName", "WaveId"];
    y.labelName = "WaveName";
    y.childName = "ProductDetails";
    //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
    y.ProductDetails.map((z:any)=>{
      z.waveId=y.WaveId;
      products.push(z)
      z.type = "Product";
    z.idName = ["type", "ProductName", "ProductId"];
    z.labelName = "ProductName";
    z.childName = "TemplateDetails";
    z.TemplateDetails= z.TemplateDetails.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
      z.TemplateDetails.map((p:any)=>{
        p.type = "Template";
        p.productId = z.ProductId;
        p.mainWaveId=(p.AssetDetails.length==0)?p.CampaignWaveId:p.AssetDetails[0].CampaignWaveId
        if(p.FormId){
          p.TemplateName=p.FormName
        }
    p.idName = ["type", "TemplateName", "TemplateId"];
    p.labelName = "TemplateName";
    p.childName = "AssetDetails";
        tempAssets=tempAssets.concat(p.AssetDetails)
        p.AssetDetails.map((o:any)=>{
          o.mainItem=true
      o.type= "Asset"
      o.idName= ["type", "AssetFile_Nm", "AssetId"]
      o.labelName="AssetFile_Nm"
          return o;
        })
        return p;

        //tempAssets.push(y.campaignWaveAsset);
      })
      return z;
    })
    return y;
  });
  return x;
});

            setAllCampaign(campaigns)
            let activeC:any=window.localStorage.getItem('activeCampaign')
            if(activeC){
              let index=campaigns.findIndex(x=>x.CamapignId==JSON.parse(activeC).CamapignId)
              if(index>=0){
                activeC=JSON.parse(activeC);
                setActiveCampagign(campaigns[index])
              }
              else{
                activeC=campaigns[0];
                setActiveCampagign(campaigns[0])
              } 
              
            }else{
              activeC=campaigns[0];
              setActiveCampagign(campaigns[0])
            } 

            
           debugger
            let tempWaves=activeC.WaveDetails?activeC.WaveDetails:[];
            setAllWaves(tempWaves)
            
            let activeW:any=window.localStorage.getItem('activeWave')
            debugger
            if(activeW){
              let index=tempWaves.findIndex(x=>x.WaveId==JSON.parse(activeW).WaveId)
              if(index>=0){
                activeW=JSON.parse(activeW);
                setActiveWave(tempWaves[index])
              }
              else{
                activeW=tempWaves[0];
                setActiveWave(tempWaves[0])
              } 
              
            }else{
              activeW=tempWaves[0];
              setActiveWave(tempWaves[0])
            } 


            // let p:any=window.localStorage.getItem('activeWave')
            // setActiveWave(p?JSON.parse(p):null)
            let tempProducts=activeW.ProductDetails?activeW.ProductDetails:[]
            debugger
            setCampaign(tempProducts)//campagins is used as products
            let activep:any=window.localStorage.getItem('activeProduct')

            if(activep){
              let index=tempProducts.findIndex(x=>x.ProductId==JSON.parse(activep).ProductId)
              if(index>=0){
                activep=JSON.parse(activep);
                setActiveProduct(tempProducts[index])
              }
              else{
                activep=tempProducts[0];
                setActiveProduct(tempProducts[0])
              } 
              
            }else{
              activep=tempProducts[0];
              setActiveProduct(tempProducts[0])
            } 


            // let o=window.localStorage.getItem('activeProduct')
            // setActiveProduct(o?JSON.parse(o):null)
            let tempTemplates=activep.TemplateDetails?activep.TemplateDetails:[]
            setTemplate(tempTemplates);
            
           // getFormsData()
          // setTemplate(tempAssets);
          // setAlllProducts(products);
            // setActiveProducts(products)
 
             // setAlllWaves(waves);
             // setActiveWaves(waves);
             // setAssets(tempAssets);
    }
   }
    )
 
  
   };
   const getFormsData=(waveId)=>{
    console.log('hi')
    axios.get('https://p360zingformsapi.azurewebsites.net/api/formslist/'+waveId).then((res)=>{
      console.log(res.data)
      let d=res.data.map((x)=>{
        x['File_Nm']=x.formName+" Form";
        x.waveId='1000';
        x.form=true;
        x.productId='1000';
        x.campaginId='1000';
        x.Template_Val='';
        x.Category_Cd="FRM"

        return x;
      })
      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      let temp=groupBy(d, 'formId')
      let data:any=[];
      for(let i in temp){
        let o:any=temp[i][0];
        o.questions=temp[i];
        data.push(o);
      }
      console.log(data)
      // debugger
      
      
      setForms(d);
      // debugger
    })
   }
  // const getTemplatesFromCampign=(activeCampaign:any)=>{
  //   axios.get(config.REACT_APP_CAMPAIGN_API_BASE+"/campaign/"+activeCampaign+"?Page="+page+"&PageSize="+pageSize,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}}).then((res)=>{
  //     //setAssets(res.data.data);
  //     let tempAssets:any=[];
  //     console.log(res.data.data)
  //     setCampaign(res.data.data)
  //     res.data.data.map(x=>{
  //       x.refCampaignWaves.map((y:any)=>{
  //        tempAssets.push(y.campaignWaveTemplate);
  //        return y;
  //       });
  //       return x;
  //     })
  //     setTemplateId(tempAssets[0].id)
  //     setTemplateText(tempAssets[0].templateVal)
  //     setTemplate(tempAssets);
  //   })
  // }
  const brandInputdisable = (name,phone,asso_pres) =>{
  
    // console.log(assoPrescName === "" && othersPhone === "", assoPrescName, othersPhone)
    if(formType){
      if(name && phone && asso_pres){
        debugger
        setDisableBrandInput(false)
        setDisableCampaignInput(false)
        setDisableWaveInput(false)
        setDisableTemplate(false)
      } else {
        setDisableBrandInput(true)
        setDisableCampaignInput(true)
        setDisableWaveInput(true)
        setDisableTemplate(true)
      } 
    }
    else{
      setDisableBrandInput(false)
      setDisableCampaignInput(false)
      setDisableWaveInput(false)
      setDisableTemplate(true)
    }       
  }
 
const setPhoneNumber =(e) =>{
  setInitialPhone(false)
  if(e){
    setOthersPhone(e);
    brandInputdisable((assoPrescName.length > 0),(e.length >0),(assoPrescName.length > 0));
  } else {
    setOthersPhone("");
    brandInputdisable((assoPrescName.length > 0),false,(assoPrescName.length >0));
  }
  
}
// const smsInputdisable = (name,phone,asso_pres,brand) =>{
//   if(name && phone && asso_pres && brand){
//     debugger
//     setDisableSmsInput(true)
//   } else {
//     debugger
//     setDisableSmsInput(false)
//   } 
// }
  const setOthersFromList=(x)=>{
    debugger;
                          setShowOnce(false);
                          setOptedOut(x.Opt_In=="N");
                          setSelecteedOthers(x);
                            setOthersName(x.AcctFull_Nm);
                            
                            //setOthersPhone(x.AcctPh_Nbr);
                            setOthersDataHidden(true);
                            if(x.Prescribers){
                              debugger
                              if(x.Prescribers.length==1){
                                 
                                 setAssociated(x.Prescribers[0]);
                                 setPrescriberName(x.Prescribers[0].AcctFull_Nm);
                                 setAssoPrescName(x.Prescribers[0].AcctFull_Nm);
                                 setDisableTemplate(x.AcctPh_Nbr.length<=0)
                              }
                              else{
                                brandInputdisable(true,(x.AcctPh_Nbr.length >0), x.Prescribers.length >0)
                              }
                              // setPrescriber(x.Prescribers);
                            }
                            
                            setOthersPhone(getNumberFromItem(x));
                            
                            
  }
 const reSetFormInputs = (e)=>{
  setSelecteedOthers({});
  setInitialPhone(true)
  // console.log(e)
  setFormType(e)
  // if(e === true){
    // setOthersPhone("");
    // setTemplateText("")
    // setAssoPrescName("");
  // }else{
    setTemplateId(0)
    setAssoPrescName("");
    setAssoPresc({Id: ''});
    setHiddenPresList(true);
    setDisabled(true);
    setOthersPhone("");
    setOthersPhone("9999999");
    setOthersPhone("");
    setDisableBrandInput(true)
    // setTemplate([]);
    setTemplateText("");
    setOthersName("");
    setDisableCampaignInput(true)
    setDisableWaveInput(true)
    setDisableTemplate(true)
  //  setAssoPrescName("");
   setAssets([]);
   setAssociated({})
   setOptedOut(false);
  // }
 }
  const selectbrand=(e)=>{
    console.log('inside brand')
    window.localStorage.setItem('activeProduct',JSON.stringify(e))
    setTemplate(e.TemplateDetails?e.TemplateDetails:[])
    setDisableTemplate(false)
    // if(e.TemplateDetails.length==1){
    //   setDisableTemplate(false)
    // }
    // else{
    //   setDisableTemplate(true)
    // }
    setActiveProduct(e)
    setTemplateText("");
    setTemplateId(0)
    setAssets([]);
    setIsForm(false)

    //  getFormsData(1001)
  }
 
  const selectWave=(e)=>{
    setDisableTemplate(true)
    window.localStorage.setItem('activeWave',JSON.stringify(e))
    //setActiveWave(e);
    setActiveWave(e)
    setActiveProduct(null)
    debugger
    setCampaign(e.ProductDetails?e.ProductDetails:[])
    if(e.ProductDetails.length==1){
      selectbrand(e.ProductDetails[0])
    }
    setTemplateText("");
    setTemplateId(0)
    if(disableWaveInput==false)
    {
      if(allWaves.length==1){
        setDisableTemplate(false);
      }
      else
      {
        setDisableBrandInput(false);
        setDisableTemplate(true);
      }
    
    }
    else
    {
      setDisableBrandInput(true);
      setDisableTemplate(false);
    }
  
    setAssets([]);
    setIsForm(false)
  }
  const setPrescriberName =(e) =>{
    // if(e.length > 0){
    //  setDisabled(false)
    // }else 
    if(assoPrescName.length <=0){
      setDisabled(true)
    } 
    else {
      setDisabled(false)
    }
  }
  const selectCampaign=(e)=>{
    setDisableTemplate(true)
    window.localStorage.setItem('activeCampaign',JSON.stringify(e))
    //setActiveCampagign(e)
    setActiveWave(null) 
    setActiveProduct(null)
    setAllWaves(e.WaveDetails?e.WaveDetails:[])
    if(e.WaveDetails.length==1){
      selectWave(e.WaveDetails[0])
    }
    setTemplateText("");
    setTemplateId(0)
    setAssets([]);
    if(disableCampaignInput==false)
  {
    setDisableWaveInput(false);
    setDisableTemplate(true);
    setDisableBrandInput(true);
  }
  else
  {
    setDisableWaveInput(true);
    setDisableTemplate(false);
    setDisableBrandInput(false);
  }
    setIsForm(false)
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        // onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="new_chat_modal"
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="modal-header d-flex">
              <h1 className="font-newchat">{t('Home')}</h1>
              <span onClick={()=>{handleCloseParent();reSetFormInputs(true)}} className="close">
                ×
              </span>
            </div>

            <div className="modal-body new_chat_body">
             <form onSubmit={(e)=>e.preventDefault()}>
             <div className="selecttype-radio-btns">
                <FormControl className="width100per">
                  <div className="newChatModalBody">
                  <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                  {
                        messageChannelType == true?
                  <div className='headerright' >
                    <label className="autopilot-switch font-sm me-3">
                                            
                        <input
                        checked={isSMS}
                            onChange={(e)=>{setIsSms(e.target.checked)}}
                            type="checkbox"
                            className="font-sm"
                            id="msg-wtsappChechedId"
                        />
                     
                        <Tooltip title={isSMS === false? t('Message Mode'): t('WhatsApp Mode')} arrow>
                        <span id="msg-wtsapptoggle" className="b-slider b-round"></span>
                        </Tooltip>
                    </label>
                </div>
                      :null}
                </div>
              
                       
                  <RadioGroup
                    row
                    defaultValue="prescriber"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    onClick={(e: any) => {
                      // if(e.target.value === "prescriber"){
                      //   reSetFormInputs(true)
                      //   if(othersPhone.length === 0){
                      //     setDisabled(false);setPhoneError(false)
                      //   }
                      // } else{
                      //   reSetFormInputs(false)
                      //   if(othersPhone.length === 0){
                      //     setDisabled(false);setPhoneError(false)
                      //   }
                      // }
                      e.target.value === "prescriber" 
                        && (reSetFormInputs(true))
                      }}
                  >
                    <FormControlLabel
                      value="prescriber"
                      control={<BpRadio />}
                      label="Prescriber"
                    />
                    <FormControlLabel
                      value="others"
                      control={<BpRadio disabled = {true} />}
                      label="Others"
                      disabled={true}
                         
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form-fields-div new-chat-prescriber">
                {formType === true ? (
                 
                  <AssociatedPresList clearDataOnSelect={false} placeholder={t("Search for a Prescriber")} label={t("Prescriber Name")} styleClass="inputEnable" onChangeInputValue={(e:any)=>{setPrescriberName(e); setDisabled(true)}} setAssoPresc={(e:any)=>{setPrescriber(e); setDisabled(false)}} />

                ) : (
                  <div className="NewChatModalOthersName">

                  
                  <TextField
                    className="roundborder inputEnable"
                    onChange={(e) => {
                      debugger;
                      setShowOnce(true);
                      if(e.target.value.length==0){
                        setDisabled(true);
                        setSelecteedOthers({});
                        setOthersPhone("");
                      // setAssoPrescName("");
                      // setPrescriberName('')
                        // reSetFormInputs(false)
                        brandInputdisable(othersName.length > 0,othersPhone.length > 0,false)
                       
                      }
                      else if(e.target.value.length > 0){
                        if(assoPrescName.length > 0 && othersPhone.length > 0) {
                          setDisabled(false);
                        }
                        brandInputdisable(othersName.length > 0,othersPhone.length > 0,false)
                      }
                      
                      setOthersName(e.target.value);
                    }}
                    value={othersName}
                    label={t("Recipient Name")}
                    placeholder={t("Enter the recipient's name")}
                    color="secondary"
                    focused
                    autoComplete="off"
                  />
                  {showOnce && othersData.length > 0?
                  <Paper   hidden={othersDatahidden}>
                      

                      <div id="presList" >
                        { othersData.length > 0 && (
                           othersData.map((x: any) => <div key={x.Id} onClick={()=>{setOthersFromList(x)}}>{x.AcctFull_Nm}</div>)
                        ) 
                        }
                      </div>
                    </Paper>:null}
                  {/* <div className="othesNameDiv" hidden={othersDatahidden}>
                    <ul>
                      {
                        othersData.map((x:any)=>(
                          <li key={x.Id} onClick={()=>{
                            setOthersFromList(x);
                            
                          }}>{x.AcctFull_Nm}</li>
                        ))
                      }
                      
                    </ul>
                  </div> */}
                  </div>

                )
                }
                {
                  optedOut?<div className="optedOutModalText">
                    {t("Recipient Opted Out")}
                  </div>:null
                }
                
                <CountryDropdown errorFunc={(e)=>{console.log(e);
                  setDisabled(e);
                  setPhoneError(e);
                  console.log(disabled);
                  debugger
                  if(e)
                  {
                    setDisableTemplate(assoPrescName.length > 0 && true)
                    brandInputdisable((assoPrescName.length > 0),false,assoPrescName.length > 0)
                  }
                  else
                  {
                    debugger;
                    if(formType && assoPrescName.length<=0)
                    {
                    setDisabled(true)
                    }
                    else if(!formType && (assoPrescName.length <=0 || othersName.length <= 0 || othersPhone.length <= 0))
                    {
                    setDisabled(true)
                    }
                  setDisableTemplate(assoPrescName.length > 0 && false)
                  }
                  }}
                  initialYN={initialPhone} 
                  isReadOnly = {isReadOnly}
                  disableColored={disableBrandInput} valueNumber={othersPhone} onChangeNumber={(e:any)=>{setPhoneNumber(e)}}/>
              
                {formType === false ? (
                  <div className="asso-prescriber-div createContactAssociate">
                   <div>
                   {
                      selectedOthers.Id?
                      <div>
                        {selectedOthers.Prescribers.length>1?
                      <DropdownInupt disableColored={false} keepPlaceHolderAtStart={selectedOthers.Prescribers.length>1} placeHolder={t("Select a Prescriber")} label={t("Associated Prescriber")} data={selectedOthers.Prescribers} id="" name="AcctFull_Nm"  func={(e)=>{setAssoPrescName(e.AcctFull_Nm);setAssociated(e); brandInputdisable(othersName.length > 0,othersPhone.length > 0, true);setDisableTemplate(false); (e? setDisabled(false): setDisabled(true))}}/>
                    :
                    <div>
                         <AssociatedPresList SelectedValue={selectedOthers.Prescribers[0].AcctFull_Nm} clearDataOnSelect={false} placeholder={t("Search for an Associated Prescriber")} label={t("Associated Prescriber")} styleClass="inputEnable"  onChangeInputValue={(e:any)=>{setPrescriberName(e);if(e==''){setDisabled(true)}}} setAssoPresc={(e:any)=>{ console.log(e);debugger; brandInputdisable(othersName.length > 0,othersPhone.length > 0,true); setAssoPrescName(e.AcctFull_Nm);setAssociated(e);setDisableTemplate(false); (e? setDisabled(false): setDisabled(true)) }} />
                          {/* {
                            optedOut?<div>
                              {t("Recipient Opted Out")}
                            </div>:null
                          }  */}
                      </div>
                    }
                      </div>
                      :
                      <div>
                         <AssociatedPresList clearDataOnSelect={true} placeholder={t("Search for an Associated Prescriber")} label={t("Associated Prescriber")} styleClass="inputEnable"  onChangeInputValue={(e:any)=>{setPrescriberName(e); setDisabled(true);
                         
                         }} setAssoPresc={(e:any)=>{ if(e)setDisabled(false);
                           brandInputdisable(othersName.length > 0,othersPhone.length > 0,true); setAssoPrescName(e.AcctFull_Nm);setAssociated(e);setDisableTemplate(false); if(othersName.length<=0){ setDisabled(true);
                          } }} />
                          {/* {
                            optedOut?<div>
                              {t("Recipient Opted Out")}
                            </div>:null
                          }  */}
                      </div>
                    }
                   </div>
                    
                    
                  
                  </div>
                ) : null}
                {
                  allCampaign.length>1?
                  <div>
                  <DropdownInupt activeValue={activeCampaign} disableColored={disableCampaignInput} keepPlaceHolderAtStart={false} placeHolder={t("Select a campaign")} label="Campaign" data={allCampaign} id="" name="CampaignName"  func={(e)=>{selectCampaign(e); setDisableTemplate(false)}}/>
                </div>
                :null
                }
                {
                  allWaves.length>1?
                  <div>
                  <DropdownInupt activeValue={activeWave} disableColored={disableWaveInput} keepPlaceHolderAtStart={allWaves.length>1} placeHolder={t("Select a wave")} label="Wave" data={allWaves} id="" name="WaveName"  func={(e)=>{selectWave(e); setDisableTemplate(false)}}/>
                </div>
                :null
                }
               {
                campaign.length>1?
                <div>
                  <DropdownInupt activeValue={activeProduct}  disableColored={disableBrandInput} keepPlaceHolderAtStart={campaign.length>1} placeHolder={t("Select a brand")} label="Brand" data={campaign} id="" name="ProductName"  func={(e)=>{selectbrand(e)}}/>
                </div>:null
               }
                
                <div>
                    <DropdownInupt
                    notdisabledAfter={true}
                    //refreshData={refreshTemplate}
                    key={formType?1:0}
                    label="SMS Template"
                    data={template}
                    keepPlaceHolderAtStart={true} 
                    placeHolder={t("Select a Template")}
                    name="TemplateName"
                    disableColored={disableTemplate}
                    disableLater={false}
                    func={(e)=>{
                      console.log(e)
                      console.log(disabled,e.TemplateId==0,optedOut,assoPrescName)
                      setIsForm(e.FormId!==null);
                      setTemplateText(e.TemplateBody);
                      setTemplateValue(e);
                      setTemplateId(e.TemplateId);
                      setDisabled(false);
                      debugger;
                      if(!formType && (assoPrescName.length <=0 || othersName.length <= 0 || othersPhone.length <= 0)){
                        setDisabled(true)
                      }
                      // else if(othersName.length<=0)
                      // {
                      //   setDisabled(true)
                      // }
                      
                      // if(setAssoPrescName)
                      setAssets(e.AssetDetails?e.AssetDetails:[]);
                      setSelectForm(e)
                      setFormValues(e.FieldDetails)           
                    }}
                  />
                  {/* <DropdownInupt
                    // refreshData={refreshTemplate}
                    label="Forms"
                    data={TemplatesForms}
                    keepPlaceHolderAtStart={true} 
                    placeHolder="Select a Form"
                    name="File_Nm"
                    disableColored={disableBrandInput}
                    func={(e)=>{setIsForm(e.Category_Cd==="FRM");setTemplateText(e.Template_Val);setTemplateValue(e);setTemplateId(e.Id);setAssets(e.Assets?e.Assets:[])}}
                  /> */}
                  {/* {template.length} {templateText.length} */}
                  {templateText.length > 0?
                    
                  <div className="newchat-floating-form" style={{display:"block"}}>
                  <textarea name="sms-textarea" id="newchat_sms" value={templateText} />
                  <p>
                    <em>Characters : {templateText.length}</em>
                  </p>
                </div>
                : null}

                {/* {
                  isForm?
                  <div>
                    {
                      selectedForm.questions?<>
                        {selectedForm.questions.map((x:any)=>(
                          <div className="template_body_form" 
                >
                  <p className="para">{x.fieldName}</p>
                    
                    <textarea name="template body" id="" value={formQuestion} onChange={(e)=>{SetFromQuestion(e.target.value)}} ></textarea>
                  </div>
                        ))}
                      </>:null
                    }
                      
                  </div> 
                 :null
                }  */}
                {
                  isForm?
                  
                  <div className="template_body_form">
                    <form ref={refForm}>
                    {
                      formsValues.map((x:any,i:any)=>(
                        <>
                        <p className="para">{x.FieldName}</p>
                        <textarea name={x.FieldId} id={x.FieldId}  ></textarea>
                        </>
                      ))
                    }
                 </form>
                  </div>
                       
                 :null
                }
               
                
                  {

                    assets.length>0?
                    <div
                    className="template_assets_form"
                  >
                    <ul>
                      {
                        assets.map((x:any)=>(
                          <li> <a href={x.AssetFile_Url}>{x.Asset_Nm}</a> </li>
                        ))
                      }
                     
                    </ul>
                    <label htmlFor="">{t('Assets')}</label>
                  </div>
                    :null
                  }
                  
                </div>

                {/* <br /> */}
                <GenericButton label={t("Send")} 
              disable={disabled || templateId==0 || optedOut} 
                 loading={loading} onClick={() => {
                    sendMessage();
                    handleCloseParent(true);
                  }} />
              
              </div>
             </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
