import React from "react";
import config from "../../env.json";
// import {ReactComponent as Doctor} from "../../assets/images/prescriber_icon.svg";
// import {ReactComponent as  OtherStaff} from "../../assets/images/associated_prescriber_icon.svg";
import  Doctor from "../../assets/images/prescriber_icon.svg";
import   OtherStaff from "../../assets/images/associated_prescriber_icon.svg";
// // import   Unknown from "../../assets/images/Unknown_number_icon.svg";
import './Avatar.css';

export default function Avatar({ imageType, avatarHeight, avatarWidth }) {
  // console.log(imageType)
  return (
    <div>
      {imageType === "prescriberassociates" || imageType === "" || imageType === "undefined" ? 
        (
          <div className="avatar-container other-staff" style={{ height:avatarHeight, width:avatarWidth}}>
            <img src={config.REACT_APP_OTHERS_LOGO ? config.REACT_APP_OTHERS_LOGO : OtherStaff} alt="other-staff-icon" />
            {/* <OtherStaff /> */}
          </div>
        )
      :(
        <div className="avatar-container" style={{ height:avatarHeight, width:avatarWidth}}>
          <img src={config.REACT_APP_HCP_LOGO ? config.REACT_APP_HCP_LOGO : Doctor} alt="doctor-icon" />
          {/* <Doctor /> */}
        </div>
      ) }
    </div>
  );
}

{/* <>
{imageType === "" || imageType === "undefined" ? 
<div className="avatar-container other-staff" style={{ height:avatarHeight, width:avatarWidth}}>
<img src={Unknown} alt="unknown-icon" />
<Unknown />
</div>
:
<div>
  {imageType === "prescriberassociates" ?
  (
    <div className="avatar-container other-staff" style={{ height:avatarHeight, width:avatarWidth}}>
      <img src={OtherStaff} alt="other-staff-icon" />
      <OtherStaff />
    </div>
  )    
  
:(
  <div className="avatar-container" style={{ height:avatarHeight, width:avatarWidth}}>
    <img src={Doctor} alt="doctor-icon" />
    <Doctor />
  </div>
) }
 </div>
}
</> */}


