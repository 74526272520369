import axios from "axios";
import config  from '../../env.json';
function getWavesData(params,callback){
  let {page,pageSize}=params;
    try {
        axios.get(config.REACT_APP_CAMPAIGN_API_BASE+'/wave?Page='+page+'&PageSize='+pageSize,{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
        .then(res=>{ 
          callback(res.data.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
}



export {getWavesData}