import React, { useEffect,useState } from 'react';
import "./Calendar.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import CalendarComponent from '../../components/tabs/CalendarComponent';
import Requests from '../../components/tabs/Requests';
import { useDispatch ,useSelector} from 'react-redux';
import {setActiveTab} from '../../app/slice/sideBarSlice';
import axios from "axios";
import config from '../../env.json';
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { MDBIcon } from 'mdbreact';

export default function Calendar() {

  const { t } = useTranslation();
  const dispatch= useDispatch()
  const [value, setValue] = useState("1");
  const [outlookval, setOutlookVal] = useState(false);
  const [syncAni, setSyncAni] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [key, setKey] = useState('home');
  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
  
    
    document.documentElement.style.setProperty('--visibility', 'hidden');
    document.getElementById("page-name")?.setAttribute('data-content',t('Calendar'));

  }, [])
  const handleChange = (event,newValue) => {
    
    setValue(newValue);
  };
  
  const handleSync = () => {
    setSyncAni(true);
    setRefresh(false);
    axios
        .get(
          config.REACT_APP_CALENDAR_API_BASE+"/outlook",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          console.log(res.data);    
          setSyncAni(false);   
          setRefresh(true);
         // handleCloseParent(true)
        })
        .catch((err)=>{
          console.log("Freetime Error:"+err);
          setSyncAni(false) ;   
        })

       
    setOutlookVal(true);
  };
  return (
    // <div style={{display:"grid",placeItems:"center"}}>Calendar</div>
    <div className='calendar-container'>

<TabContext value={value}>
        <Box className='CalendarBox' sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList className='calendarTablist' onChange={handleChange} aria-label="lab API tabs example" >
          <Tab label={t("Calendar")} className='calendarTab' value="1"  />
            <Tab label={t("Requests")} className='requestTab' value="2" />
            <div >
            <Tooltip title={t('Sync')} arrow>
            {  syncAni?
              <i id="sync1" onClick={handleSync} className="fas fa-sync m-3 lg primary Sync syncAni"></i>
              :            
               <i id="sync1"  onClick={handleSync} className="fas fa-sync m-3 lg Sync primary"></i>             
            }
            </Tooltip>
            </div>
          </TabList>
        </Box>
        <TabPanel value="1" className='calendarTabPanel'><CalendarComponent refresh={refresh} stoprefresh={()=>{setRefresh(false)}}/></TabPanel>
        <TabPanel value="2" className='requestTabPanel'><Requests /></TabPanel>
        
        
</TabContext>
    
    </div>
  )
}
