import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { sqlite, existingConn } from '../../App';
export const createTableScript: string = `
    CREATE TABLE IF NOT EXISTS user (
    id INTEGER PRIMARY KEY NOT NULL,
    authToken TEXT
    );
`;

export async function createDatabase(): Promise<void> {
    try {
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let ret: any = await db.execute(createTableScript);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---DB Create Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function deleteUserDetails(): Promise<void> {
    try {
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd = `DELETE FROM user`;
        await db.query(sqlcmd);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---USER Table Delete Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
} 

export async function addUserDetails(accessToken: string): Promise<void> {
    try {
        await deleteUserDetails();
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd: string = 
                "INSERT INTO user (authToken) VALUES (?)";
        let values: Array<any>  = [accessToken];
        await db.run(sqlcmd,values);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve();
    } catch (err) {
        console.log("---USER TABLE INSERT Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function getUserDetails(): Promise<any> {
    try {
        let isConn = await sqlite.isConnection("db");
        let db: SQLiteDBConnection;
        if(!isConn.result) {
            db = await sqlite.createConnection("db");
        } else {
            db = await sqlite.retrieveConnection("db");
        }
        await db.open();
        let sqlcmd = "SELECT * FROM user";
        const user = await db.query(sqlcmd);
        await db.close();
        await sqlite.closeAllConnections();
        return Promise.resolve(user.values);
    } catch (err) {
        console.log("---USER TABLE SELECT Error---");
        console.log(err);
        await sqlite.closeAllConnections();
        return Promise.reject(err);
    }
}

export async function deleteDatabase(db: SQLiteDBConnection): Promise<void> {
    try {
      let ret: any = await db.isExists();
      if(ret.result) {
        const dbName = db.getConnectionDBName();
        console.log("$$$ database " + dbName + " before delete");
        await db.delete();
        console.log("$$$ database " + dbName + " after delete " + ret.result);
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    } catch (err) {
      return Promise.reject(err);
    }
}