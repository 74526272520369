import React,{useState,useEffect} from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput,{getCountries, getCountryCallingCode,isPossiblePhoneNumber,parsePhoneNumber,isSupportedCountry,isValidPhoneNumber} from 'react-phone-number-input';
import TextField, { TextFieldProps } from "@mui/material/TextField";
import countryNames from 'react-phone-number-input/locale/en.json'
import config from '../../env.json';
import './CountryDropdown.css'
import { useTranslation } from 'react-i18next';
function CountryDropdown(props) {
   const {valueNumber,onChangeNumber, disableColored,errorFunc,initialYN, isReadOnly}=props;
    const { t } = useTranslation();
    const [value, setValue] = useState(valueNumber)
    const [length,setLength]=useState(12)
    const [error,setError]=useState(false);
    const [initial,setInitial]=useState(true);
    const [country,setCountry]=useState<any>(config.REACT_APP_COUNTRY_LIST.split(','));
    useEffect(() => {



        setValue(valueNumber);

        console.log(valueNumber)

        if (valueNumber) {

            if (valueNumber.length > 0) {

                setInitial(false);

            }



            // setError(!isPossiblePhoneNumber(valueNumber))

            // errorFunc(!isPossiblePhoneNumber(valueNumber))

            setError(!isValidPhoneNumber(valueNumber))

            errorFunc(!isValidPhoneNumber(valueNumber))





        }

        else {

            setInitial(true);

            setError(true);

            errorFunc(true);

        }





    }, [valueNumber])
    useEffect(()=>{
        console.log(country)
    },[country])
    useEffect(() => {
        // if(value){
        //     let c:any=parsePhoneNumber(value);
        //     countryChnage(c?c.country:config.REACT_APP_DEFAULT_COUNTRY as any)
        // }else{
        //     countryChnage(  config.REACT_APP_DEFAULT_COUNTRY as any);
        // }
        if(initialYN)
        setInitial(true)
    }, [initialYN])

    // const countryChnage=(e)=>{
    //     if(e){
    //        let t ="+"+getCountryCallingCode(e)
    //        while(!isValidPhoneNumber(t)){
    //         t+='1'
    //        }
    //        console.log(t.length,t);
    //        setLength(t.length)
    //     }
    // }
    const changeValue=(e)=>{
        debugger
   

        if(e){
            // console.log(isPossiblePhoneNumber(e))
            // setError(!isPossiblePhoneNumber(e))
            // errorFunc(!isPossiblePhoneNumber(e))

            setError(!isValidPhoneNumber(e))
            errorFunc(!isValidPhoneNumber(e))
        }
        setValue(e);
        setInitial(false)
        debugger
        onChangeNumber(e);
        // console.log("gc getting the country code", getCountryCallingCode(e));
        
        //console.log(isSupportedCountry('US'))
        //console.log(isSupportedCountry(config.REACT_APP_DEFAULT_COUNTRY as any),config.REACT_APP_DEFAULT_COUNTRY)
    }
    return (
        <div className='phoneNumberInput'>
            <label>{t('Phone Number')}</label>
      <PhoneInput
        // onCountryChange={(e)=>{if(!e){setCountry(undefined)}else{setCountry([])}}}
        required
        countryCallingCodeEditable={false}
        defaultCountry={isSupportedCountry(config.REACT_APP_DEFAULT_COUNTRY as any)?config.REACT_APP_DEFAULT_COUNTRY:undefined as any}
        // defaultCountry={"US"}
        placeholder={t('Enter phone number')}
        // countries={[isSupportedCountry(config.REACT_APP_DEFAULT_COUNTRY as any)?config.REACT_APP_DEFAULT_COUNTRY:undefined as any]}
        countries={country}
        value={value}
        international
        addInternationalOption={false}
        readOnly = {isReadOnly}
        onChange={
            (e:any)=>{changeValue(e)}
        }
        />
        {
            error && !initial?
            <p className="erorrPhone form-error-message">
                {t('Enter a valid phone number')}
            </p>:null
        }
        </div>
    )
}

export default CountryDropdown