import { combineReducers } from 'redux'
import loginSlice from './slice/loginSlice';
import sideBarSlice from './slice/sideBarSlice';
import chatSlice from './slice/chatSlice';
import callModalSlice from './slice/callModalSlice';
import floaterVideoCallSlice  from './slice/videoCallSlice';
import authApiSlice from './slice/authApiSlice';
import personalisationSlice from './slice/personalisationSlice';
// import {apiSlice} from '../utils/ApiSlice';
// import {apiContacts} from '../utils/ApiContacts';


// export default combineReducers({
//   login:loginSlice,
//   sideBar:sideBarSlice,
//   chat:chatSlice,
//   callModal:callModalSlice,
//   floaterVideoCall:floaterVideoCallSlice,
//   authApi: authApiSlice,
//   // [apiSlice.reducerPath]: apiSlice.reducer,
//   // [apiContacts.reducerPath]:apiContacts.reducer
// })
const allReducers  = combineReducers({
  login:loginSlice,
  sideBar:sideBarSlice,
  chat:chatSlice,
  callModal:callModalSlice,
  floaterVideoCall:floaterVideoCallSlice,
  authApi: authApiSlice,
  personalisation:personalisationSlice
  // [apiSlice.reducerPath]: apiSlice.reducer,
  // [apiContacts.reducerPath]:apiContacts.reducer
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return allReducers(state, action);
}

export default rootReducer;