import axios from "axios";
import config  from '../../env.json';
let cancelToken:any;

function getAllVideoHistory(callback){
  // let {page,pageSize,search,cancelToken}=params;
    try {
        axios.get(config.REACT_APP_VIDEO_API_BASE+'/meeting',{
          //cancelToken: cancelToken
        })
        .then(res=>{ 
          debugger;
          callback(res.data.data);
        }).catch((error)=>{
          if (axios.isCancel(error)) {
            callback({error:true})
          }
          else{
            callback([])
          }
          
        })
    } catch (error) {
        callback([])
    }
   
}

function CreateVideoMeeting(payload,callback){
  try {
    axios.post(config.REACT_APP_VIDEO_API_BASE+"/meeting/GetMultipleVideoURLs",payload).then(res=>{
      callback(res.data.data);
    }).catch(()=>{
      callback(false)
    })
} catch (error) {
    callback(false)
}
}

export {getAllVideoHistory,CreateVideoMeeting}