import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ContactAlreadyAssign from '../../components/logoutModal/LogoutModal'


import Chip from "@mui/material/Chip";

import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
import { parsePhoneNumber } from "react-phone-number-input";
// import ReactAutocomplete from "react-autocomplete";
import { addNewContact, checkNumberExist } from "../../apis/contacts/contactsApi";
import { useDebounce } from 'usehooks-ts'
import { useTranslation } from "react-i18next";
import Paper from '@mui/material/Paper';
import axios from "axios";
import config from '../../env.json'
import './SaveContactForChat.css'
import Button from "../../layouts/button/Button";
import { editContact } from "../../apis/contacts/contactsApi";
import { setChatType,setChatDetails,setRefreshChatList,setRefreshChatComponent } from "../../app/slice/chatSlice";
import { useDispatch,useSelector } from 'react-redux';
import {setShowSuccessSnackBar,setShowErrorSnackBar} from '../../app/slice/personalisationSlice';
import {getDeviceType} from '../../utils/deviceTypeUtil';
import { useLocation } from "react-router-dom";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
const BpCheckedIcon = styled(BpIcon)({

  backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
  },
});
// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function SaveContactsForChat(props) {
  const {unknownid,number, show, handleCloseParent,deletedOthers }=props;
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState<any>({});
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [temp, setTemp] = useState("");
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [formType, setFormType] = useState(true);
  const [othersData,setOthersData]=useState([])
  const [othersDatahidden,setOthersDataHidden]=useState(true)
  const [selectedOthers,setSelecteedOthers]=useState<any>({})
  const [isAdd,setIsAdd]=useState(false);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
    const [showModal,setShowModal]=useState(false);
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [multiAssociatePrescriberCheck, setMutliAssociatePrescriberCheck]= useState<any>(true);
  const { chatName } = useSelector((state: any) => state.chat);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setOthersPhone(number);
    if(config.REACT_APP_MULTI_PRES_ASSOCIATION){      
      setMutliAssociatePrescriberCheck(config.REACT_APP_MULTI_PRES_ASSOCIATION === "N"? false: true)
     }
     debugger
     if(deletedOthers){
      setFormType(false)
      setOthersPhone('')
     } else if (unknownid) {
      setFormType(false);
      setOthersPhone(number);
     } 
  }, [number]);

  function validatePhoneNumber(input_str) {
    var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  
    return re.test(input_str);
  }

  useEffect(()=>{
    if(validatePhoneNumber(chatName)==false && !unknownid)
    {
    setOthersName(chatName);
    }
  }, [])

  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);
    getModalDimensions();
    window.addEventListener('resize',(e) => {
      getModalDimensions();
    })
  }, [debouncedValue])

  useEffect(() => {
    window.addEventListener('orientationchange', getModalDimensions);
    return () => {
      window.removeEventListener('orientationchange', getModalDimensions)
    }
  }, [])

  const getModalDimensions=()=>{
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
      setModalWidth(window.innerWidth-divpaddingleft-divpaddingright); 
    }
  }
  const getContactsData = (value) => {
    debugger;
    if (value.length > 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?IsPrescriberOnly=false&Page=" +
            0 +
            "&PageSize="+10+"&Search=" +
          value,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
            
          setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
          
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };
  const setPrescriber = (x) => {
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setHiddenPresList(true);
    setDisabled(false);
  };

  const handleYOrNPopup =(e:any) =>{
    if(othersPhone) {
    let contact:any=assoPresc
    let phn:any=parsePhoneNumber(othersPhone);
    console.log(phn)
    let payload:any={
      AcctPh_Nbr:phn.nationalNumber,
      IntlCallPfx_Nbr:phn.countryCallingCode,
      Id:contact.Id,
      UnknownId:unknownid
    }
    if(e=== true){
      if(deletedOthers && !formType){
        if(contact.Id){
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            Id:contact.Id,
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid
          } 
        }
        else{
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid
          } 
        }
        addNewContact(payload,async (cont)=>{
          if(cont){
            
                      payload.Prescriber=secPrescribers;
                      payload.Is_Prescriber="N";
                      debugger
                      let done=false;
                      let original=contact.Prescribers?contact.Prescribers:[];
                      let modified=secPrescribers;
                      let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                      let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                      let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                      let a,d;
                      if(forAdd.length>0){
                        let assoPayload={
                          PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                          PresciberAccociatesPrescrber_Id: forAdd,
                        }
                        await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                          a=res
                        }).catch(()=>{
                          
                        });
                      }
                      if(forDelete.length>0){
                        let assoPayload={
                          Ids:forDelete
                        }
                        await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                          d= res
                        }).catch(()=>{
                          
                        })
                      }
                      let final=payload;
                      dispatch(setChatDetails(
                        {
                          chatId:cont.Id,
                          chatIsPrescriber:cont.Is_Prescriber=="Y",
                          showChat:location.pathname==='/CallLog'? false : true,
                          chatName:cont.AcctFull_Nm
                        }))
                        dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                        dispatch(setRefreshChatList(true))
                        dispatch(setRefreshChatComponent(true))
                       clearAll(true,final);
                       dispatch(setShowSuccessSnackBar(true));
          }
          else{
             clearAll(false,{})
             dispatch(setShowErrorSnackBar(true));
          }
        })
      }
      
    } else{
      setShowModal(false) 
    }
    }
  }
  const closePopup = (torf) => {
    setOthersName("");
    setOthersPhone("");
    setAssoPrescName("");
    setDisabled(true);
    setAssoPresc({});
    setSecPrescribers([]);
    handleCloseParent(torf);
  };
  async function addContact() {
    setDisabled(true);
    let phn: any = parsePhoneNumber(othersPhone);
    console.log(phn);
    let payload = {
      AcctPh_Nbr: phn.nationalNumber,
      Prescriber_Id: secPrescribers.map((x: any) => x.Id),
      AcctFull_Nm: othersName,
      IntlCallPfx_Nbr: phn.countryCallingCode,
    };
    console.log(payload);
    addNewContact(payload, (res: any) => {
      if (res) {
        closePopup(true);
      } else {
        setDisabled(false);
        closePopup(false);
      }
    });
    // axios
    //     .post(
    //       config.REACT_APP_CONTACTS_API_BASE+"/contact",
    //       {
    //         AcctPh_Nbr:othersPhone,
    //         Prescriber_Id:assoPresc.Id as any,
    //         AcctFull_Nm:othersName,
    //         IsPrescriber:false
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + sessionStorage.getItem("login"),
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       closePopup(true);
    //       //handleCloseParent(true)
    //     })
    //     .catch((err)=>{
    //       closePopup(false);
    //      // handleCloseParent(false)
    //     })
  }
  
  // useEffect(() => {
  //   setSecPrescribers(secPrescribers);
  // }, [secPrescribers])

  const checkDisabled = (name, phone, presList) => {
    if (name && phone && presList) {
      setDisabled(false);
    } else setDisabled(true);
  };
  const reSetFormInputs = (e) => {
    // setSelecteedOthers({});
    // console.log(e)
    setFormType(e);
    // if(e === true){
    // setOthersPhone("");
    // setTemplateText("")
    // setAssoPrescName("");
    // }else{
    //   setTemplateId(0)
    //   setAssoPrescName("");
    //   setAssoPresc({Id: ''});
    //   setHiddenPresList(true);
    //   setDisabled(true);
    //   setOthersPhone("");
    //   setDisableBrandInput(true)
    //   // setTemplate([]);
    //   setTemplateText("");
    //   setOthersName("");
    //  setAssoPrescName("");
    //  setAssets([]);
    //  setAssociated({})

    // }
  };
  const clearAll=(passOrNot,data)=>{

    setOthersName('');
    setSecPrescribers([]);
    setDisabled(true)
    if(passOrNot){
      handleCloseParent(true,data);
    }else
    handleCloseParent(false,{})

  }
  async  function updateContact(){
    
    let contact:any=assoPresc
    let phn:any=parsePhoneNumber(othersPhone);
    console.log(phn)
    let payload:any={
      AcctPh_Nbr:phn.nationalNumber,
      IntlCallPfx_Nbr:phn.countryCallingCode,
      Id:contact.Id,
      UnknownId:unknownid
    }
    // debugger
    //for others
    if(!formType){
        contact=selectedOthers;
        if(contact.Id){
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            Id:contact.Id,
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid
          } 
        }
        else{
          payload={
            AcctPh_Nbr:phn.nationalNumber,
            AcctFull_Nm:othersName,
            Prescriber_Id:secPrescribers.map((x:any)=>x.Id),
            IntlCallPfx_Nbr:phn.countryCallingCode,
            UnknownId:unknownid
          } 
        }
          console.log(secPrescribers)
          debugger
          if(deletedOthers){
            checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
              console.log(res)  
              if(res)  {
                setShowModal(true)
                    } else{
                      addNewContact(payload,async (cont)=>{
                        if(cont){
                          
                                    payload.Prescriber=secPrescribers;
                                    payload.Is_Prescriber="N";
                                    debugger
                                    let done=false;
                                    let original=contact.Prescribers?contact.Prescribers:[];
                                    let modified=secPrescribers;
                                    let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                                    let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                                    let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                                    let a,d;
                                    if(forAdd.length>0){
                                      let assoPayload={
                                        PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                                        PresciberAccociatesPrescrber_Id: forAdd,
                                      }
                                      await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                                        a=res
                                      }).catch(()=>{
                                        
                                      });
                                    }
                                    if(forDelete.length>0){
                                      let assoPayload={
                                        Ids:forDelete
                                      }
                                      await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                                        d= res
                                      }).catch(()=>{
                                        
                                      })
                                    }
                                    let final=payload;
                                    dispatch(setChatDetails(
                                      {
                                        chatId:cont.Id,
                                        chatIsPrescriber:cont.Is_Prescriber=="Y",
                                        showChat:location.pathname==='/CallLog'? false : true,
                                        chatName:cont.AcctFull_Nm
                                      }))
                                      dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                                      dispatch(setRefreshChatList(true))
                                      dispatch(setRefreshChatComponent(true))
                                     clearAll(true,final);
                                     dispatch(setShowSuccessSnackBar(true));
                        }
                        else{
                           clearAll(false,{})
                           dispatch(setShowErrorSnackBar(true));
                        }
                      })
                    }})
          } else{
            addNewContact(payload,async (cont)=>{
              if(cont){
                
                          payload.Prescriber=secPrescribers;
                          payload.Is_Prescriber="N";
                          debugger
                          let done=false;
                          let original=contact.Prescribers?contact.Prescribers:[];
                          let modified=secPrescribers;
                          let difference=original.filter(x=>{if(modified.findIndex(p=>p.Id==x.Id)<0)return x;}).concat(modified.filter(x=>{if(original.findIndex(p=>p.Id==x.Id)<0)return x;}))
                          let forDelete:any=difference.filter((x:any)=>{if(x.Association_Id)return x.Id}).map(x=>x.Association_Id)
                          let forAdd:any=difference.filter((x:any)=>{if(!x.Association_Id)return x.Id}).map(x=>x.Id)
                          let a,d;
                          if(forAdd.length>0){
                            let assoPayload={
                              PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                              PresciberAccociatesPrescrber_Id: forAdd,
                            }
                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation",assoPayload).then(res=>{
                              a=res
                            }).catch(()=>{
                              
                            });
                          }
                          if(forDelete.length>0){
                            let assoPayload={
                              Ids:forDelete
                            }
                            await axios.post(config.REACT_APP_CONTACTS_API_BASE+"/contact/associateassociation/delete",assoPayload).then(res=>{
                              d= res
                            }).catch(()=>{
                              
                            })
                          }
                          let final=payload;
                          dispatch(setChatDetails(
                            {
                              chatId:cont.Id,
                              chatIsPrescriber:cont.Is_Prescriber=="Y",
                              showChat:location.pathname==='/CallLog'? false : true,
                              chatName:cont.AcctFull_Nm
                            }))
                            dispatch(setChatType(cont.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
                            dispatch(setRefreshChatList(true))
                            dispatch(setRefreshChatComponent(true))
                           clearAll(true,final);
                           dispatch(setShowSuccessSnackBar(true));
              }
              else{
                 clearAll(false,{})
                 dispatch(setShowErrorSnackBar(true));
              }
            })
          }
          
    }
    else{
      editContact(payload,(res)=>{
        if(res){
          dispatch(setChatDetails(
            {
              chatId:res.Id,
              chatIsPrescriber:res.Is_Prescriber=="Y",
              showChat:location.pathname=== '/CallLog' ? false : true,
              chatName:res.AcctFull_Nm
            }))
            dispatch(setChatType(res.Is_Prescriber=="Y"? "PRESCR":"ASSPRS"))
            dispatch(setRefreshChatList(true))
            dispatch(setRefreshChatComponent(true))
            clearAll(true,res);
            dispatch(setShowSuccessSnackBar(true));
        }
        else{
            clearAll(false,{})
            dispatch(setShowErrorSnackBar(true));
        }
      })
     
    }
   
    
    
   
  }
  const setOthersFromList=(x)=>{
     
      setIsAdd(false);
      setSelecteedOthers(x);
      setOthersName(x.AcctFull_Nm);
      setOthersDataHidden(true);
      if(x.Prescribers){
        setSecPrescribers(x.Prescribers);
      }
      checkDisabled(
       true,true,true
      )
}
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        //onClose={()=>{closePopup(false)}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div className="modal-box contact-box ipad-top-margin" style={mobileStyle?{height:modalHeight,width:modalWidth}:{borderRadius:'1rem',overflow:'auto'}}>
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t("Save Contact")}</h1>
                <span
                  onClick={() => {
                    clearAll(false,{});
                  }}
                  className="close"
                >
                  ×
                </span>
              </div>

              <div className="modal-body addContacts">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateContact();
                  }}
                >
                  <div className="selecttype-radio-btns">
                    <FormControl>
                      <FormLabel id="demo-customized-radios">
                        {t("Select Type")}
                      </FormLabel>
                      <RadioGroup
                        row
                        defaultValue={deletedOthers || unknownid ? 'others':'prescriber'}
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        onClick={(e: any) => {
                          e.target.value === "prescriber"
                            ? reSetFormInputs(true)
                            : reSetFormInputs(false);
                        }}
                      >
                        <FormControlLabel
                          value="prescriber"
                          control={<BpRadio />}
                          label="Prescriber"
                          disabled={deletedOthers || unknownid ? true:false}
                        />
                        <FormControlLabel
                          value="others"
                          control={<BpRadio />}
                          label="Others"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    {formType ? (
                      <div className="form-fields-div">
                        <h1 className="font-md">{t("Prescribers")}</h1>
                        
                        <div>
                          <AssociatedPresList
                            placeholder={t("Search for a Prescriber")}
                            label=""
                            styleClass=""
                            clearDataOnSelect={false}
                            onChangeInputValue={(e:any)=>{console.log(e)}}
                            setAssoPresc={(e: any) => {
                                setPrescriber(e)
                                setDisabled(false)
                            }}
                          />
                        </div>
                        <TextField
                          className="roundborder disableNumber"
                          value={othersPhone}
                          label={t("Phone Number")}
                          color="secondary"
                          focused
                        />
                      </div>
                    ) : (
                      <div className="form-fields-div">
                        <h1 className="font-md">{t("Recipient Name")}</h1>
                        <div className="NewChatModalOthersName">
                          <TextField
                            className="roundborder inputEnable"
                            onChange={(e) => {
                               // setSelecteedOthers({});
                              if (e.target.value.length == 0) {
                                setSelecteedOthers({});
                              }
                              setOthersName(e.target.value);
                            }}
                            value={othersName}
                            label=""
                            placeholder={t("Enter Name")}
                            color="secondary"
                            focused
                          />
                          {
                            deletedOthers?null:
                            <Paper hidden={othersDatahidden}>
                            <div id="presList">
                              {othersData.length > 0 && (
                                othersData.map((x: any) => 
                                  <div
                                    key={x.Id}
                                    onClick={() => {
                                      setOthersFromList(x);
                                    }}
                                    >
                                    {x.AcctFull_Nm}
                                  </div>
                                )
                              ) 
                              }
                            </div>
                          </Paper>
                          }
                          
                       
                        </div>
                        {
                          deletedOthers?
                          <CountryDropdown errorFunc={(e)=>{
                            checkDisabled(
                              othersName.length > 0,
                              true,
                              secPrescribers.length > 0
                            );
                          }} disableColored="" valueNumber={othersPhone} onChangeNumber={(e)=>{setOthersPhone(e);}} />
                          :
                          <TextField
                          className={"roundborder disableNumber"}
                          value={othersPhone}
                          label={t("Phone Number")}
                          color="secondary"
                          focused
                        />
                        }
                        

                         <h1 className="font-md">{t("Associated Prescriber")}</h1>


                         {multiAssociatePrescriberCheck ?
                <div>
               

                  <div className="secPresDiv">
                {
                   secPrescribers.map((x:any)=>(
                    
                   <Chip key={x.Id} id={x.Id} label={x.AcctFull_Nm} 
                   onDelete={()=>{
                      // onChipDelete(x);                       
                   }}
                    />))
                }
                </div>
                
                </div>:
                null}
                


                         {/* {
                            secPrescribers.length>0?
                            <div className="secPresDiv">
                          {secPrescribers.map((x: any) => (
                            <Chip
                              key={x.Id}
                              id={x.Id}
                              label={x.AcctFull_Nm}
                              onDelete={() => {
                                let temp = secPrescribers.filter((p: any) => {
                                  if (p.Id !== x.Id) return p;
                                });
                                console.log(temp);
                                setSecPrescribers(temp);
                              }}
                            />
                          ))}
                        </div>:null
                         } */}
                        
                       
                        <AssociatedPresList
                          placeholder={t("Search for an Associated Prescriber")}
                          label=""
                          styleClass=""
                          clearDataOnSelect={multiAssociatePrescriberCheck?true:false}
                          onChangeInputValue={(e:any)=>{console.log(e)}}
                          setAssoPresc={(e: any) => {
                            let t: any = [e];
                            if(multiAssociatePrescriberCheck){
                      
                              t=t.concat(secPrescribers);
                          }
                            const result: any = [];
                            const map = new Map();
                            for (const item of t) {
                              if (!map.has(item.Id)) {
                                map.set(item.Id, true); // set any value to Map
                                result.push(item);
                              }
                            }
                            checkDisabled(
                              othersName.length > 0,
                              othersPhone.length > 0,
                              result.length > 0
                            );
                            setSecPrescribers(result);
                          }}
                        />
                      </div>
                    )}
                        <br />
                        <div className="SaveButtonForchat">
                        <Button type="submit" label={t("Save")} disable={disabled} />
                        </div>
                   
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
      show={showModal}
      yorn={handleYOrNPopup}
      modalText={t('This number has already been assigned. Do you want to override the number?')}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowModal(false)
      }} />
    </div>
  );
}
