import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Paper from '@mui/material/Paper';
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import './VideoModal.css';
import Autocomplete from "@mui/material/Autocomplete";
import { useGetPrescribersQuery,useAddContactQuery} from "../../utils/ApiContacts";
// import ReactAutocomplete from "react-autocomplete";
import SearchInput from "../../layouts/searchInput/SearchInput";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import config from '../../env.json';
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import CountryDropdown from "../../layouts/countryDropdown/CountryDropdown";
// import ReactAutocomplete from "react-autocomplete";
import OutlinedInput from '@mui/material/OutlinedInput';
import { CreateVideoMeeting } from "../../apis/video/video";
import Button from "../../layouts/button/Button";
import axios from "axios";

// import "./AddContact.css";
import { data } from "jquery";
import { borderRadius } from "@mui/system";
import { useDebounce } from 'usehooks-ts';import { useTranslation } from "react-i18next";
import moment from "moment";
import {getDeviceType} from '../../utils/deviceTypeUtil';

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  
  let primcolor=document.documentElement.style.getPropertyValue('--primary-color');
  const BpCheckedIcon = styled(BpIcon)({
  
    backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: primcolor?document.documentElement.style.getPropertyValue('--primary-color'):'#734bd1' ,
    },
  });
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};
function BpRadio(props: RadioProps) {
    debugger;
    return (
      <Radio
        sx={{
          "&:hover": {
            bgcolor: "transparent",
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }


export default function VideoModal({ show, handleCloseParent }) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [PrescName, setPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc,setAssoPresc]=useState({Id:null})
  const [post, setPost] = useState([]);
  const [temp,setTemp]=useState('')
  const [age, setAge] = React.useState('');
  const [name, setName] = React.useState('');
  const [formType, setFormType] = useState(true);
  const [meetingType, setMeetingType] = useState(true);
  const [assoPrescName, setAssoPrescName] = useState("");
  const [disableBrandInput,setDisableBrandInput]=useState(true)
  const [phoneError,setPhoneError]=useState(false);
  const [meetingname,setMeetingName]=useState('');
  const [prescriberId,setPrescriberId]=useState('');
  const [userType, setUserType] = useState("P");
  const [disabled,setDisabled]=useState(true);
  const [loading, setLoading] = useState(false);
  const [pageSize,setPageSize]=useState(10);
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [othersData,setOthersData]=useState([])
  const [othersDatahidden,setOthersDataHidden]=useState(true)
  const [selectedOthers,setSelecteedOthers]=useState<any>({})
  const [templateId,setTemplateId]=useState(0);
  const [secPrescribers,setSecPrescribers]=useState<any>([]);
  const [startDate,setStartDate]=useState(moment().format('YYYY-MM-DD'));
  const [startTime,setStartTime]=useState(moment().format('HH:mm'));
  const [mobileStyle, setMobileStyle] = useState(false);
  const [showOnce,setShowOnce]=useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const getContactsData = (value) => {
     
    // useGetContactsQuery({
    //         page:this.state.page,
    //         pageSize:this.state.pageSize,
    //         search:this.state.search
    //       })
    // const DATATEMP = useGetContactsQuery({
    //       page:this.state.page,
    //       pageSize:this.state.pageSize,
    //       search:this.state.search
    //     })
    //     console.log(DATATEMP);
    debugger;
    if (value.length > 2) {
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?IsPrescriberOnly=false&Page=" +
            0 +
            "&PageSize="+pageSize+"&Search=" +
            value,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setOthersData(res.data.data.filter((x:any)=>x.Is_Prescriber=="N"))
          setPost(res.data.data.filter((x:any)=>x.Is_Prescriber=="Y"));
          
        });
    } else {
      setOthersDataHidden(true)
      setOthersData([])
      setPost([]);
      setHiddenPresList(true);
    }
  };

  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () {}, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    // Do fetch here...
    // Triggers when " " changes
    setOthersDataHidden(false)
    getContactsData(debouncedValue);
    getModalDimensions();
    
  }, [debouncedValue])

  const getModalDimensions=()=>{
    let div:any=document.querySelector('#main') 
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
      setMobileStyle(true);
      setModalHeight(window.innerHeight-divpaddingTop-divpaddingbottom);
      setModalWidth(window.innerWidth-divpaddingleft-divpaddingright); 
    }
  }
  useEffect(()=>{
    return()=>{
      
        setStartDate(moment().format('YYYY-MM-DD'));
        setStartTime(moment().format('HH:mm'));
     
    }
  },[])
  const setPrescriber = (x:any) => {
    setUserType("P");
    setPrescriberId(x.Id);
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    // setLoading(true);
    console.log(othersPhone);
  };
  
  
  const getNumberFromItem=(item)=>{
    debugger

    if(item.AcctPh_Nbr){
      if(item.AcctPh_Nbr.indexOf("+")>=0){
        return item.AcctPh_Nbr
      }else
      return '+'+item.IntlCallPfx_Nbr+item.AcctPh_Nbr
    }
    else return ""
  }

  

  const brandInputdisable = (name,phone,asso_pres) =>{
  
    // console.log(assoPrescName === "" && othersPhone === "", assoPrescName, othersPhone)
    if(name && phone && asso_pres){
      debugger
      setDisableBrandInput(false)
    } else {
      debugger
      setDisableBrandInput(true)
    }

  }
  const setPhoneNumber =(e) =>{

    // let phn:any=parsePhoneNumber(othersPhone);
    //     let payload={
    //       AcctPh_Nbr:phn.nationalNumber,
    //       Prescriber_Id:[prescriber.Id],
    //       AcctFull_Nm:othersName,
    //       IntlCallPfx_Nbr:phn.countryCallingCode
    //     }
  
    if(e){
      setOthersPhone(e);
      brandInputdisable((assoPrescName.length > 0),(e.length >0),(assoPrescName.length > 0));
    } else {
      setOthersPhone("");
      brandInputdisable((assoPrescName.length > 0),false,(assoPrescName.length > 0));
    }
  }

  const setOthersFromList=(x)=>{
    debugger
    setUserType("O");
    setShowOnce(false);
    setSelecteedOthers(x);
      setOthersName(x.AcctFull_Nm);
      //setOthersPhone(x.AcctPh_Nbr);
      setOthersDataHidden(true);
      if(x){
        setPrescriberId(x.Id);
      }
      setOthersPhone(getNumberFromItem(x));
      brandInputdisable(true,(x.AcctPh_Nbr.length >0),meetingname.length>0)
  }

  const reSetFormInputs = (e)=>{
    setSelecteedOthers({});
    // console.log(e)
    setFormType(e)
    // if(e === true){
    //   setOthersPhone("");
    //   setTemplateText("")
    //   setAssoPrescName("");
    // }else{
      setTemplateId(0)
      setAssoPrescName("");
      setHiddenPresList(true);
      setDisabled(true);
      setOthersPhone("");
      setDisableBrandInput(true)
      // setTemplate([]);
      setOthersName("");
      setAssoPrescName("");
  
    // }
   }

  const closePopup=(torf)=>{
    setOthersName('')
    setOthersPhone('')
    // setAssoPrescName('');
    setDisabled(true);
    // setAssoPresc({})
    setSecPrescribers([]);
    handleCloseParent(torf);
   
  }
  async  function CreateVideo(){
    debugger;
    console.log(meetingname,prescriberId);
    setLoading(true);
    console.log(startDate,startTime)
    console.log()
    debugger;
    let payload={
      Source: "ZingSaaS",
      RoomName: meetingname,
      ParticipantList: [
          {
              Id: prescriberId,
              Type:userType
          }
          

      ],
      StartTime:new Date(startDate+"T"+startTime).toISOString()
      // Requester: {
      //     Id: "1013"
      // }
  }
    console.log(
      payload
    )
    CreateVideoMeeting(payload,(res:any)=>{
      if(res){
        closePopup(true);
        setLoading(false);
      }
      else{
        closePopup(false);
        setLoading(false);
      }
    })
  } 
  const checkDisabled=(assoPrescName,othersPhone,meetingname)=>
  {
    debugger;
    if(assoPrescName && othersPhone && meetingname){
      debugger;
      setDisabled(false);
    }
    else setDisabled(true);
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{height:"50%"}}
      >
        <Fade in={show}>
          <Box sx={style}>
          <div className="modal-box" style={mobileStyle?{height:modalHeight,width:modalWidth}:{width:'100%',borderRadius:'1rem',overflow:'auto'}}>
            <div className="modal-header d-flex ">
              <h1 className="font-createcontact">{t('Create Video Meeting')}</h1>
              <span onClick={() => {
                  // closePopup(false);
                  handleCloseParent();
                  setMeetingType(true);
                  reSetFormInputs(true)
                }}
                 className="close">
                ×
              </span>
            </div>
          <form onSubmit={(e)=>{e.preventDefault();CreateVideo();}}>
            <div className="modal-body video-body">
           
              <div className="selecttype-radio-btns">
                <FormControl>
                  <FormLabel id="demo-customized-radios">{t('Select Type')}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue="prescriber"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    onClick={(e: any) => {
                        e.target.value === "prescriber"
                          ? reSetFormInputs(true)
                          : reSetFormInputs(false);  //setFormType (Previously)
                      }}
                  >
                    <FormControlLabel                      
                      value="prescriber"
                      control={<BpRadio />}
                      label="Prescriber"
                    />
                    <FormControlLabel
                      value="others"
                      control={<BpRadio />}
                      label="Others"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              
              {formType === false ?(
              <>    <div className="asso-prescriber-div createContactAssociate">
                                 
                  <TextField
                    // onChange={(e) => {
                    //   setTyping(true);
                    //   setAssoPrescName(e.target.value)
                    // }}
                    value={othersName}
                    label={t("Receipient Name")}
                    required // Added Validation to Prescriber
                    focused
                    // value={assoPrescName}
                    placeholder={t("Search for a Recipient")}
                    color="secondary"
                    id="messageBox"
                    onChange={(e) => { 
                      setShowOnce(true);
                      if(e.target.value.length>0){
                      checkDisabled(true,othersPhone.length>0,secPrescribers.length>0)
                    }
                    else{
                      checkDisabled(false,othersPhone.length>0,secPrescribers.length>0)
                    }
                    setOthersName(e.target.value);}
                  }

                  
                    autoComplete="off"
                    aria-expanded="true"
                    aria-controls="presList"
                    className={'associateInput inputEnable'}
                  />
                   
              
                     </div>  
                     {/* <div className="othesNameDiv" hidden={othersDatahidden}>
                    <ul>
                      {
                        othersData.map((x:any)=>(
                          <li key={x.Id} onClick={()=>{
                            setOthersFromList(x);
                            
                          }}>{x.AcctFull_Nm}</li>
                        ))
                      }
                      
                    </ul>
                  </div> */}
                  {showOnce && othersData.length > 0?
                  <Paper   hidden={othersDatahidden}>
                      <div id="presList" >
  
                        { othersData.length > 0 && (
                           othersData.map((x: any) => <div key={x.Id} onClick={()=>{setOthersFromList(x)}}>{x.AcctFull_Nm}</div>)
                        ) 
                        }
                      </div>     
                    </Paper>:null}
                  
                   </>

              ):(
                <>
                {/* <h1 className="font-md">Prescriber</h1><div className='presc__searchbox'>
                //                       <div className="input-group-prepend">
                //                           <span id="basic-addon3">
                //                               <button className="btn" onClick={() => { } }>
                //                                   <i className="fas fa-search i-1p5"></i>
                //                               </button>
                //                           </span>
                //                       </div>
                //                       <input type="text" className={'form-control font-md search-prescriber'} placeholder={"Search a Prescriber"} />
                //                   </div> */}
                
                <AssociatedPresList clearDataOnSelect={false} placeholder={t("Search for a Prescriber")}  label={t("Prescriber Name")} styleClass="inputEnable"  onChangeInputValue={(e:any)=>{console.log(e)}} setAssoPresc={(e:any)=>{setPrescriber(e);}}/></>
              )}
              
               <div className={"phno-txtfield"}>
               <CountryDropdown errorFunc={(e)=>{setDisabled(e);setPhoneError(e);if(e)brandInputdisable((assoPrescName.length > 0),false,(assoPrescName.length > 0));}} disableColored={disableBrandInput} valueNumber={othersPhone} onChangeNumber={(e:any)=>{setPhoneNumber(e)}}/>
                </div>
                <div className="meeting-textfield">
                          <TextField
                         className="roundborder"
                         label={t("Meeting name")}
                         placeholder={t("Please enter meeting Name")}
                         required // Added Validation to meeting name
                         sx={{width:400}}
                         onChange={(e) => {
                          if(e.target.value.length>0){
                          setMeetingName(e.target.value)
                          debugger
                          checkDisabled(assoPrescName.length>0 || othersName.length>0,othersPhone.length>0,true)
                         }
                        else
                        {
                          checkDisabled(assoPrescName.length>0 || othersName.length>0,othersPhone.length>0,false)
                        }}}
                         
                         focused
                        />
             </div>
             <div className="selecttype-radio-btns-video">
                <FormControl>
                  <FormLabel id="demo-customized-radios">{t('Select Meeting Type')}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue="Instant"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    onClick={(e: any) => {
                      if(e.target.value==="Instant"){
                        setStartDate(moment().format('YYYY-MM-DD'));
                        setStartTime(moment().format('HH:mm'));
                      }
                        e.target.value === "Instant"
                          ? setMeetingType(true)
                          : setMeetingType(false); 
                      }}
                  >
                    <FormControlLabel                      
                      value="Instant"
                      control={<BpRadio />}
                      label="Instant"
                    />
                    <FormControlLabel
                      value="Schedule"
                      control={<BpRadio />}
                      label="Schedule"
                    />
                  </RadioGroup>
                </FormControl>
              </div>  
              {meetingType==true?(<></>  
                     
                ):(   <><TextField
                    id="date"
                    
                    className="Datepicker"
                    label={t("Select Meeting Date")}
                    type="date"
                    placeholder="MM/DD/YYYY"
                    sx={{ width: 220 }}
                    inputProps={{
                      style: {
                        height:'84',
                        padding: '1rem',
                        color:'grey',
                      },
                    
                  }}
                
                  value={startDate}
                  onChange={(e)=>{ setStartDate(e.target.value)}}
                    InputLabelProps={{
                        shrink: true,
                    }} focused/>
                    <TextField
                        className="Timepicker"
                        id="time"
                        label={t("Select Meeting Time")}
                        type="time"
                        placeholder="--:--"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                          style: {
                            height:'84',
                            padding: '1rem',
                            color:'grey',
                          },
                      }}    
                      value={startTime}   
                      onChange={(e)=>{setStartTime(e.target.value)}}                       
                        sx={{ width: 150 }} focused/></>               
                )}

                <br />
                {/* <button type="submit" className="submit-btn btn rounded-primary-40 font-md mx-auto send-videolink" style={{backgroundColor: "rgba(115, 75, 209, 0.3)"}} >
                    <span> Send Video Link</span>
                    </button> */}
                    <div className="SendVideo"> <Button label={t("Send video link")} type="submit" disable={disabled} loading={loading}/></div>
                   
                {/* <button type="button" className="create-btn" disabled={disabled} style={{opacity:disabled?0.3:1}} >
                  Create
                </button> */}
              
            </div>
          </form>
          </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
