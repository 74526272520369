import React, { useState, useEffect } from "react";
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import './Dropdown.css'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// const names = [
//   'Cordastin'
// ];

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultipleSelectPlaceholder(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
 
  const [value,setValue]=useState({});
  const [disabled,setDisabled]=useState(false);
  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  //   props.func(value);
  // };
  // // console.log(props.brandData);
 
  

  useEffect(() => {
  console.log(props.data)
    debugger
    if(props.keepPlaceHolderAtStart){
      setValue({})
      setDisabled((props.data.length==0 && !props.notdisabledAfter))
    }
    else
    { 
      if(props.activeValue){
        setValue(props.activeValue?props.activeValue:{})
      }
      else
      setValue(props.data.length>0 ?props.data[0]:{})
      debugger
      setDisabled((props.data.length==0 && !props.notdisabledAfter))
    }
  
  }, [props.data])
  
  useEffect(()=>{
    debugger
    if(props.activeValue)
    setValue(props.activeValue)
    else{
      if(props.keepPlaceHolderAtStart){
        setValue({})
        setDisabled((props.data.length==0 && !props.notdisabledAfter))
      }
      else
      { 
        if(props.activeValue){
          setValue(props.activeValue?props.activeValue:{})
        }
        else
        setValue(props.data.length>0 ?props.data[0]:{})
        debugger
        setDisabled((props.data.length==0 && !props.notdisabledAfter))
      }
    }
  },[props.activeValue])
  useEffect(() => {
    if(props.disableColored === true){
      setDisabled(true)
    }else{
      setDisabled(false)
    }
  }, [props.disableColored])
  useEffect(()=>{

    setValue({})

  },[props.templateSelectedValue])
  
  

  return (
    <div className='genericDropdownDiv'>

         <InputLabel className='genericDropdown_label' >{t(props.label)}</InputLabel>
        <Select
        //   focused
        //   labelId="demo-multiple-chip-label"
        //   id="demo-multiple-chip"
        className={(props.disableColored || disabled ||(props.data.length==0 && !props.notdisabledAfter)?'genericDropdown_select disabledColor':'genericDropdown_select ') }
        placeholder='Cordastin'
        value={value}
        displayEmpty={props.keepPlaceHolderAtStart}
        disabled={disabled || (props.data.length==0 && !props.notdisabledAfter) ||props.disableColored }
        onChange={(e:any)=>{console.log(e.target.value); setValue(e.target.value);props.func(e.target.value)}}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          
          if (props.keepPlaceHolderAtStart && !selected[props.name]) {
            return props.placeHolder;
          }else
            return selected[props.name];
        
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
         {props.data.map((name) => (
            <MenuItem
          
              value={name}
              className="brand_name_dropdown"
            >
              {name[props.name]}

            
            </MenuItem>
          ))}
        </Select>
    </div>
  );
}
