import React, { useEffect, useState } from 'react';
import '../../components/errors/Error404.css';
import erroricon from '../../assets/icons/erroricon.svg'
import { useTranslation } from "react-i18next";
export default function ErrorPage(props) {
    const { t } = useTranslation();
    return (
        <>
            <div className='errordiv'>
                <div className='error'>
                    <img src={erroricon} />
                    <h1>ERROR - Page</h1>
                    <p>{t('invalid page')}</p>
                </div>
            </div>
        </>
    )
}


