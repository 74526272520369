import React,{useEffect,useState} from 'react'
import {useSelector} from 'react-redux';
import ParticipantItem from './ParticipantItem';
function ParticipantsList() {
  const room=useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
  const [localParticipant,setLocalParticipant]=useState<any>(null);
  const [totalParticipants,setTotalParticipants]=useState<any>([]);
  const participantConnected = participant => {
    console.log(room)
    setTotalParticipants(Array.from(room.participants.values()));
    };

    const participantDisconnected = participant => {
        setTotalParticipants(Array.from(room.participants.values()));
    };
  useEffect(() => {
    if(room){
        
        setLocalParticipant(room.localParticipant)

        room.on('participantConnected',participantConnected);
        room.on('participantDisconnected',participantDisconnected);
        room.participants.forEach(participantConnected);
        
    }
  }, [room])


  return (
    <div>
        {
        localParticipant?<ParticipantItem local={true} participant={localParticipant}/>:null
        }
        {
            totalParticipants.map(x=><ParticipantItem local={false} participant={x}/>)
        }
    </div>
  )
}

export default ParticipantsList