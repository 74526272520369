import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import DropdownInupt from "../shared/Dropdown";
import { Keyboard } from "@capacitor/keyboard";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { useTranslation } from "react-i18next";
import config from "../../env.json";
import "./preApprovedTemplates.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function PreApprovedTemplates({
  show,
  handleCloseParent,
  selectedTemplate,
}) {
  const { t } = useTranslation();
  const [campaign, setCampaign] = useState([]);
  const { chatId, chatIsPrescriber } = useSelector((state: any) => state.chat);
  const [clickedTemplateId, setClickedTemplateId] = useState(-1); //userId
  const [template, setTemplate] = useState([]);
  const [selectedTemplateData, setSelectedTemplateData] = useState<any>({});
  const [sendButtonDisbale, setSendButtonDisbal] = useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [allCampaign, setAllCampaign] = useState<any>([]);
  const [allWaves, setAllWaves] = useState<any>([]);
  // const refForm=useRef<any>(null);
  const [activeWave, setActiveWave] = useState<any>(null);
  const [activeProduct, setActiveProduct] = useState<any>(null);
  const [activeCampaign, setActiveCampagign] = useState<any>(null); //userId
  const refForm = useRef<any>(null);

  const calcHeightWidth = () => {
    console.log("mobile preapproved");

    let doc: any = document;
    let div: any = document.querySelector("#main");
    // let modalHeaderHeight=doc.getElementsByClassName('modal-header')[0].offsetHeight;
    // let modalSearchHeight=doc.getElementsByClassName('pre_approvetemplate_head')[0].offsetHeight;
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    console.log("gc modal toppad", divpaddingTop);
    console.log("gc modal bottompad", divpaddingbottom);
    console.log("gc modal leftpad", divpaddingleft);
    console.log("gc modal rightpad", divpaddingright);
    console.log(
      "gc modal height",
      window.innerHeight - divpaddingTop - divpaddingbottom
    );
    console.log(
      "gc modal width",
      window.innerWidth - divpaddingleft - divpaddingright
    );
    // console.log("gc modal body height",modalHeaderHeight);
    // console.log("gc modal search body height",modalSearchHeight);
    // pre_approvetemplate_head modal-header

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);

      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  useEffect(() => {
    if (show) {
      getTemplatesFromCampign();
      calcHeightWidth();
    }
    return () => {
      setClickedTemplateId(-1);
      setSelectedTemplateData({});
      setSendButtonDisbal(false);
    };
  }, [show]);

  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    return () => {
      window.removeEventListener("orientationchange", calcHeightWidth);
    };
  }, []);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        calcHeightWidth();
      });

      Keyboard.addListener("keyboardDidHide", () => {
        calcHeightWidth();
      });
    }
  }, []);

  // const
  const selectTemplate = (e, data) => {
    setClickedTemplateId(e);
    console.log(data);
    setSelectedTemplateData(data);
  };
  const sendTemplate = () => {
    debugger;
    let tempData: any = {
      templateData: selectedTemplateData,
      formData: [],
    };
    //     var formElements=document.getElementById("form-").elements;
    // var postData={};
    // for (var i=0; i<formElements.length; i++)
    //     if (formElements[i].type!="submit")//we dont want to include the submit-buttom
    //         postData[formElements[i].name]=formElements[i].value;
    debugger;
    console.log(selectedTemplateData);
    if (selectedTemplateData.FormId) {
      let doc: any = document;
      if (doc.getElementById("form" + selectedTemplateData.FormId))
        var formElements = doc.getElementById(
          "form" + selectedTemplateData.FormId
        ).elements;
      let details: any = [];
      for (var i = 0; i < formElements.length; i++) {
        if (formElements[i].type != "submit")
          details.push({
            idOfFieldToAskFromEmp: formElements[i].name,
            valueOfFieldToAskFromEmp: formElements[i].value,
          });
      }
      tempData.formData = details;
    }

    setSendButtonDisbal(true);
    selectedTemplate(tempData);

    setShowConfirm(true);
  };

  const getTemplatesFromCampign = () => {
    getCampaignsData({ page: 0, pageSize: 10, id: chatId, isPrescriber: chatIsPrescriber }, (res) => {
      if (res.length > 0) {
        let tempAssets: any = [];
        let campaigns: any = [];
        let waves: any = [];
        let products: any = [];

        res.map((x: any) => {
          campaigns.push(x);
          x.type = "Campaign";
          x.idName = ["type", "CampaignName", "CamapignId"];
          x.labelName = "CampaignName";
          x.childName = "WaveDetails";
          x.WaveDetails.map((y: any) => {
            waves.push(y);
            y.type = "Wave";
            y.idName = ["type", "WaveName", "WaveId"];
            y.labelName = "WaveName";
            y.childName = "ProductDetails";
            //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
            y.ProductDetails.map((z: any) => {
              z.waveId = y.WaveId;
              products.push(z);
              z.type = "Product";
              z.idName = ["type", "ProductName", "ProductId"];
              z.labelName = "ProductName";
              z.childName = "TemplateDetails";
              z.TemplateDetails = z.TemplateDetails.sort(function (
                a: any,
                b: any
              ) {
                return a.SortOrder_Nbr - b.SortOrder_Nbr;
              });
              z.TemplateDetails.map((p: any) => {
                p.type = "Template";
                p.productId = z.ProductId;
                p.mainWaveId =
                  p.AssetDetails.length == 0
                    ? p.CampaignWaveId
                    : p.AssetDetails[0].CampaignWaveId;
                if (p.FormId) {
                  p.TemplateName = p.FormName;
                }
                p.idName = ["type", "TemplateName", "TemplateId"];
                p.labelName = "TemplateName";
                p.childName = "AssetDetails";
                tempAssets = tempAssets.concat(p.AssetDetails);
                p.AssetDetails.map((o: any) => {
                  o.mainItem = true;
                  o.type = "Asset";
                  o.idName = ["type", "AssetFile_Nm", "AssetId"];
                  o.labelName = "AssetFile_Nm";
                  return o;
                });
                return p;

                //tempAssets.push(y.campaignWaveAsset);
              });
              return z;
            });
            return y;
          });
          return x;
        });

        setAllCampaign(campaigns);
        let activeC: any = window.localStorage.getItem("activeCampaign");
        if (activeC) {
          let index = campaigns.findIndex(
            (x) => x.CamapignId == JSON.parse(activeC).CamapignId
          );
          if (index >= 0) {
            activeC = JSON.parse(activeC);
            setActiveCampagign(campaigns[index]);
          } else {
            activeC = campaigns[0];
            setActiveCampagign(campaigns[0]);
          }
        } else {
          activeC = campaigns[0];
          setActiveCampagign(campaigns[0]);
        }

        debugger;
        let tempWaves = activeC.WaveDetails ? activeC.WaveDetails : [];
        setAllWaves(tempWaves);

        let activeW: any = window.localStorage.getItem("activeWave");
        debugger;
        if (activeW) {
          let index = tempWaves.findIndex(
            (x) => x.WaveId == JSON.parse(activeW).WaveId
          );
          if (index >= 0) {
            activeW = JSON.parse(activeW);
            setActiveWave(tempWaves[index]);
          } else {
            activeW = tempWaves[0];
            setActiveWave(tempWaves[0]);
          }
        } else {
          activeW = tempWaves[0];
          setActiveWave(tempWaves[0]);
        }

        // let p:any=window.localStorage.getItem('activeWave')
        // setActiveWave(p?JSON.parse(p):null)
        let tempProducts = activeW.ProductDetails ? activeW.ProductDetails : [];
        debugger;
        setCampaign(tempProducts); //campagins is used as products
        let activep: any = window.localStorage.getItem("activeProduct");

        if (activep) {
          let index = tempProducts.findIndex(
            (x) => x.ProductId == JSON.parse(activep).ProductId
          );
          if (index >= 0) {
            activep = JSON.parse(activep);
            setActiveProduct(tempProducts[index]);
          } else {
            activep = tempProducts[0];
            setActiveProduct(tempProducts[0]);
          }
        } else {
          activep = tempProducts[0];
          setActiveProduct(tempProducts[0]);
        }

        // let o=window.localStorage.getItem('activeProduct')
        // setActiveProduct(o?JSON.parse(o):null)
        let tempTemplates = activep.TemplateDetails
          ? activep.TemplateDetails
          : [];
        setTemplate(tempTemplates);

        // getFormsData()
        // setTemplate(tempAssets);
        // setAlllProducts(products);
        // setActiveProducts(products)

        // setAlllWaves(waves);
        // setActiveWaves(waves);
        // setAssets(tempAssets);
      }
    });
  };
  const selectbrand = (e) => {
    console.log("inside brand");
    window.localStorage.setItem("activeProduct", JSON.stringify(e));
    setTemplate(e.TemplateDetails ? e.TemplateDetails : []);
    // setDisableTemplate(false)
    // if(e.TemplateDetails.length==1){
    //   setDisableTemplate(false)
    // }
    // else{
    //   setDisableTemplate(true)
    // }
    setActiveProduct(null);
    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)

    //  getFormsData(1001)
  };
  const selectWave = (e) => {
    // setDisableTemplate(true)
    window.localStorage.setItem("activeWave", JSON.stringify(e));
    //setActiveWave(e);
    setActiveWave(null);
    setActiveProduct(null);
    debugger;
    setCampaign(e.ProductDetails ? e.ProductDetails : []);
    if (e.ProductDetails.length == 1) {
      selectbrand(e.ProductDetails[0]);
    }
    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)
  };
  const selectCampaign = (e) => {
    // setDisableTemplate(true)
    window.localStorage.setItem("activeCampaign", JSON.stringify(e));
    //setActiveCampagign(e)
    setActiveWave(null);
    setActiveProduct(null);
    setAllWaves(e.WaveDetails ? e.WaveDetails : []);
    if (e.WaveDetails.length == 1) {
      selectWave(e.WaveDetails[0]);
    }
    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        // onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="pre_approvetemplate"
      >
        <Fade in={show}>
          <Box>
            <div
              className="pre-approve-modal-box"
              style={
                mobileStyle
                  ? { width: modalWidth + "px", height: modalHeight + "px" }
                  : { height: "100%" }
              }
            >
              <div id="ModalHeader" className="modal-header d-flex">
                <h1 className="font-newchat">{t("Pre-Approved Messages")}</h1>
                <span onClick={handleCloseParent} className="close">
                  ×
                </span>
              </div>
              <div id="ModalSearch" className="pre_approvetemplate_head">
                {allCampaign.length > 1 ? (
                  <div>
                    <DropdownInupt
                      activeValue={activeCampaign}
                      disableColored={false}
                      keepPlaceHolderAtStart={false}
                      placeHolder={t("Select a campaign")}
                      label="Campaign"
                      data={allCampaign}
                      id=""
                      name="CampaignName"
                      func={(e) => {
                        selectCampaign(e);
                      }}
                    />
                  </div>
                ) : null}
                {allWaves.length > 1 ? (
                  <div className="mt-3">
                    <DropdownInupt
                      activeValue={activeWave}
                      disableColored={false}
                      keepPlaceHolderAtStart={allWaves.length > 1}
                      placeHolder={t("Select a wave")}
                      label="Wave"
                      data={allWaves}
                      id=""
                      name="WaveName"
                      func={(e) => {
                        selectWave(e);
                      }}
                    />
                  </div>
                ) : null}
                {campaign.length > 1 ? (
                  <div className="mt-3">
                    <DropdownInupt
                      activeValue={activeProduct}
                      disableColored={false}
                      keepPlaceHolderAtStart={campaign.length > 1}
                      placeHolder={t("Select a brand")}
                      label="Brand"
                      data={campaign}
                      id=""
                      name="ProductName"
                      func={(e) => {
                        debugger;
                        setClickedTemplateId(-1);
                        setTemplate(e.TemplateDetails);
                      }}
                    />
                  </div>
                ) : null}
                {/* <DropdownInupt label="Select Brand" data={campaign} id="Id" name="ProductName"  func={(e)=>{
                    setClickedTemplateId(-1)
                    setTemplate(e.TemplateDetails);
                  }}/> */}
              </div>

              <div className="modal-body pre_approvetemplate_body">
                <ul className="template-modal-list">
                  {template.map((item: any, index: number) => {
                    return (
                      <Accordion
                        expanded={
                          selectedTemplateData.TemplateId == item.TemplateId
                        }
                        onChange={(e) => {
                          if (
                            selectedTemplateData.TemplateId == item.TemplateId
                          ) {
                            setClickedTemplateId(-1);
                            setSelectedTemplateData({});
                          } else {
                            selectTemplate(index, item);
                          }
                        }}
                      >
                        <AccordionSummary
                          className="template-modal-body-title accordian-title"
                          expandIcon={
                            <ExpandMoreIcon
                              className="primary"
                              style={{ fontSize: 32 }}
                            />
                          }
                        >
                          {item.TemplateName}
                        </AccordionSummary>
                        <AccordionDetails>
                          <p className="template-modal-body-content">
                            {item.TemplateBody}
                          </p>
                          {item.AssetDetails && (
                            <div>
                              {item.AssetDetails.length > 0 && (
                                <>
                                  <p className="template-modal-body-assets">
                                    <b> Assets </b>{" "}
                                    <span className="badge badge-info">
                                      {item.AssetDetails.length}
                                    </span>
                                  </p>
                                  <ul className="template-modal-body-footer">
                                    {item.AssetDetails.map((x) => (
                                      <li>
                                        <a
                                          href={x.AssetFile_Url}
                                          target="__blank"
                                          className="d-block"
                                        >
                                          {x.Asset_Nm}
                                        </a>
                                        {x.AssetPreview_Url && (
                                          <img
                                            className="asset-img-preview"
                                            src={x.AssetPreview_Url}
                                          ></img>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}
                            </div>
                          )}
                          {item.FormId && (
                            <div className="template_body_form">
                              <form id={"form-" + item.FormId}>
                                {item.FieldDetails.map((x: any, i: any) => (
                                  <>
                                    <p className="para">{x.FieldName}</p>
                                    <textarea
                                      name={x.FieldId}
                                      id={x.FieldId}
                                    ></textarea>
                                  </>
                                ))}
                              </form>
                            </div>
                          )}
                          {clickedTemplateId === index && (
                            <div className="floating_btn preapp-plane">
                              <button
                                disabled={sendButtonDisbale}
                                className={
                                  sendButtonDisbale === true ? "disbaleBtn" : ""
                                }
                                onClick={sendTemplate}
                              >
                                <i className="far fa-paper-plane "></i>
                                {/* <i className="fas fa-paper-plane-top"></i> */}
                              </button>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
