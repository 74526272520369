import React, { useState, useEffect } from "react";
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import '../../components/shared/Dropdown.css'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// const names = [
//   'Cordastin'
// ];

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultiselectDropdown(props) {
  const theme = useTheme();
  const [value,setValue]=useState([props.selectedData]);
  const [checkValues,setCheckValues]=useState<String[]>([])
  const handleChange = (event:any) => {
    
    // let index=value.findIndex((x:any)=>x[props.id]==event.target.value[props.id]);
    // let tempValue:any=value;
    // if(index >=0 )
    // { 
    //   tempValue.splice(index,1);
    // }else{
    //   tempValue.push(event.target.value);
    // }
    if(!props.disable){
      setValue(event.target.value)
      setCheckValues(event.target.value.map(x=>x[props.id]))
      props.func(event.target.value)
    }
   
  };

 
  

  useEffect(() => {
    debugger
   setValue(props.selectedData.length>0?props.selectedData:[])
   setCheckValues(props.selectedData.map(x=>x[props.id]))
   
  }, [props])
  

 
  return (
    <div className='genericDropdownDiv'>

          {/* <label className='newchat_brand_label'>{props.label}</label>
            <select  className='newchat_brand_select'>
            {props.data.map((name) => (
              <option value={name}>{name}</option>
            ))}
             
            </select> */}
        <InputLabel className='genericDropdown_label' >{props.label}</InputLabel>
        <Select
        // disabled={props.disable}
        onChange={handleChange}
        className='genericDropdown_select generic_form_select'
        placeholder='Cordastin'
        value={value}
        // onChange={(e:any)=>{setValue(e.target.value);props.func(e.target.value)}}
        input={<OutlinedInput />}
        renderValue={(selected:any) => {
           console.log(selected)
          return selected.map((x:any)=>x[props.name]).join(',');
          }}
          MenuProps={MenuProps}
          multiple
        >
         {props.data.map((name:any) => (
            <MenuItem
              // key={name[props.id]}
              value={name}
              // style={getStyles(name, personName, theme)}
              className="brand_name_dropdown multi-select-itemlist"
            >
              {
                checkValues.indexOf(name[props.id])>=0?
                <Checkbox checked={true}/>
                :
                <Checkbox checked={false}/>
              }
              {name[props.name]}
            </MenuItem>
          ))}
        </Select>
    </div>
  );
}
