// import React, { useState, useEffect } from 'react';
// import Video from 'twilio-video';
// import { useSelector, useDispatch } from 'react-redux'
// import Participant from './Participant';
// import CallEndIcon from '@mui/icons-material/CallEnd';
// import './VideoCall.css'
// import Popper from '@mui/material/Popper';

// import Paper from '@mui/material/Paper';
// import {setVideoCallDetails, setVideoCallShowFloater,setVideoCallRoom,setVideoOtherParticipants,setVideoLocalParticipant} from '../../../app/slice/videoCallSlice';
// import {ReactComponent as RecordButton} from '../../../assets/icons/Record.svg';
// import {ReactComponent as VideoEnable} from '../../../assets/icons/VideoEnable.svg';
// import {ReactComponent as Setting} from '../../../assets/icons/settings.svg';
// import {ReactComponent as Chat} from '../../../assets/icons/Chat.svg';
// import {ReactComponent as Reactions} from '../../../assets/icons/Reaction.svg';
// import {ReactComponent as Mute} from '../../../assets/icons/MuteIcon.svg';
// import {ReactComponent as Unmute} from '../../../assets/icons/Unmute.svg';
// import {ReactComponent as CallEnd} from '../../../assets/icons/end-call.svg';
// import {ReactComponent as VideoDisable} from '../../../assets/icons/VideoDisable.svg';


// function VideoCall() {
//     const dispatch=useDispatch();
//     const show = useSelector((state: any) => state.floaterVideoCall.videoCallShowFloater)
//     //const room=useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
//     const token:any=useSelector((state: any) => state.floaterVideoCall)
//   const [room, setRoom] = useState<any>(null);
//   const [participants, setParticipants] = useState<any>([]);
//   const [toggleChat,setToggleChat]=useState(true)
//   const [toggleVideo,setToggleVideo]=useState(true);
//   const [toggleVoice,setToggleVoice]=useState(true);
//   const [height,setHeight]=useState(10);
//   const [width,setWidth]=useState(10);
  

//   useEffect(() => {
//     dispatch(setVideoCallShowFloater(false))
//         const participantConnected = participant => {
//           console.log(participant)
//             setParticipants(prevParticipants => [...prevParticipants, participant]);
//           };
      
//           const participantDisconnected = participant => {
//             console.log(participant)
//             setParticipants(prevParticipants =>
//               prevParticipants.filter(p => p !== participant)
//             );
//           };
//           Video.connect(token.videoCallToken, {
//               name: token.videoCallId
//             }).then((room:any) => {
//               setRoom(room);
//               dispatch(setVideoCallRoom(room));
//               room.on('participantConnected',
//                participantConnected);
//               room.on('participantDisconnected', participantDisconnected);
//               room.participants.forEach(participantConnected);
              
//             });
      
//           return () => {
//             // dispatch(setVideoCallShowFloater(true))
//             setRoom(currentRoom => {
//               if (currentRoom && currentRoom.localParticipant.state === 'connected') {
//                 currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
//                   trackPublication.track.stop();
//                 });
//                 currentRoom.disconnect();
//                 return null;
//               } else {
//                 return currentRoom;
//               }
//             });
//           };
    
   
//   }, [ token,show]);
//   useEffect(() => {
//     let doc:any=document;
//     let h=doc.getElementsByClassName('participantsDiv')[0].offsetHeight;
//     let w=doc.getElementsByClassName('participantsDiv')[0].offsetWidth;
//     setHeight(h);
//     setWidth(w);
//     return () => {
//       dispatch(setVideoCallShowFloater(true))
//     }
//   }, [])
  
//   const remoteParticipants = participants.map(participant => (
//     <Participant video={false} mute={false} key={participant.sid} participant={participant} parentWidth={width}
//     parentHeight={height}
//     children={ participants.length} />
//   ));
//   return (
//     <div className="room">
//     {/* <h2>Room: {token.videoCallName}</h2>
//     <button onClick={()=>{}}>Log out</button>
//     <div className="local-participant">
//       {room ? (
//         <Participant
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//         />
//       ) : (
//         ''
//       )}
//     </div>
//     <h3>Remote Participants</h3>
//     <div className="remote-participants">{remoteParticipants}</div>
//     <div>
    
//     </div> */}
//     <div className='sectionVideo'>
//          <div className='sectionVideoMain'>
//         <div className='participantsDiv'>
//           {/* <div id="remote-media-div">

//           </div> */}
//          {/* {room ? (
//         <Participant
//         video={false}
//         mute={!toggleVoice}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//         />
//       ) : (
//         null
//       )} */}
//       {room ? (
//         <Participant
//         mute={toggleVoice}
//         video={toggleVideo}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//           parentWidth={width}
//           parentHeight={height}
//           children={1}
//         />
//       ) : (
//         ''
//       )}
//         {/* {room ? (
//         <Participant
//         mute={false}
//         video={false}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//           parentWidth={width}
//           parentHeight={height}
//           children={5}
//         />
//       ) : (
//         ''
//       )}
//         {room ? (
//         <Participant
//         mute={false}
//         video={false}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//           parentWidth={width}
//           parentHeight={height}
//           children={5}
//         />
//       ) : (
//         ''
//       )}
//       {room ? (
//         <Participant
//         mute={false}
//         video={false}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//           parentWidth={width}
//           parentHeight={height}
//           children={5}
//         />
//       ) : (
//         ''
//       )} */}
//       {/* {room ? (
//         <Participant
//         mute={false}
//         video={false}
//           key={room.localParticipant.sid}
//           participant={room.localParticipant}
//           parentWidth={width}
//           parentHeight={height}
//           children={4}
//         />
//       ) : (
//         ''
//       )} */}
     
//       {remoteParticipants}
//       </div>
//         </div>        
//         <div className='sectionVideoActions'>
//             <div className='actionsDiv'>
//                 <div><RecordButton style={{fill:"var(--primary-color)"}} /></div>
                
//                 <div>{
//                   toggleVoice?
//                   <Mute onClick={()=>{room.localParticipant.audioTracks.forEach(publication => {
//                     publication.track.disable();
//                   });;setToggleVoice(!toggleVoice);
                    
                    
                    

//                   }} style={{fill:"var(--primary-color)"}} />:
//                   <Unmute onClick={()=>{room.localParticipant.audioTracks.forEach(publication => {
//                     publication.track.enable();
//                   });setToggleVoice(!toggleVoice)}} style={{fill:"var(--primary-color)"}} />
//                   }</div>
//                 <div>{
//                   toggleVideo?
//                   <VideoEnable onClick={()=>{
//                     room.localParticipant.videoTracks.forEach((publication:any) => {
                      
//                    // publication.unpublish();
//                     publication.track.disable();
//                   }); 
                  
//                   setToggleVideo(!toggleVideo)}} style={{fill:"var(--primary-color)"}}/>
//                   :
//                   <VideoDisable onClick={()=>{
//                     room.localParticipant.videoTracks.forEach((publication:any) => {
                      
//                       publication.track.enable();
//                     });setToggleVideo(!toggleVideo)}} style={{fill:"var(--primary-color)"}}/>
//                   }</div>
//                 <div>
                  
//                   <Setting style={{fill:"var(--primary-color)"}}/></div>
//                 <div><Chat onClick={()=>setToggleChat(!toggleChat)} style={{fill:"var(--primary-color)"}}/></div>
//                 <div><CallEndIcon onClick={()=>{
//                   room.localParticipant.tracks.forEach(publication => {
//                   const attachedElements = publication.track.detach();
//                   publication.track.stop();
//                       attachedElements.forEach(element => element.remove());
//                   });
//                 room.disconnect();
                
//                 setRoom(null)}} fontSize='large' color='error'/></div>
//                 <div><Reactions style={{fill:"var(--primary-color)"}}/></div>
//             </div>
//         </div>        

//     </div>
//     {
//       toggleChat?
//       <div className='sectionChat'>
//       <div className='sectionChatMain'>
//           <div className='videochatContainer'>
//             msg
//           </div>
//           <div className='videochatfooter'>
//           <input placeholder='Write your message..'/>
//                  <button
//                               className="btn btn-light"
//                               onClick={()=>{}}
//                               >
//                               <i
//                                   data-v-4d521fc4=""
//                                   data-name="send"
//                                   data-tags="message,mail,email,paper airplane,paper aeroplane"
//                                   data-type="send"
//                                   className="feather feather--send i-2"
                                  
//                               >
//                               <svg
//                                       style={{ color: '#734bd1' }}
//                                   data-v-4d521fc4=""
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   width="18"
//                                   height="18"
//                                   viewBox="0 0 24 24"
//                                   fill="none"
//                                   stroke="currentColor"
//                                   strokeWidth="2"
//                                   strokeLinecap="round"
//                                   strokeLinejoin="round"
//                                   className="feather feather-send feather__content"
//                               >
//                                   <line x1="22" y1="2" x2="11" y2="13"></line>
//                                   <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
//                               </svg>
//                           </i>
//                       </button>
//           </div>
      
//               </div>  
//           </div>:null
//     }
 
//   </div>
//   )
// }

// export default VideoCall

import React, { useState, useEffect, useRef } from 'react';

import Video,{connect,createLocalTracks, createLocalVideoTrack, LocalVideoTrack,LocalDataTrack} from 'twilio-video';
import { useSelector, useDispatch } from 'react-redux'
import Participant from './Participant';
import CallEndIcon from '@mui/icons-material/CallEnd';
import './VideoCall.css'
import Popper from '@mui/material/Popper';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';

import {setVideoCallDetails, setVideoCallShowFloater,setVideoCallRoom,setVideoOtherParticipants,setVideoLocalParticipant,setVideoCallCallStarted,setVideoCallEnded, setVideoCallScreenTrack, setVideoCallPdfTronStarted, setVideoCallChats} from '../../../app/slice/videoCallSlice';
import {ReactComponent as RecordButton} from '../../../assets/icons/Record.svg';
import {ReactComponent as VideoEnable} from '../../../assets/icons/VideoEnable.svg';
import {ReactComponent as Setting} from '../../../assets/icons/settings.svg';
import {ReactComponent as Chat} from '../../../assets/icons/Chat.svg';
import {ReactComponent as Reactions} from '../../../assets/icons/Reaction.svg';
import {ReactComponent as Mute} from '../../../assets/icons/MuteIcon.svg';
import {ReactComponent as Unmute} from '../../../assets/icons/Unmute.svg';
import {ReactComponent as CallEnd} from '../../../assets/icons/end-call.svg';
import {ReactComponent as VideoDisable} from '../../../assets/icons/VideoDisable.svg';
import {ReactComponent as ScreenShare} from '../../../assets/icons/Screenshare.svg';
import ZingLogoHomePage from '../../../assets/images/ZingLogoHomePage.png'
import { Tooltip } from "@mui/material";
// import {VirtualBackgroundProcessor,GaussianBlurBackgroundProcessor,GaussianBlurBackgroundProcessorOptions } from '@twilio/video-processors';
// import * as VideoProcessors from '@twilio/video-processors';

// import {  GaussianBlurBackgroundProcessor,VirtualBackgroundProcessor } from '@twilio/video-processors';


import axios from 'axios';

import config from '../../../env.json'
// import { CollabClientProvider } from '@pdftron/collab-react';
import { CollabClient } from '@pdftron/collab-client';
import WebViewer from '@pdftron/webviewer'
import {ReactComponent as ParticipantsIcon} from '../../../assets/icons/ParticipantsIcon.svg';
import ParticipantsList from './ParticipantsList';
import { Roofing } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import {getDeviceType} from '../../../utils/deviceTypeUtil';


function VideoCall() {
  const { t } = useTranslation();
    const dispatch=useDispatch();
    const show = useSelector((state: any) => state.floaterVideoCall.videoCallShowFloater)
    const room=useSelector((state: any) => state.floaterVideoCall.videoCallRoom)
    const token:any=useSelector((state: any) => state.floaterVideoCall)
    const screenTrack=useSelector((state: any) => state.floaterVideoCall.videoCallScreenTrack)
    const videoCallScreenShareStarted=useSelector((state: any) => state.floaterVideoCall.videoCallScreenShareStarted)
    const videoCallShareUserId=useSelector((state: any) => state.floaterVideoCall.videoCallShareUserId)
    const videoCallAnnotaion=useSelector((state: any) => state.floaterVideoCall.videoCallAnnotaion)
    const videoCallPdfTronStarted=useSelector((state:any)=>state.floaterVideoCall.videoCallPdfTronStarted)
    const chatsNew:any=useSelector((state:any)=>state.floaterVideoCall.videoCallChats)
    const videoCallName=useSelector((state:any)=>state.floaterVideoCall.videoCallName);
    const videoCallUrl=useSelector((state:any)=>state.floaterVideoCall.videoCallUrl);
    // const RandomToken=useSelector((state:any)=>state.floaterVideoCall.RandomToken);
    // const OTP=useSelector((state:any)=>state.floaterVideoCall.OTP);
     const videoCallId=useSelector((state:any)=>state.floaterVideoCall.videoCallId);
    // const UserName=useSelector((state:any)=>state.floaterVideoCall.UserName)
  // const [room, setRoom] = useState<any>(null);
  const [participants, setParticipants] = useState<any>([]);
  const [localParticipant,setLocalParticipant]=useState<any>(null);
  const [toggleChat,setToggleChat]=useState(true)
  const [toggleVideo,setToggleVideo]=useState(true);
  const [toggleVoice,setToggleVoice]=useState(true);
  const [height,setHeight]=useState(10);
  const [width,setWidth]=useState(10);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [dominantSid,setDominantSid]=useState<any>(0)
  const [sharingMode,setSharingMode]=useState(false)//for other participant share mode;
  const [sharingParticipant,setSharingParticipant]=useState<any>(null);
  const [chatInput,setChatInput]=useState('')
  const [chats,setChats]=useState<any>([]);
  const [dataTrackReady,setDataTrackReady]=useState<any>({})
  const [client,setClient]=useState<any>(null)
  const [AnnotationManagerNew,setAnnotationManagerNew]=useState<any>(null);
  const [annotation,setAnnotation]=useState('')
  const [toggleParticipant,setToggleParticipant]=useState(false)
  const [isMobile, setIsMobile] = useState(false);
  const serializer = new XMLSerializer();
  const [toggleChatMobile,setToggleMobileChat]=useState(false)
   const [heightOfroom,setHeightOfRoom]=useState(10)
  const [inactiveParticipant,setInactiveParticipant]=useState<any>([])
   const [localChats,setLocalChats]=useState([])
   const [chatMsg,setChatMsg]=useState<any>(null)
   const [blurPro,setBlurPro]=useState<any>(null);
   const [infiniteScrollHeight,setInfiniteScrollHeight]=useState(0);
   const [localRoom,setLocalRoom]=useState<any>(null);

   useEffect(()=>{
    console.log(chatsNew)
    debugger
    if(chatsNew){
      
      setChats(chatsNew)
    }
  },[chatsNew])
  const viewer=useRef<any>(null)
  
  useEffect(() => {
    
    

   
     
    
    
    let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
       setIsMobile(true);
     } else setIsMobile(false);
     window.addEventListener(
       "resize",
       function (event) {
        
          let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
           setIsMobile(true);
         } else setIsMobile(false);
       },
       true
     );
   }, []);
    
   if(room){
    debugger
   }
  const handlePopoverOpen = (event: React.MouseEvent<any>) => {
    if(anchorEl)
    setAnchorEl(null);
    else
    setAnchorEl(event.currentTarget);
  };


  const open = Boolean(anchorEl);
  const participantConnected = participant => {
    console.log(room)
    // console.log(participant.identity,'viddeo connected')
    // let part=participants;
    // let index=participants.findIndex(x=>x.sid==participant.sid)
    // if(index>=0){
    //   part[index]=participant;
    // }
    // else
    // part.push(participant);
    
    // console.log(part.length,'viddeo connected')
    let part:any=Array.from(room.participants.values());
    part.map((x:any)=>{
      // if(part.dataTracks instanceof Map){
        
      //   let dTracks=trackpubsToTracks(part.dataTracks);
      //   let dt=dTracks[0]
      //   if(dt){
      //     dt.on('message', message => {
      //       if(JSON.parse(message).type=="message"){
      //         // let ct=chatsNew;
      //         // ct = [...ct,JSON.parse(message).value]
      //         // dispatch(setVideoCallChats(ct))
      //       }
      //   }
      //   }
      // }
    })
    let lRoom=room;
    let allPart:any=Array.from(lRoom.participants.values())//participants.concat(inactiveParticipant).push(participant)//Array.from(room.participants.values());
    allPart=allPart.filter((p:any)=> p.state!="disconnected");
    let mainPartlength=8
    if(isMobile){
      mainPartlength=4;
    }
    setParticipants(allPart.filter((x,i)=>i<mainPartlength));
    setInactiveParticipant(allPart.filter((x,i)=>i>=mainPartlength));
    // dispatch(setVideoCallRoom(lRoom))
    };

    const participantDisconnected = participant => {
      console.log(room)
      // console.log(participant.identity,'viddeo disconnected')
      // let part=participants;
      // let index=participants.findIndex(x=>x.sid==participant.sid)
      // part=part.filter((p,i)=> i!== index);
      // console.log(part.length,'viddeo disconnected')
    let allPart:any=Array.from(room.participants.values());
    allPart=allPart.filter((p:any)=> p.state!="disconnected" && p.sid!=participant.sid);
    let mainPartlength=8
    if(isMobile){
      mainPartlength=4;
    }
    let p=[];
    p=p.concat(allPart.filter((x,i)=>i<mainPartlength));
    setParticipants(p);
    setInactiveParticipant(allPart.filter((x,i)=>i>=mainPartlength));
    let lroom=room
    lroom.participants=new Map(allPart);
    dispatch(setVideoCallRoom(lroom))
    };
  // useEffect(() => {
  //   dispatch(setVideoCallShowFloater(false))
  //       // const participantConnected = participant => {
  //       //   console.log(participant)
  //       //     setParticipants(prevParticipants => [...prevParticipants, participant]);
  //       //   };
      
  //       //   const participantDisconnected = participant => {
  //       //     console.log(participant)
  //       //     setParticipants(prevParticipants =>
  //       //       prevParticipants.filter(p => p !== participant)
  //       //     );
  //       //   };
  //         // Video.connect(token.videoCallToken, {
  //         //     name: token.videoCallId
  //         //   }).then((room:any) => {
  //         //     // setRoom(room);
  //         //     dispatch(setVideoCallRoom(room));
  //             // room.on('participantConnected',
  //             //  participantConnected);
  //             // room.on('participantDisconnected', participantDisconnected);
  //             // room.participants.forEach(participantConnected);
              
  //           });
      
  //         return () => {
  //           // dispatch(setVideoCallShowFloater(true))
  //           // setRoom(currentRoom => {
  //           //   if (currentRoom && currentRoom.localParticipant.state === 'connected') {
  //           //     currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
  //           //       trackPublication.track.stop();
  //           //     });
  //           //     currentRoom.disconnect();
  //           //     return null;
  //           //   } else {
  //           //     return currentRoom;
  //           //   }
  //           // });
  //         };
    
   
  // }, [ token,show]);
  useEffect(() => {
    
    console.log(room)
   if(room){
    
   
    
      
    if(room._options){

      setLocalParticipant(room.localParticipant)
      room.on('participantConnected',participantConnected);
      room.on('participantDisconnected',participantDisconnected);
      room.participants.forEach(participantConnected);
      room.on('participantReconnecting',(e)=>{console.log('reconnecting')})
      room.on('dominantSpeakerChanged', participant => {

        if(participant){
          let i=participants.findIndex(x=>x.sid==participant.sid);
          if(i<0){
            let lastActive=participants.pop();
            participants.push(participant);
            let ii=inactiveParticipant.findIndex(x=>x.sid==participant.sid);
            if(ii>=0)
            inactiveParticipant[ii]=lastActive;
            setParticipants(participants);
            setInactiveParticipant(inactiveParticipant);
          }
        setDominantSid(participant.sid)
        //setParticipants(participants)
        }
        else {
          setDominantSid(0)
          // setParticipants(participants)
        }
        
      });
      room.localParticipant.videoTracks.forEach((publication:any) => {
        if(!publication.track.isEnabled){
        publication.track.disable();
       setToggleVideo(false) 
       }
       else{
        publication.track.enable();
       setToggleVideo(true) 
       }
    });
    room.localParticipant.audioTracks.forEach((publication:any) => {
     if(!publication.track.isEnabled){
     publication.track.disable();
    setToggleVoice(false) 
    
    }
    else{
      publication.track.enable();
      setToggleVoice(true) 
     }
 });

    }
    else{
    //to reconect 
    reconnect()
    
    }
    
   }
  else{
    setParticipants([])
    setLocalParticipant(null)
  }
    return () => {
      
    }
    
  }, [room])
  useEffect(()=>{
    if(room){
      room.on('particiapntDisconnected',(e)=>{console.log('dis')})
    }
  })
  
  const reconnect=()=>{
    
    let item=videoCallUrl;
      let temp=item.split('?')[1].split('&');
      let randomToken=temp[0].split("=")[1];
      let name=temp[1].split("=")[1];
      let otp=temp[2].split("=")[1];
  
      debugger
      // dispatch(setVideoCallDetails({
      //   videoCallName:item.RoomName,
      // videoCallToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzEzNWFmMzEwMTQ1ZjFkYmZiMTI5NjEwOWUwZTUwYmVlLTE2NjEyNzYwOTAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJwYXJhZyIsInZpZGVvIjp7fSwiZGF0YV9zeW5jIjp7InNlcnZpY2Vfc2lkIjoiSVNiOTllOTc3MGFlNGUxOTJhOTI2M2RmZmVjNjZmZDBmNSJ9fSwiaWF0IjoxNjYxMjc2MDkwLCJleHAiOjE2NjEyNzk2OTAsImlzcyI6IlNLMTM1YWYzMTAxNDVmMWRiZmIxMjk2MTA5ZTBlNTBiZWUiLCJzdWIiOiJBQzE5YTljNzRjZGQ5YTNmMzRkNDQ4YjI4Y2IyZTFjYjE0In0.KLzFwRJUjp71jziX9cQId3yHdi__RvHbXv4LjcRl4QY",
      // videoCallId:item.RoomId,
      // videoCallUrl:item.WebHookUrl,
      // }))
      // // window.open(url, '_blank', 'noopener,noreferrer');
      // dispatch(setVideoCallShowFloater(true))
     
      console.log('before')
      axios.get(config.REACT_APP_VIDEO_API_BASE+'/meeting/GetAuthTokenFromZing',{params:{
        RandomToken:randomToken,
        OTP: otp,
        RoomId:videoCallId,
        UserName:name
      }}).then((res)=>
      {
        console.log(res)
        
        dispatch(setVideoCallDetails({
        videoCallName:videoCallName,
        videoCallToken:res.data.data,
        videoCallId:videoCallId,
        videoCallUrl:videoCallUrl,
        }))
        connect(res.data.data, {
          name:videoCallId,
          audio:true,
          video:true,
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
          dominantSpeaker: true,
          
        }).then((room:any) => {
          room.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1
          });
          room.localParticipant.publishTrack(new LocalDataTrack());
          dispatch(setVideoCallShowFloater(false))
          dispatch(setVideoCallRoom(room));
          dispatch(setVideoCallEnded(false))
          dispatch(setVideoCallCallStarted(true))

        })
       
      })
  
  
    
  }
  
  const sendChat=()=>{
   
   let payload=  {
    "UserName": room.localParticipant.identity,
    "RoomId": room.name,
    "Message": chatInput,
    "CreatedOn":new Date().toISOString(),
    "MessageType":"chat"
    }
    setChatMsg(payload)
  
    // let temp:any=localChats//chatsNew; 
    //       temp = [...temp, payload]
    //      setLocalChats(temp)
          //setChats(temp)
         // dispatch(setVideoCallChats(temp))
          // setLocalChats(localChats.push);
    room.localParticipant.dataTracks.forEach(element => {
    
      element.track.send(JSON.stringify({"type":"message","value":payload}))
      });

    setChatInput('')

   axios.post(config.REACT_APP_VIDEO_API_BASE+'/meeting/InsertChat',payload).
   then((res)=>{
    setChatInput('')
   }).catch((err)=>
   {
    console.log(err);
   })

  }
  useEffect(() => {
    let doc:any=document;
    let div:any=document.querySelector('#main') 
    console.log("gc MobHeader"+doc.getElementById('MobHeader').offsetHeight);
    console.log("gc Header"+doc.getElementById('headerVal').offsetHeight);
    // console.log("search"+doc.getElementById('search').offsetHeight);
    console.log("gc footer"+doc.getElementById('footer').offsetHeight);     
    let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
   
    setInfiniteScrollHeight(window.innerHeight-(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom);


    let mainH= window.innerHeight-(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom//doc.getElementById('footer').offsetTop-doc.getElementById('videoRoom').offsetTop;
    // setHeightOfRoom(mainH)
    // debugger
    doc.getElementById('videoRoom').style.height=mainH+"px"

    
    dispatch(setVideoCallShowFloater(false))
   
    // WebViewer({licenseKey:undefined,path:''},doc.getElementById('viewer')).then()
    
    
    let h=doc.getElementsByClassName('sectionVideoMain')[0].offsetHeight;
    let w=doc.getElementsByClassName('sectionVideoMain')[0].offsetWidth;
    // //let doc:any=document
   
    // //let h=window.innerHeight - doc.getElementById('videoRoom').offsetTop-50
     setVariables(h,w);
    setHeight(h);
    setWidth(w);
    return () => {
      console.log(room)
      debugger
      if(room){
        dispatch(setVideoCallShowFloater(true))
      }
      else
      dispatch(setVideoCallShowFloater(false))
      
    }
  }, [])
  const setVariables=(h,w)=>{
    
    // var r:any= document.querySelector(':root');
    // r.style.setProperty('--one-row', h );
    // r.style.setProperty('two-rows', h h);
    
    document.documentElement.style.setProperty('--one-row',h+'px');

    document.documentElement.style.setProperty('--two-rows','repeat(2,'+h/2+'px)');

    document.documentElement.style.setProperty('--three-rows','repeat(3,'+h/3+'px)');

    document.documentElement.style.setProperty('--four-rows','repeat(4,'+h/4+'px)');

    
    // --two-rows: repeat(2, calc(100vh / 2));
    // --three-rows: repeat(3, calc(100vh / 3));
    // --four-rows: repeat(4, calc(100vh / 4));
  }
  const remoteAnnote = async(annotationManagerNew)=>{ 
    debugger;
    const annotations = await annotationManagerNew.importAnnotCommand(videoCallAnnotaion);
    await annotationManagerNew.drawAnnotationsFromList(annotations);
   }
 
  useEffect(()=>{

    if(videoCallAnnotaion){
     
        
     if(AnnotationManagerNew){
    
      remoteAnnote(AnnotationManagerNew);
      
     }
    
    }
  },[videoCallAnnotaion])
  useEffect(() => {
    
    if(videoCallScreenShareStarted && videoCallShareUserId){
      room.participants.forEach(x=>{
        if(x.sid==videoCallShareUserId)
        {
          setSharingParticipant(x)
          setSharingMode(true)
          //dispatch(setVideoCallShowFloater(true))

        }
      });
      
    }
    else{
        setSharingParticipant(null)
        setSharingMode(false)
        //dispatch(setVideoCallShowFloater(false))
    }
   
  }, [videoCallScreenShareStarted,videoCallShareUserId])
  

  useEffect(()=>{
    console.log('hi')
    debugger
    if(chatMsg){
      console.log(chatsNew)
      let t=[...chatsNew, chatMsg]
      dispatch(setVideoCallChats(t))
    }
    
    // setChats(chats => [...chats, chatMsg]);
  },[chatMsg])
  const remoteParticipants = participants.map((participant:any,i:any) => (
    <Participant 
    annotationset={(data:any)=>{
      
     
      setChatMsg(data)
       
              
    }}
    disconnected={(e)=>{
      debugger
     participantDisconnected(e)
    }}
     dominant={participant.sid==dominantSid} video={true} mute={false} key={participant.sid} participant={participant} parentWidth={width}
    parentHeight={height}
    children={i+2} />
  ));
  const inactiveParticipantsDiv = inactiveParticipant.map((participant:any,i:any) => (
    <Participant 
    annotationset={(data:any)=>{
      setChatMsg(data)
    }} dominant={participant.sid==dominantSid} video={false} mute={false} key={participant.sid} participant={participant} parentWidth={width}
    parentHeight={height}
    children={i+2} />
  ))
  const screenshare=()=>{
    console.log('hi',screenTrack)
    
    if(screenTrack){
      room.localParticipant.unpublishTrack(screenTrack);
      screenTrack.stop();
      room.localParticipant.dataTracks.forEach(element => {    

        element.track.send(JSON.stringify({"type":"screenshare","value":false}))
  
      });
      dispatch(setVideoCallScreenTrack(null))
    }else{
      
    navigator.mediaDevices.getDisplayMedia({
      video: true
    }).
    then(stream => {
     let screenTrack:any = (stream.getTracks()[0]);
     console.log(screenTrack)
     screenTrack = new LocalVideoTrack(screenTrack,{name:'user-screen',logLevel:'info'})
     screenTrack.on('disable',()=>{console.log('disable')})
     screenTrack.on('stopped',()=>{
      console.log('ended')
          room.localParticipant.unpublishTrack(screenTrack)
          room.localParticipant.dataTracks.forEach(element => {    

            element.track.send(JSON.stringify({"type":"screenshare","value":false}))
      
          });
          console.log(room)
          screenTrack.stop();
          dispatch(setVideoCallScreenTrack(null))
     })
    //  screenTrack.on('disable',()=>{console.log('disable')})

     dispatch(setVideoCallScreenTrack(screenTrack))
        room.localParticipant.publishTrack(screenTrack);
        room.localParticipant.dataTracks.forEach(element => {    

          element.track.send(JSON.stringify({"type":"screenshare","value":true}))
    
        });
        console.log(room)
      //   screenTrack.addEventListener("ended", () => {
      //     console.log('ended')
      //     room.localParticipant.unpublishTrack(screenTrack)
      //     console.log(room)
      //     screenTrack.stop();
      // dispatch(setVideoCallScreenTrack(null))

      //   });;
    }).catch((error) => {
      console.log(error)
      alert('Could not share the screen.')
  });
}


  }
  const blurBack=async()=>{


    // let blurBack=new GaussianBlurBackgroundProcessor({
    //   assetsPath: '/twillpro',
    //   maskBlurRadius: 10,
    //   blurFilterRadius: 5,
    // });
    // blurBack.loadModel().then(()=>{
    //   setBlurPro(blurBack)
    //   room.localParticipant.videoTracks.forEach(publication => {
    //               publication.track.addProcessor(blurBack);
    //             });
    // });

    // createLocalVideoTrack({
    //     width: 640,
    //     height: 480,
    //     frameRate: 24
    //   }).then(track => {
    //     track.addProcessor(blurBack);
    //     room.localParticipant.publishTrack(track);
    //   });
    // room.localParticipant.videoTracks.forEach(publication => {
    //         publication.track.addProcessor(blurBack);
    //       });
  //   console.log('hi blur')
  // let img = new Image();
  // img.src ="https://picsum.photos/id/237/200/300";
  // img.onload = async () => {
  //   let virtualBackground:any = new VirtualBackgroundProcessor({
  //     assetsPath: '/twillpro',
  //     backgroundImage: img,
  //     //debounce: isSafari(),
  //   });
  //   await virtualBackground.loadModel();
  //   room.localParticipant.videoTracks.forEach(publication => {
  //           publication.track.addProcessor(virtualBackground);
  //         });
    
    
  // }

  //   const bg = new VirtualBackgroundProcessor({
  //     assetsPath: '/',
  //     backgroundImage:ZingLogoHomePage,
  //     debounce: 10,
  //   });
  //  bg.loadModel().then(res=>{console.log(res)}).catch(res=>{console.log(res)})
    
  //  room.localParticipant.videoTracks.forEach(publication => {
  //   publication.track.addProcessor(bg);
  // });

 
  
  }
  const muteUnmute=(mute)=>{
    if(mute){
      room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });
    setToggleVoice(!toggleVoice);
    }
    else{
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
      setToggleVoice(!toggleVoice)
    }
    
  }
  const videoOnOff=(video)=>{
    if(video){
      room.localParticipant.videoTracks.forEach((publication:any) => {
        console.log(publication)
        publication.track.disable();
        // publication.unpublish();
        // publication.track.stop();
    }); 
    setToggleVideo(!toggleVideo)
    }
    else{
      // createLocalVideoTrack().then(track=>{
                      
                    //   // room.localParticipant.publishTrack(track).then((x)=>{

                    //   //   x.track.disable();
                    //   //   x.track.enable()
                    //   //   // room.localParticipant.videoTracks.forEach((publication:any) => {
                    //   //   //   console.log(publication)
                          
                    //   //   //  publication.track.disable();
                    //   //   //  publication.track.enable();
                    //   //   // })
                    //   // });
                    //   room.localParticipant.publishTrack(track);
                    //   room.localParticipant.videoTracks.forEach((publication:any) => {
                          
                          
                    //      publication.track.enable();
                    //      publication.track.start();
                    //     })

                    // })
                    room.localParticipant.videoTracks.forEach((publication:any) => {
                      console.log(publication)
                      // publication.unpublish();
                      publication.track.enable();
                      // publication.track.stop();
                  }); 
                    console.log(room)
                    
                    ;
                    //dispatch(setVideoCallRoom(room));
                    setToggleVideo(!toggleVideo)
    }
  }
  const endCall=()=>{
    // room.localParticipant.videoTracks.forEach(publication => {
    //   const attachedElements = publication.track.detach();
    //   publication.track.stop();
    //       attachedElements.forEach(element => element.remove());
    //   });
    //   room.localParticipant.audioTracks.forEach(publication => {
    //     const attachedElements = publication.track.detach();
    //     publication.track.stop();
    //         attachedElements.forEach(element => element.remove());
    //     });
    room.localParticipant.dataTracks.forEach(element => {    
      element.track.send(JSON.stringify({"type":"pdftron","value":null})) 
    });
    room.localParticipant.tracks.forEach(publication => {
      if(publication.kind=="video"||publication.kind=="audio")
      {
      const attachedElements = publication.track.detach();
      attachedElements.forEach(element => element.remove());
      publication.track.stop();
      }
      else{
        publication.unpublish() 
      }

       

      });
      console.log(room)

    room.disconnect();
    dispatch(setVideoCallShowFloater(false))
    debugger
    dispatch(setVideoCallRoom(null))
    dispatch(setVideoCallEnded(true))
  dispatch(setVideoCallCallStarted(false))
  
    //setRoom(null)
  }
  const convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    if (action === 'add') {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
    } else if (action === 'modify') {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
    } else if (action === 'delete') {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
    }
    xfdfString += `</xfdf>`;
    return xfdfString;
  }
  
  // helper function to send annotation changes to WebSocket server
  const sendAnnotationChange = (annotation, action) => {
    if (annotation.nodeType !== annotation.TEXT_NODE) {
      const annotationString = serializer.serializeToString(annotation);
  
      const annotations = convertToXfdf(annotationString,action);
      console.log("annotation Data from UI ::" + annotationString +"\t");
      room.localParticipant.dataTracks.forEach(element => {
    
        element.track.send(JSON.stringify({"type":"annotation","value":annotations}))
        });
      //setAnnotationData(annotations);

  
    //  getAnnotationData && getAnnotationData(annotations);
      // connection.send(JSON.stringify({
      //   documentId: DOCUMENT_ID,
      //   annotationId: annotation.getAttribute('name'),
      //   xfdfString: convertToXfdf(annotationString, action)
      // }));
    }
    else{
      console.log("No data for annotations");
    }
  }
  
  useEffect(() => {
    
    
    if(videoCallPdfTronStarted){
      WebViewer(
        {
          path: '/public',
          initialDoc: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
        },
        viewer.current,
      ).then((instance) => {
        //setInstance(instance);
        const { documentViewer, annotationManager, Annotations } = instance.Core;
  
        setAnnotationManagerNew(annotationManager);
  
        documentViewer.addEventListener('documentLoaded', () => {
          const rectangleAnnot = new Annotations.RectangleAnnotation({
            PageNumber: 1,
            // values are in page coordinates with (0, 0) in the top left
            X: 100,
            Y: 150,
            Width: 200,
            Height: 50,
            Author: annotationManager.getCurrentUser()
          });
  
          annotationManager.addAnnotation(rectangleAnnot);
          // need to draw the annotation otherwise it won't show up until the page is refreshed
          annotationManager.redrawAnnotation(rectangleAnnot);
  
          annotationManager.on('annotationChanged', async (e:any) => {
            // If annotation change is from import, return
            if (e.imported) {
              return;
            }
        
            const xfdfString = await annotationManager.exportAnnotationCommand();
            // Parse xfdfString to separate multiple annotation changes to individual annotation change
            const parser = new DOMParser();
            const commandData = parser.parseFromString(xfdfString, 'text/xml');
            const addedAnnots = commandData.getElementsByTagName('add')[0];
          //   const modifiedAnnots = commandData.getElementsByTagName('modify')[0];
          //  const deletedAnnots = commandData.getElementsByTagName('delete')[0];
        
            // List of added annotations
            addedAnnots.childNodes.forEach((child) => {
              sendAnnotationChange(child, 'add');
            });
        
            // // List of modified annotations
            // modifiedAnnots.childNodes.forEach((child) => {
            //   sendAnnotationChange(child, 'modify');
            // });
            
            // // List of deleted annotations
            // deletedAnnots.childNodes.forEach((child) => {
            //   sendAnnotationChange(child, 'delete');
            // });
  
          });
  
        });
      });
  
      
  
    }
    else{
  
    }
  
    }, [videoCallPdfTronStarted]);
    
  

    

    
  if(!isMobile)
  return (
    
    <div className="room" id="videoRoom" >
    
    <div className='sectionVideo'>
        <div className='sectionVideoMain'>
        {
          sharingMode?
          <div className={'grid grid--1'}>     
          <Participant
          annotationset={(data)=>{}}
          dominant={sharingParticipant.sid==dominantSid}
          mute={toggleVoice}
          video={toggleVideo}
          key={sharingParticipant.sid}
          participant={sharingParticipant}
          parentWidth={width}
          parentHeight={height}
          children={ 1}
          disconnected={(e)=>{}}
          />
          <div style={{display:'none'}}>
          <Participant
        annotationset={(data)=>{}}
        dominant={localParticipant.sid==dominantSid}
        mute={toggleVoice}
        video={toggleVideo}
        key={localParticipant.sid}
        participant={room.localParticipant}
        parentWidth={width}
        parentHeight={height}
        children={ 1}
        />
        {remoteParticipants}
          </div>
          </div>:null
        }
        {/* {
          videoCallPdfTronStarted?
          <div className={'grid grid--1'}>
          <div className="webviewer" 
            ref={viewer}
            ></div>
          </div>:null
        } */}
        {
          room && !sharingMode &&!videoCallPdfTronStarted?
          <div className={'grid grid--' +(participants.length+1)}>
      {localParticipant ? (
        <Participant
        annotationset={(data)=>{}}
        dominant={localParticipant.sid==dominantSid}
        mute={toggleVoice}
        video={toggleVideo}
        key={localParticipant.sid}
        participant={room.localParticipant}
        parentWidth={width}
        parentHeight={height}
        children={ 1}
        />
      ) : (
        ''
      )}
      {remoteParticipants}
      </div>:null
        }
        
        </div>        
        <div className='sectionVideoActions'>
            <div className='actionsDiv'>
                {/* <div>
                  <Tooltip  title="Record" arrow>
                  <RecordButton style={{fill:"var(--primary-color)"}} />
                  </Tooltip>
                  </div> */}
                
                <div>{
                  toggleVoice?
                  <Tooltip  title={t("Mute")} arrow><Mute onClick={()=>{muteUnmute(true)}} style={{fill:"var(--primary-color)"}} /></Tooltip>:
                  <Tooltip  title={t("Unmute")} arrow><Unmute onClick={()=>{muteUnmute(false)}} style={{fill:"var(--primary-color)"}} /></Tooltip>
                  }
                </div>
                <div>{
                  toggleVideo?
                  <Tooltip  title={t("Video off")} arrow><VideoEnable onClick={()=>{videoOnOff(true)}} style={{fill:"var(--primary-color)"}}/></Tooltip>
                  :
                  <Tooltip  title={t("Video on")} arrow><VideoDisable onClick={()=>{videoOnOff(false)}} style={{fill:"var(--primary-color)"}}/></Tooltip>
                  }</div>
                {/* <div>
                <Popover
        
        
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverOpen}
        
      
      >
        <div className='popoverVideo'>
          <div  onClick={()=>{screenshare()}}>ScreenShare</div>
          <div onClick={()=>{
           blurBack()
          }}>BlurBackground</div>
        
         </div>
      </Popover>
                  <Setting onClick={()=>{
                    //screenshare();
                  }} style={{fill:"var(--primary-color)"}}/></div> */}
                    {/* <div><ScreenShare onClick={handlePopoverOpen} style={{ fill:"var(--primary-color)"}}/></div> */}
                <div><Tooltip  title={t("Toggle chat")} arrow><Chat onClick={()=>setToggleChat(!toggleChat)} style={{fill:"var(--primary-color)"}}/></Tooltip></div>
                <div><Tooltip  title={t("End Call")} arrow><CallEndIcon onClick={()=>{endCall()}} fontSize='large' color='error'/></Tooltip></div>
                {/* <div><Reactions style={{fill:"var(--primary-color)"}}/></div> */}
            </div>
        </div>        
        <div style={{display:'none'}}>
        {inactiveParticipantsDiv}     
        </div>
    </div>
    {
      toggleChat && localParticipant?
      <div className='sectionChat'>
      <div className='sectionChatMain'>
          <div className='videochatContainer'>
            <ul>
           
            {
              chats.map((x:any,i)=>(
                <div className='chatListDivVideo' style={{float:x.UserName==localParticipant.identity?"right":'left',justifyContent:x.UserName==localParticipant.identity?"flex-end":'flex-start'}} >
                  {
                    (x.UserName!=localParticipant.identity)?
                    <div className='userIconvideoChat'>
                    <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                  </div>:null
                  }
                 
                <li key={x.CreatedOn+i} id={x.CreatedOn+i} className={'videomessage '+ (x.UserName==localParticipant.identity?"out":"in")}>
                  
                  <div>
                    <div>
                      {x.UserName}
                    </div>
                    {x.Message}
                  </div>
                </li>
                {
                    (x.UserName==localParticipant.identity)?
                    <div className='userIconvideoChat'>
                    <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                  </div>:null
                  }
                </div>
                )
              )
            }
            </ul>
          </div>
          <div className='videochatfooter'>
          <input placeholder={t('Write your message')+'..'} value={chatInput} onChange={(e)=>setChatInput(e.target.value)}/>
                 <button
                              className="btn btn-light"
                              onClick={()=>{sendChat()}}
                              >
                              <i
                                  data-v-4d521fc4=""
                                  data-name="send"
                                  data-tags="message,mail,email,paper airplane,paper aeroplane"
                                  data-type="send"
                                  className="feather feather--send i-2"
                                  
                              >
                              <svg
                                      style={{ color: '#734bd1' }}
                                  data-v-4d521fc4=""
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-send feather__content"
                              >
                                  <line x1="22" y1="2" x2="11" y2="13"></line>
                                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg>
                          </i>
                      </button>
          </div>
      
              </div>  
          </div>:<div className='sectionChat'>
            <h5>{t('Participants')}</h5>
            <ParticipantsList /></div>
    }
 
  </div>
  )
  else{
    return (
      <div className="room" style={{height:infiniteScrollHeight+"px"}}>
    
    <div className='sectionVideo'>
    <div className='sectionVideoMain'>
        {
          sharingMode?
          <div className={'grid grid--1'}>     
          <Participant
          annotationset={(data)=>{}}
          dominant={sharingParticipant.sid==dominantSid}
          mute={toggleVoice}
          video={toggleVideo}
          key={sharingParticipant.sid}
          participant={sharingParticipant}
          parentWidth={width}
          parentHeight={height}
          children={ 1}
          />
          <div style={{display:'none'}}>
          <Participant
        annotationset={(data)=>{}}
        dominant={localParticipant.sid==dominantSid}
        mute={toggleVoice}
        video={toggleVideo}
        key={localParticipant.sid}
        participant={room.localParticipant}
        parentWidth={width}
        parentHeight={height}
        children={ 1}
        />
        {remoteParticipants}
          </div>
          </div>:null
        }
        {
          videoCallPdfTronStarted?
          <div className={'grid grid--1'}>
          <div className="webviewer" 
            ref={viewer}
            ></div>
          </div>:null
        }
        {
          room && !sharingMode &&!videoCallPdfTronStarted?
          <div className={'grid grid--' +(participants.length+1)}>
      {localParticipant ? (
        <Participant
        annotationset={(data)=>{}}
        dominant={localParticipant.sid==dominantSid}
        mute={toggleVoice}
        video={toggleVideo}
        key={localParticipant.sid}
        participant={room.localParticipant}
        parentWidth={width}
        parentHeight={height}
        children={ 1}
        />
      ) : (
        ''
      )}
      {remoteParticipants}
      </div>:null
        }
        
        </div>   
        <div className='sectionVideoActions'>
            <div className='actionsDiv'>
                {/* <div><RecordButton style={{width:'1rem', fill:"var(--primary-color)"}} /></div> */}
                
                <div>
                  {
                  toggleVoice?
                  <Mute onClick={()=>{muteUnmute(true)}} style={{width:'0.8rem', fill:"var(--primary-color)"}} />:
                  <Unmute onClick={()=>{muteUnmute(false)}} style={{width:'0.8rem', fill:"var(--primary-color)"}} />
                  }
                </div>
                <div>{
                  toggleVideo?
                  <VideoEnable onClick={()=>{videoOnOff(true)}} style={{width:'1rem', fill:"var(--primary-color)"}}/>
                  :
                  <VideoDisable onClick={()=>{videoOnOff(false)}} style={{width:'1rem', fill:"var(--primary-color)"}}/>
                  }</div>
                {/* <div>
                <Popover
        
        
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverOpen}
        
      
      >
        
          <div  onClick={()=>{screenshare()}}>ScreenShare</div>
          <div onClick={()=>{
           blurBack()
          }}>BlurBackground</div>
          <div onClick={()=>{
            if(!videoCallPdfTronStarted){
              dispatch(setVideoCallPdfTronStarted(true))
              dispatch(setVideoCallShowFloater(true))
            }
            else{
              dispatch(setVideoCallPdfTronStarted(false))
              dispatch(setVideoCallShowFloater(false))
            }
          
          }}>PDF tron</div>
         
      </Popover>
                  <Setting  onClick={()=>{
                    
                    
          
                    }} style={{width:'1rem', fill:"var(--primary-color)"}}/></div> */}
                    {/* <div><ScreenShare onClick={handlePopoverOpen} style={{width:'1rem',  fill:"var(--primary-color)"}}/></div> */}
                <div><Chat onClick={()=>{setToggleMobileChat(!toggleChatMobile);setToggleParticipant(false)}} style={{width:'1rem', fill:"var(--primary-color)"}}/></div>
                <div><ParticipantsIcon onClick={()=>{setToggleMobileChat(false);setToggleParticipant(!toggleParticipant)}} style={{width:'1rem', fill:"var(--primary-color)"}}/></div>
                <div><CallEndIcon onClick={()=>{endCall()}} style={{width:'1rem'}} fontSize='large' color='error'/></div>
                {/* <div><Reactions style={{width:'1rem', fill:"var(--primary-color)"}}/></div> */}
            </div>
        </div>        
        <div style={{display:'none'}}>
        {inactiveParticipantsDiv}     
        </div>
    </div>
  
    {toggleChatMobile?
      <div className='sectionChat'>
      <div className='sectionChatMain'>
      <div className='videoBackBtnclose' onClick={()=>{setToggleMobileChat(!toggleChatMobile)}}>x</div>
          <div className='videochatContainer'>
           
            {
              room && localParticipant?
              <ul>
           
              {
                chats.map((x:any,i:any)=>(
                  <div className='chatListDivVideo' style={{float:x.UserName==localParticipant.identity?"right":'left',justifyContent:x.UserName==localParticipant.identity?"flex-end":'flex-start'}} >
                    {
                      (x.UserName!=localParticipant.identity)?
                      <div className='userIconvideoChat'>
                      <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                    </div>:null
                    }
                   
                  <li key={x.CreatedOn+i} id={x.CreatedOn+i} className={'videomessage '+ (x.UserName==localParticipant.identity?"out":"in")}>
                    
                    <div>
                      <div>
                        {x.UserName}
                      </div>
                      {x.Message}
                    </div>
                  </li>
                  {
                      (x.UserName==localParticipant.identity)?
                      <div className='userIconvideoChat'>
                      <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'/>
                    </div>:null
                    }
                  </div>
                  )
                )
              }
              </ul>
            
            :null
            }
            
          </div>
          <div className='videochatfooter'>
          <input placeholder={t('Write your message')+'..'} value={chatInput} onChange={(e)=>setChatInput(e.target.value)}/>
                 <button
                              className="btn btn-light"
                              onClick={()=>{sendChat()}}
                              >
                              <i
                                  data-v-4d521fc4=""
                                  data-name="send"
                                  data-tags="message,mail,email,paper airplane,paper aeroplane"
                                  data-type="send"
                                  className="feather feather--send i-2"
                                  
                              >
                              <svg
                                      style={{ color: '#734bd1' }}
                                  data-v-4d521fc4=""
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-send feather__content"
                              >
                                  <line x1="22" y1="2" x2="11" y2="13"></line>
                                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg>
                          </i>
                      </button>
          </div>
      
              </div>  
          </div>:null}
 
    {
      toggleParticipant?
      <div className='sectionChat'>
        <div className='videoBackBtnclose' onClick={()=>{setToggleParticipant(!toggleParticipant)}}>x</div>
      <ParticipantsList /></div>:null
    }
  </div>
    )
  }
}

export default VideoCall