import React, { useState } from 'react'
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login"
import Logout from "./pages/logout/Logout"
import Chat from "./pages/chat/Chat"
import Contacts from './pages/contacts/Contacts';
import Calllog from './pages/calllog/Calllog';
import Assets from './pages/assets/Assets';
import Templates from './pages/templates/Templates';
import Video from './pages/video/Video';
import Calendar from './pages/calendar/Calendar';
import Home from './pages/home/Home';
import Error404 from './components/errors/Error404';
import ErrorAuth from './components/errors/ErrorAuth';
import ErrorPage from './components/errors/ErrorPage';
import NewChatMobile from './pages/NewChatMobile/NewChatMobile';
import Campaign from './pages/campaign/campaign';
import axios from 'axios';
import Forms from './pages/forms/Forms';
import Media from './pages/media/Media';
import { useTranslation } from "react-i18next";
import DynamicTemplate from './pages/templates/DynamicTemplate';

export default function MainRoutes() {

  // const { t } = useTranslation();
  const [errorMsg,setErrorMsg]=useState('');
  
  const errmsg = localStorage.getItem('errorpage');
  // const errauth=localStorage.getItem('errorauth');
  return (
    
    <Routes>
      
      <Route path='/logout' element={<Logout />} />  

      <Route path='/error' element={<ErrorAuth />} />
      
      <Route path='/' element={<Login />}>
        {errmsg  &&
          <Route path='*' element={<ErrorPage />} />
        }
        {!errmsg &&
        <>
          <Route index element={<Chat />} />
          <Route path='/Chat' element={<Chat />} />
          {/* <Route path='/Contacts' element={<Contacts />} /> */}
          <Route path='/Contacts' element={<Contacts  />} />
          <Route path='/CallLog' element={<Calllog />} />
          <Route path='/Assets' element={<Assets />} />
          <Route path='/Templates' element={<Templates />} />
          <Route path='/Video' element={<Video />} />
          <Route path='/Calendar' element={<Calendar />} />
          <Route path='/Home' element={<NewChatMobile />} />  
          <Route path='/Campaign' element={<Campaign />} />        
          {/* <Route path='/Forms' element={<Forms />} />   */}
          <Route path='/Forms' element={<Forms />} />  
          <Route path='/DynamicTemplate' element={<DynamicTemplate />} />  
          <Route path='/Media' element={<Media />} />  
          <Route path='*' element={<Error404 />} />
        </>
        }
      </Route>
      

    </Routes>
    
  )
}
