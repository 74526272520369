// import React from 'react';
import React,{useState,useEffect, useRef} from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Popup from "../calendar/FreeTimeModal";
import axios from "axios";

// import "@fullcalendar/core/main.css";
import config from '../../env.json';
import moment from 'moment';
import './CalenderComponent.css';
import BlockUI from '../../layouts/bockUi/BlockUI';
import {getDeviceType} from '../../utils/deviceTypeUtil';

// import "@fullcalendar/daygrid/main.css";

export default function CalendarComponent(props) {
    // const events = [{ title: "today's event", date: new Date() }];
    const [modalShow, setModalShow] = React.useState(false);
    const [freetimeval,setFreeTimeVal]=useState({});
    const [freetime,setFreeTime]=useState([]); 
    const [event,setEvent]=useState([]); 
    const [calendarHeight,setCalendarHeight]=useState(0);  
    const [info,setInfo]=useState([{
      freetime:[],
      event:[]
    }])
    const [nextflag,setNextFlag]=useState(false); 
    const [tempNext,setTempNext]=useState([]); 
    const [result,setResult]=useState([]); 
    const [date, setDate]=useState("");
    const start:any=new Date();
    const end:any=new Date();
    const [block,setBlock]=useState(false);
    const [startdate, setStartDate]=useState(new Date());
    const [enddate, setEndDate]=useState(new Date());

      useEffect(() => {
        if(props.refresh)
        {
          getFreeTime(moment(startdate).subtract(2,'M').format(),moment(enddate).add(2, 'M').format());
          getEvents(moment(startdate).subtract(2,'M').format(),moment(enddate).add(2, 'M').format());
        }
       
      }, [props.refresh])
      useEffect(() => {
        const temp=event.concat(freetime);
        setResult([...temp]);
        console.log(JSON.stringify(result));
      
          getHeight();

        
       }, [event,freetime])

      useEffect(() => {
        window.addEventListener('orientationchange', getHeight);
        return () => {
          window.removeEventListener('orientationchange',getHeight)
        }
      },[])
       
       const getHeight=()=>{
        let viewportHeight = window.innerHeight;
        let div:any=document.getElementById('main');
        let calendardiv:any=document.getElementsByClassName('calendarTabPanel')[0];
        let toolbardiv:any=document.getElementsByClassName('fc-header-toolbar')[0];
        let toolbarmargin:any=parseInt(window.getComputedStyle(toolbardiv, '').getPropertyValue('margin-bottom'));
        let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
        let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
        let calendarPadding:any=parseInt(window.getComputedStyle(calendardiv, '').getPropertyValue('padding'));
       // console.log(toolbarmargin)
        let doc:any=document;
       // console.log(doc.getElementById('MobHeader').offsetHeight,document.getElementById('headerVal')?.offsetHeight,doc.getElementsByClassName('CalendarBox')[0].offsetHeight,doc.getElementById('footer').offsetHeight,doc.getElementsByClassName('fc-header-toolbar')[0].offsetHeight)
       
        let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
              if(doc.getElementsByClassName('calendar-container')[0])
              {
                const finalheight=viewportHeight -(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementsByClassName('CalendarBox')[0].offsetHeight+doc.getElementById('footer').offsetHeight+doc.getElementsByClassName('fc-header-toolbar')[0].offsetHeight+toolbarmargin)-divpaddingTop-divpaddingbottom-calendarPadding-calendarPadding ;
                console.log(finalheight);
             //   setCalendarHeight(finalheight);
             
               doc.querySelector('.fc-view-harness').style.height=finalheight+"px";
              // doc.getElementsByClassName('fc-view-harness').style.height=finalheight+"px";
              }
         }
         else
         {
          if(doc.getElementsByClassName('calendar-container')[0])
          {
            const finalheight=viewportHeight -(document.getElementById('headerVal')?.offsetHeight+doc.getElementsByClassName('CalendarBox')[0].offsetHeight)-calendarPadding-calendarPadding ;
            console.log(viewportHeight,document.getElementById('headerVal')?.offsetHeight,doc.getElementsByClassName('CalendarBox')[0].offsetHeight,calendarPadding);
            console.log(finalheight)
            setCalendarHeight(finalheight);
          // doc.getElementsByClassName('fc-view-harness').style.height=finalheight+"px";
          }
         }

        
      }
      
      const getFreeTime=(start,end)=>{
        setBlock(true);
        debugger;
        console.log(new Date())
        console.log(start,end);
        setStartDate(start);
        setEndDate(end);
        start=moment(start).toISOString();
        end=moment(end).toISOString();
     
        console.log(start,  end);
        axios
        .get(
          config.REACT_APP_CALENDAR_API_BASE+"/freetime?PageSize=&Page=0&StartDate="+start+"&EndDate="+end,
        )
        .then((res) => {
          setBlock(false);
          props.stoprefresh();
          console.log(res.data.data);
          let ftime=res.data.data;  
                  
          ftime=ftime.map((x:any)=>{
            console.log(moment(x.AvailabilityStart_DtTm).format());
            x.start= moment(x.AvailabilityStart_DtTm).format();           
            x.end =moment(x.AvailabilityEnd_DtTm).format();
            x.title=x.Subject_Val   ;

            return x;
          })
      
          setFreeTime(ftime);


         // handleCloseParent(true)
        })
        .catch((err)=>{
          console.log("Freetime Error:"+err);
        })

       
      }
      const getEvents=(start,end)=>{
        debugger;
        setBlock(true);
        setStartDate(start);
        setEndDate(end);
        start=moment(start).toISOString();
        end=moment(end).toISOString();
        axios
        .get(
          config.REACT_APP_CALENDAR_API_BASE+"/calendarevent?PageSize=&Page=&Status_Val=accept&StartDate="+start+"&EndDate="+end,
        )
        .then((res) => {
          setBlock(false);
          console.log(res.data.data);
          let ftime=res.data.data;  
                
          ftime=ftime.map((x:any)=>{
            console.log(moment(x.AvailabilityStart_DtTm).format());
            x.start= moment(x.RequestStart_DtTm).format();           
            x.end =moment(x.RequestedEnd_DtTm).format();
            x.title=x.Subject_Val   ;
            return x;
          })
         
          setEvent(ftime);
        

        })
        .catch((err)=>{
          console.log("Freetime Error:"+err);
        })
        
      }

         
      const handleMonthChange=(eventdata)=>{
        debugger;
            console.log(event);
            setNextFlag(true);
            setEvent([]);
            setFreeTime([]);
            // getFreeTime(moment(eventdata.start).startOf('month').format(),moment(eventdata.start).endOf('month').format());
            // getEvents(moment(eventdata.start).startOf('month').format(),moment(eventdata.start).endOf('month').format());
            
            getFreeTime(moment(eventdata.start).subtract(2,'M').format(),moment(eventdata.end).add(2, 'M').format());
            getEvents(moment(eventdata.start).subtract(2,'M').format(),moment(eventdata.end).add(2, 'M').format());          
             console.log(event,freetime);
             const temp=event.concat(freetime);
        }

  
  
      
      
      const handleDateClick = (event) => {
        debugger;
        console.log(event.dateStr);
        setModalShow(true);
        setDate(event.date.toISOString());
         debugger;
      }
    return (
        <>
       
            <Popup show={modalShow}  onHide={(x) => {setModalShow(false);if(x){getFreeTime(startdate,enddate);getEvents(startdate,enddate);}}}  end={end} value={true} dateval={date} />
            <div className='col-md-12'>
             
            <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        dayMaxEventRows= {2}
        initialView="dayGridMonth"
        headerToolbar={
        {
          left: 'dayGridMonth,timeGridWeek',
          center: 'title',
          right: 'prev,next'
        }
      }
      // height={calendarHeight}
      datesSet= {(e)=> handleMonthChange(e) }
      eventBackgroundColor={"#3788d8"}
      eventColor={'#3788d8'}
      displayEventEnd={true}
      events={result}
        nowIndicator
        eventTextColor='white'
        
         dateClick={(e) => handleDateClick(e)}
      />
        {
                block?<BlockUI show={block}/>:null
               }
            </div>
        </>
    )
}

